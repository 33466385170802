import React, { useState } from "react";
import { Box, Heading, useColorMode, Image } from "@chakra-ui/react";
import LayoutTwo from "../components/layouts/LayoutTwo";
import CreateReport from "../components/tickets/CreateReport";
import TicketsTable from "../components/tickets/TicketsTable";
// import NewUserModal from "../components/userlist/NewUserModal";
// import UserListTable from "../components/userlist/UserListTable";
// import DashboardTable from "../components/dashboard/DashboardTable";
// import NotificationsRightContent from "../components/dashboard/NotificationsRightContent";
// import SecondTableDashboard from "../components/dashboard/SecondTableDashboard";

export default function Tickets() {
  const { colorMode, toggleColorMode } = useColorMode();
  const [isRefresh, setIsRefresh] = useState(false);

  return (
    <>
      <LayoutTwo>
        <Box className="userlist_page">
          <Box className="usr_list_cntnr">
            <Heading as="h2" className="def_head_dbrd">
              Tickets
            </Heading>
            <Box className="dahboardgrid">
              <Box className="user_cntnt_box flex75">
                {colorMode === "light" ? (
                  <Image
                    src="/img/user_list_top_img.png"
                    alt=""
                    className="user_list_top_img"
                  />
                ) : (
                  <Image
                    src="/img/user_list_top_img_dark.png"
                    alt=""
                    className="user_list_top_img"
                  />
                )}
                {colorMode === "light" ? (
                  <Image
                    src="/img/user_list_btm_img.png"
                    alt=""
                    className="user_list_btm_img"
                  />
                ) : (
                  <Image
                    src="/img/user_list_btm_img_dark.png"
                    alt=""
                    className="user_list_btm_img"
                  />
                )}
                <Box className="user_cntr_line_bg" />
                <Box className="create_user_prnt_raw create_user_prnt_raw_ab">
                  <Heading as="h5">Create Ticket</Heading>
                  <Box>
                    {/* <NewUserModal /> */}
                  </Box>
                </Box>
                <Box className="flex_full_boxs">
                  {/* <UserListTable /> */}
                  <Box>
                    <CreateReport setIsRefresh={setIsRefresh} />
                  </Box>
                </Box>
              </Box>
              <Box className="flex25">
                <Box className="main_rightbx">
                  {colorMode === "light" ? (
                    <Image
                      src="/img/ntfctn_tp.png"
                      alt=""
                      className="ntfctn_tp"
                    />
                  ) : (
                    <Image
                      src="/img/ntfctn_tp_dark.png"
                      alt=""
                      className="ntfctn_tp"
                    />
                  )}
                  {colorMode === "light" ? (
                    <Image
                      src="/img/ntfctn_btm.png"
                      alt=""
                      className="ntfctn_btm"
                    />
                  ) : (
                    <Image
                      src="/img/ntfctn_btm_dark.png"
                      alt=""
                      className="ntfctn_btm"
                    />
                  )}
                  <Box className="ntfctn_mddl" />
                  <Box className="right_content_main">
                    <TicketsTable isPagination={true} pagination={10} isRefresh={isRefresh} setIsRefresh={setIsRefresh} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <Image src="/img/user_list_top_sdw_circle_img.png" alt="" className="user_list_top_sdw_circle_img" /> */}
        </Box>
      </LayoutTwo>
    </>
  );
}

