import React from "react";
import LayoutOne from "../components/layouts/LayoutOne";
import BecomeUnlimited from "./sections/BecomeUnlimited";
import DevTeam from "./sections/DevTeam";
import OurPartners from "./sections/OurPartners";
import OurResults from "./sections/OurResults";
import OurWorks from "./sections/OurWorks";
import SectionReport from "./sections/SectionReport";
import SectionWate from "./sections/SectionWate";
import ThreeBoxSec from "./sections/ThreeBoxSec";
// import OurTeam from "./sections/OurTeam";
// import ResultDriven from "./sections/ResultDriven";
// import TrustedDevloper from "./sections/TrustedDevloper";

export default function HomePage() {
  return (
    <>
      <LayoutOne>
        <BecomeUnlimited />
        {/* <ResultDriven /> */}
        {/* <TrustedDevloper /> */}
        <ThreeBoxSec />
        <OurWorks />
        <SectionWate />
        <DevTeam />
        <OurResults />
        {/* <OurTeam /> */}
        <OurPartners />
        <SectionReport />
      </LayoutOne>
    </>
  );
}
