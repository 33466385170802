import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Image,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useToast,
} from '@chakra-ui/react'
import axios from 'axios'
import httpStatus from "http-status";
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { BASE_URL, CLICKUP_TICKET_STATUS } from '../../services/constant'
import EditReport from './EditReport'
import ViewReport from './ViewReport'
import { logoutUser } from '../../redux/Auth/action'

export default function TicketDetail({
  ticketTable,
  isDetailOpen,
  handleBack,
}) {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ticket, setTicket] = useState({})
  const [statusStyle, setStatusStyle] = useState('')
  const [isView, setIsView] = useState(true);
  const [tags, setTags] = useState(false);

  const getDetail = async () => {
    try {
      const token = localStorage.getItem('bluClientToken')
      const resp = await axios({
        method: 'get',
        url: `${BASE_URL}/api/ticket/${ticketTable?._id}`,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })

      if (resp.status === httpStatus.OK) {
        const json = resp.data
        setTicket(JSON.parse(JSON.stringify(json)))
      }
    } catch (error) {
      console.error(error)
      if (error?.response?.status == httpStatus.UNAUTHORIZED) {
        dispatch(logoutUser()).then(() => {
          toast({
            title: 'You are not logged in.',
            description: "Please login first",
            status: 'warning',
            duration: 3000,
            isClosable: true,
          });
          navigate('/')
        })
      }
      else {
        let message = error?.response?.data?.message;
        if (!message) message = error.message
        toast({
          title: `Failed to load ticket ${ticketTable?.name}`,
          description: message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }

  useEffect(() => {
    let status = 'Open_btn'
    switch (ticket?.status) {
      case CLICKUP_TICKET_STATUS.INPROGRESS:
        status = 'Progress_btn'
        break
      case CLICKUP_TICKET_STATUS.RESOLVED:
        status = 'Resolved_btn'
        break
      case CLICKUP_TICKET_STATUS.CLOSED:
        status = 'Closed_btn'
        break
      default:
        break
    }
    setStatusStyle(status)
    setTags(ticket?.tags?.length == 1 ? ticket?.tags[0] == 'request' : false)
  }, [ticket])

  useEffect(() => {
    if (!isDetailOpen) {
      setIsView(true);
    }
  }, [isDetailOpen])

  useEffect(() => {
    if (ticketTable?._id) {
      getDetail();
    }
  }, [ticketTable])

  let EditTicket = (
    <EditReport
      ticket={ticket}
      setIsView={setIsView}
      getDetail={getDetail}
    />
  )

  let ViewTicket = (
    <ViewReport
      ticket={ticket}
      setIsView={setIsView}
      tags={tags}
      getDetail={getDetail}
      handleBack={handleBack} />
  )

  return (
    <Box className='modal_main'>
      <Modal isOpen={isDetailOpen} onClose={handleBack} isCentered={false}>
        <ModalOverlay />
        <ModalContent className='modal_report'>
          <ModalHeader className='moda_hed moda_hed_as moda_hed_asv2 moda_hed_ab'>
            <Box className='tcket_hed_inn'>
              <Heading as='h1' className='ticket_had_txt'>Ticket {ticket?.ticket_no}</Heading>
              <Heading as='h1' className='img_txt_h'>
                {
                  tags ? (
                    <>
                      <Image src='/img/tracedon_ic.svg' alt='' /> REQUEST
                    </>
                  ) : (
                    <>
                      <Image src='/img/exclaim_ic.svg' alt='' /> ISSUE
                    </>
                  )
                }
              </Heading>
              <Box className='submited_bx'>
                <Text>Submitted By: </Text>
                <Heading as="h4">{`${ticket?.createdBy?.firstname} ${ticket?.createdBy?.lastname}`}</Heading>
              </Box>
              <Box className={`${statusStyle} cetner_items btn_hight_w tcket_right_btn`}>
                <Button className='def_btn'>{ticket?.status}</Button>
              </Box>
            </Box>
            <Button className='had_close_btn' onClick={handleBack}>
              <Image src='/img/had_close_btn.png' alt='' />
            </Button>
          </ModalHeader>
          {isView ? ViewTicket : EditTicket}
        </ModalContent>
      </Modal>
    </Box>
  )
}
