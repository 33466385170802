import React from 'react'
import {
  Box,
  Button,
  Image,
  Heading,
  Text,
  useColorMode,
  Textarea,
} from '@chakra-ui/react'
export default function ViewReportSolution({ ticket }) {
  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <>
      <Box className='describe_problem_cont description_solution_cont'>
        <Heading as='h4'>Description of the solution</Heading>
        <Box className='border_box'>
          <Box className='gredint_border'>
            <Textarea value={ticket?.id_ticket_solution?.description}></Textarea>
            {/* <Text>{ticket?.id_ticket_solution?.description}</Text> */}
            {/* <Text>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper bibendum lectus laoreet enim volutpat ullamcorper at imperdiet curabitur. Nibh eu dictumst at viverra facilisis. Interdum sit euismod nisi tellus sem lectus.</Text>
            <Text>Et ac dolor nulla volutpat sed euismod in ultricies. Sed tortor cras fermentum, velit, ipsum lacus, dictum facilisi. Auctor ultrices pellentesque lorem viverra ornare iaculis amet. Et ac dolor nulla volutpat sed euismod in ultricies. Sed tortor cras fermentum, velit, ipsum lacus, dictum facilisi. Auctor ultrices pellentesque lorem viverra ornare iaculis amet.</Text> */}
          </Box>
        </Box>
        <Box className="main_img_box">
          <ul>
            {ticket?.id_ticket_solution?.files && ticket?.id_ticket_solution?.files.length > 0
              ? ticket.id_ticket_solution.files.map((item, index) => (
                <li
                  key={`file-attached-${index}`}
                  className='main_img_box_innr'
                >
                  <Box className='flex_btn_prnt'>
                    {colorMode === 'light' ? (
                      <Image
                        src='/img/pdf_img.svg'
                        alt=''
                        className='pdf_img'
                      />
                    ) : (
                      <Image
                        src='/img/pdf_img_dark.svg'
                        alt=''
                        className='pdf_img'
                      />
                    )}

                    {/* <Image src={`${item.thumbnail_medium}`}/> */}
                    <Text><a href={`${item.url}`} target='_blank'>{item.title}</a></Text>
                    <Button>

                      {colorMode === 'light' ? (
                        <Image
                          src='/img/delt_img.svg'
                          alt=''
                          className=''
                        />
                      ) : (
                        <Image
                          src='/img/delt_img_dark.svg'
                          alt=''
                          className=''
                        />
                      )}
                    </Button>
                  </Box>
                </li>
              ))
              : null}
          </ul>
        </Box>
      </Box>
    </>
  )
}