import React from 'react'
import { 
    Box,
    SimpleGrid,
    GridItem,
    Image,
    Heading,
    Link
} from '@chakra-ui/react'
import LayoutOne from '../components/layouts/LayoutOne'
// import { Link } from 'react-router-dom'

export default function OurWorkDetails() {
  return (
    <>
    <LayoutOne>
        <Box className="ourwrk_detail_main">
            <Box>
                <Box className='btn_and_hed'>
                    <Link href="/">
                        <Image src='/img/arrow_ic_aj.svg' alt='' />
                        <span>Back</span>
                    </Link>
                    <Heading as="h1">PORTFOLIO</Heading>
                </Box>
            </Box>
            <Box className="cntnr_box">
                <SimpleGrid columns={12} columnGap={4} rowGap={6} >
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                        <Box className="img_tp_bx">
                            <Image src="/img/on_xdc_img.png" />
                        </Box>
                        <Box className="grdnt_bttm_bx">
                            <a
                            href="https://www.onxdc.io"
                            target="_blank"
                            className="same_btn"
                            >
                            onXDC
                            </a>
                        </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                        <Box className="img_tp_bx">
                            <Image src="/img/dop_three_img.png" />
                        </Box>
                        <Box className="grdnt_bttm_bx">
                            <a
                            href="https://www.d0p3clubnft.com"
                            target="_blank"
                            className="same_btn"
                            >
                            D0P3 CLUB NFT
                            </a>
                        </Box>
                        </Box>
                    </GridItem>
                    {/* <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                        <Box className="img_tp_bx">
                            <Image src="/img/lava_img.png" />
                        </Box>
                        <Box className="grdnt_bttm_bx">
                            <a
                            href="/"
                            target="_blank"
                            className="same_btn"
                            >
                            on play
                            </a>
                        </Box>
                        </Box>
                    </GridItem> */}
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/sliceoftime_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://sliceoftime.netlify.app/" target="_blank" className="same_btn">Slice of time</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/onavax_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://onavax-frontend.vercel.app/dashboard" target="_blank" className="same_btn">onAvax</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/swapsicle.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://www.swapsicle.io/" target="_blank" className="same_btn">Swapsicle</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/piprotocol_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://www.pi-protocol.io" target="_blank" className="same_btn">pi-protocol</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/biggerminds_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://biggerminds-io-frontend.vercel.app" target="_blank" className="same_btn">bigger minds</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/cat_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://www.aggregator.capital/" target="_blank" className="same_btn">C.A.T</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/cultivator_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://cultivator-app.vercel.app" target="_blank" className="same_btn">cultivator</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/ecc_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://www.ecc.capital/" target="_blank" className="same_btn">ecc</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/rua_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://www.roundify.app/" target="_blank" className="same_btn">roundify</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/upcake_ing.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://upcake.netlify.app/" target="_blank" className="same_btn">upCake</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/upmatic_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://upmatic.netlify.app/" target="_blank" className="same_btn">upmatic</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/uptether_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://uptether.netlify.app/" target="_blank" className="same_btn">uptether</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/upbnb_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://upbnb.vercel.app" target="_blank" className="same_btn">upBNB</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/upcro_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://upcro2.netlify.app/" target="_blank" className="same_btn">upcro</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/nekotoshi_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://nekotoshi.netlify.app/" target="_blank" className="same_btn">nekotoshi</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/defidungeon_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://defidungeon.io/" target="_blank" className="same_btn">defi dungeon</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/empiredex_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://www.empiredex.org/#/" target="_blank" className="same_btn">Empire dex</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/prism_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://prismupdate.netlify.app/" target="_blank" className="same_btn">prism</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/longsharp_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://longsharp.netlify.app/" target="_blank" className="same_btn">longs & sharps</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/jigsaw_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://jigsaw-phi.vercel.app" target="_blank" className="same_btn">jigsaw</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/moonboots_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="/img/moonboots_V8.png" target="_blank" className="same_btn">moonboots</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/block_guru_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://www.blockgurus.ai" target="_blank" className="same_btn">BLOCK GURU</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/devlegion_img.png" />sqp
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://www.devlegion.ai" target="_blank" className="same_btn">dev legion</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/block_crtifide_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://www.blockcertified.io" target="_blank" className="same_btn">Block Certified</a>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/rootbridge_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://rootbridge.netlify.app/" target="_blank" className="same_btn">root bridge</a>
                            </Box>
                        </Box>
                    </GridItem>
                    {/* <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/blockvoodoodtl_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="" target="_blank" className="same_btn">block voodoo</a>
                            </Box>
                        </Box>
                    </GridItem>
                     */}
                    {/* <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/vineyard_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="/img/Vineyard_Landing.jpg" target="_blank" className="same_btn">vineyard</a>
                            </Box>
                        </Box>
                    </GridItem> */}
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/defimagic_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://defimagic.io/#/" target="_blank" className="same_btn">Defi magic</a>
                            </Box>
                        </Box>
                    </GridItem>
                    {/* <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/strigoi_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://strigoi.netlify.app/" target="_blank" className="same_btn">strigoi</a>
                            </Box>
                        </Box>
                    </GridItem> */}
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/pgbg_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://feedthemonkey.io/" target="_blank" className="same_btn">pbgb</a>
                            </Box>
                        </Box>
                    </GridItem>
                    {/* <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/spartacus_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="https://spartacus-dao.netlify.app/" target="_blank" className="same_btn">spartacus</a>
                            </Box>
                        </Box>
                    </GridItem> */}
                    {/* <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/diatom_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="/img/Diatom_Landing.jpg" target="_blank" className="same_btn">diatom</a>
                            </Box>
                        </Box>
                    </GridItem> */}
                    {/* <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/klima_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="/img/Klima_Landing.jpg" target="_blank" className="same_btn">klima</a>
                            </Box>
                        </Box>
                    </GridItem> */}
                    {/* <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/battleformars_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="" target="_blank" className="same_btn">Battle for mars</a>
                            </Box>
                        </Box>
                    </GridItem> */}
                    <GridItem colSpan={[12, 6, 4, 3]}>
                        <Box className="ourwrk_bxs">
                            <Box className="img_tp_bx">
                                <Image src="/img/deflect_img.png" />
                            </Box>
                            <Box className="grdnt_bttm_bx">
                                <a href="" target="_blank" className="same_btn">deflect</a>
                            </Box>
                        </Box>
                    </GridItem>
                </SimpleGrid>
            </Box>
        </Box>
    </LayoutOne>
    </>
  )
}
