import { React, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  IconButton,
  Button,
  Stack,
  Collapse,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Link } from "react-scroll";
import { toast } from "react-toastify";
import LoginModal from "../components/login/LoginModal";

export default function Header() {
  const {
    isOpen: isOpenLoginModal,
    onOpen: onOpenLoginModal,
    onClose: onCloseLoginModal,
  } = useDisclosure();

  const { isOpen, onToggle } = useDisclosure();

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  const emergency = () => {
    toast.error("Coming Soon")
  }
  return (
    <>
      <Box className={scroll ? "header scrolled" : "header"}>
        <Flex
          className="hdr_innr"
          py={{ base: 2 }}
          px={{ base: 4 }}
          align={"center"}
        >
          <Flex
            // flex={{ base: 1, md: 'auto' }}
            // ml={{ base: -2 }}
            display={{ base: "flex", lg: "none" }}
          >
            <IconButton
              className="tggl_btn"
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
            <Box as="a" href="/">
              <Image src="/img/logo.svg" className="logo" />
            </Box>
            {/* <Box className="cntr_cntnt">
                      <Box className="bg_design_bx">
                        <ui>
                            <li>
                                <Link 
                                  to='Devlegion'
                                  activeClass='active'
                                  spy={true}
                                  smooth={true}
                                  offset={-10}
                                  duration={500}
                                >
                                DEV Legion
                                </Link>
                            </li>
                            <li>
                                <Link
                                  to='Whatwedo'
                                  activeClass='active'
                                  spy={true}
                                  smooth={true}
                                  offset={-10}
                                  duration={500}
                                >
                                  What we do
                                </Link>
                            </li>
                            <li>
                                <Link
                                 to='Ourteam'
                                 activeClass='active'
                                 spy={true}
                                 smooth={true}
                                 offset={-60}
                                 duration={500}
                                >
                                  Our Team
                                </Link>
                            </li>
                            <li>
                                <Link
                                  to='Contactus'
                                  activeClass='active'
                                  spy={true}
                                  smooth={true}
                                  offset={-40}
                                  duration={500}
                                >
                                  Contact Us
                                </Link>
                            </li>
                        </ui>
                      </Box>
                    </Box> */}
          </Flex>

          <Stack
            className="hdr_btn_bx"
            flex={{ base: 1, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
          >
            <Button
              className="emrgncy_btn hdr_btn"
              fontSize={"sm"}
              fontWeight={400}
              variant={"link"}
              href={"#"}
              onClick={(e) => {emergency()}}
            >
              EMERGENCY
            </Button>
            {/* <Button
              className="cntct_us_btn"
              fontSize={"sm"}
              fontWeight={400}
              variant={"link"}
              href={"#"}
            >
              Contact Us
            </Button> */}

            <Link
              to="ContactUs"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className="cntct_us_btn hdr_btn"
            >
              Contact Us
            </Link>

            <Button
              className="login_btn hdr_btn"
              // display={{ base: 'none', md: 'inline-flex' }}
              fontSize={"sm"}
              fontWeight={600}
              // href={"#"}
              onClick={onOpenLoginModal}
            >
              Client Login
            </Button>
          </Stack>
        </Flex>

        <Collapse in={isOpen} animateOpacity className="cllps_main">
          <Stack
            className="innr_cllps"
            bg={useColorModeValue("white", "gray.800")}
            p={4}
            display={{ lg: "none" }}
          >
            <Stack spacing={4}>
              <Collapse
                in={isOpen}
                animateOpacity
                style={{ marginTop: "0!important" }}
                className="cllps_bx"
              >
                {/* <Stack
                  className="mbl_links"
                  mt={2}
                  pl={4}
                  borderLeft={1}
                  borderStyle={"solid"}
                  borderColor={useColorModeValue("gray.200", "gray.700")}
                  align={"start"}
                >
                  <ui>
                    <li>
                      <Link
                        to="Devlegion"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={-80}
                        duration={500}
                      >
                        DEV Legion
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="Whatwedo"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={-20}
                        duration={500}
                      >
                        What we do
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="Ourteam"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={-40}
                        duration={500}
                      >
                        Our Team
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="Contactus"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={-40}
                        duration={500}
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ui>
                </Stack> */}
                <Stack
                  className="hdr_btn_bx"
                  flex={{ base: 1, md: 0 }}
                  justify={"flex-end"}
                  direction={"row"}
                  spacing={6}
                >
                  <Button
                    className="emrgncy_btn hdr_btn"
                    fontSize={"sm"}
                    fontWeight={400}
                    variant={"link"}
                    href={"#"}
                    onClick={(e) => {emergency()}}
                  >
                    EMERGENCY
                  </Button>
                  <Link
                    to="ContactUs"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    className="cntct_us_btn hdr_btn"
                  >
                    Contact Us
                  </Link>
                  <Button
                    className="login_btn hdr_btn"
                    // display={{ base: 'none', md: 'inline-flex' }}
                    fontSize={"sm"}
                    fontWeight={600}
                    // href={"#"}
                    onClick={onOpenLoginModal}
                  >
                    Client Login
                  </Button>
                </Stack>
              </Collapse>
            </Stack>
          </Stack>
        </Collapse>
      </Box>
      <LoginModal 
        isOpen={isOpenLoginModal}
        onClose={onCloseLoginModal}
      />
    </>
  );
}
