import React from "react";
import {
  Box,
  Container,
  SimpleGrid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import { Element } from "react-scroll";

export default function SectionWate() {
  return (
    <>
      <Element name="Whatwedo">
        <Box className="sec_wat_prnt">
          <Container maxW="1180">
            {/* <Box className='wat_hed'>
                        <Heading as="h4">What<span> we do</span></Heading>
                    </Box> */}
            <Heading as="h1" className="def_h1">
              FUll Stack<span> SERVICES</span>
            </Heading>
            <Text className="new_peragp">Using AI, we speed up the development process, enhance the accuracy of our code, and increase our testing efficiency, ultimately delivering a product that exceeds your expectations while adhering to the set timeline and budget.</Text>
            <SimpleGrid columns={12} columnGap={0} rowGap={0}>
              <GridItem colSpan={[12, 12, 12, 4]}>
                <Box className="smrt_grd_box">
                  <Image src="/img/smart_img_01.png" alt="" />
                  <Heading as="h6">Web 3 : UI / UX</Heading>
                  <Text>
                    Constructing the secure, user-friendly future of the decentralized internet.
                  </Text>
                </Box>
              </GridItem>
              <GridItem colSpan={[12, 12, 12, 4]}>
                <Box className="smrt_grd_box">
                  <Image src="/img/smart_img_02.png" alt="" />
                  <Heading as="h6">Smart Contracts</Heading>
                  <Text>
                    With AI assistance, we streamline smart contract creation from NFTs to complex mechanics. Our code is optimized, gas-efficient, and thoroughly tested by experienced programmers using AI tools.
                  </Text>
                </Box>
              </GridItem>
              <GridItem colSpan={[12, 12, 12, 4]}>
                <Box className="smrt_grd_box">
                  <Image
                    src="/img/smart_img_03.png"
                    alt=""
                    className="smar_img_03"
                  />
                  <Heading as="h6">Dev TEAM</Heading>
                  <Text>
                    We're not just your project builder, we're your trusted dev team. We apply AI in planning and execution for quick and efficient results. Our 24/7 support ensures consistent progress and quick issue resolution.
                  </Text>
                </Box>
              </GridItem>
            </SimpleGrid>
          </Container>
        </Box>
      </Element>
    </>
  );
}
