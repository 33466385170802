import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Button,
  Image,
  useColorMode,
  useDisclosure,
  Heading,
  useToast,
} from '@chakra-ui/react'
import axios from "axios";
import { BASE_URL, CLICKUP_TICKET_STATUS } from "../../services/constant";
import httpStatus from "http-status";
import TicketDetail from "../tickets/TicketDetail";

export default function SecondTableDashboard() {
  const pagination = 5;
  const { colorMode, toggleColorMode } = useColorMode()
  const toast = useToast()
  const {
    isOpen: isDetailOpen,
    onOpen: onDetailOpen,
    onClose: onDetailClose,
  } = useDisclosure()

  const [loading, setLoading] = useState(false)
  const [ticket, setTicket] = useState({})
  const [resolvedTickets, setResolvedTickets] = useState([]);

  const loadData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('bluClientToken');
      const resp = await axios({
        method: 'get',
        url: `${BASE_URL}/api/ticket/resolved?size=${pagination}`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      if (resp.status === httpStatus.OK) {
        const json = resp.data;
        setResolvedTickets(json?.result?.result);
      }
    } catch (e) {
      // console.error("test", e?.message, e?.response?.status)
      if (e?.response?.status != httpStatus.UNAUTHORIZED) {
        let message = e?.response?.data?.message;
        if (!message) message = e.message
        toast({
          title: `Failed to load resolved ticket.`,
          description: message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    } finally {
      setLoading(false);
    }
  }

  const getDetail = (item) => {
    setTicket(item);
    onDetailOpen();
  }

  const handleBack = () => {
    loadData();
    setTicket({})
    onDetailClose()
  }

  useEffect(() => {
    if (!loading) {
      loadData();
    }
  }, [])

  return (
    <>
      <Box className='userlist_table_prnt ticketlist_table_prnt userlist_table_prnt_aj userlist_table_prnt_ab'>
        <Heading as="h5">Ready to review</Heading>
        <TableContainer className='userlist_table_cntnr'>
          <Table variant='simple'>
            <Thead className='report_list_head_bx'>
              <Tr>
                <Th>Ticket #</Th>
                <Th>Title</Th>
                <Th className='svrt_th'>Severity</Th>
                <Th>Category</Th>
                <Th>Type of report</Th>
                <Th className='cetner_items'>Status</Th>
                <Th>Open</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading && (
                <Tr className='border_befr_none'>
                  <Td className='min_height_14' colSpan={6}>
                    Loading...
                  </Td>
                </Tr>
              )}
              {resolvedTickets.length > 0 ? (
                resolvedTickets.map((item, index) => {
                  let status = 'Open_btn'
                  switch (item?.status) {
                    case CLICKUP_TICKET_STATUS.INPROGRESS:
                      status = 'Progress_btn'
                      break
                    case CLICKUP_TICKET_STATUS.RESOLVED:
                      status = 'Resolved_btn'
                      break
                    case CLICKUP_TICKET_STATUS.CLOSED:
                      status = 'Closed_btn'
                      break
                  }
                  return (
                    <Tr key={index}>
                      <Td>
                        <Box>{item.ticket_no}</Box>
                      </Td>
                      <Td className='cusrtm_pargf'>
                        <Box>{item.name}</Box>
                      </Td>
                      <Td>
                        <Box className='pdng_0'>
                          <Box
                            className={
                              item.severity?.name == 'Critical' ? 'crtcl_svrt status' :
                                item.severity?.name == 'High' ? 'high_svrt status' :
                                  item.severity?.name == 'Medium' ? 'mdum_svrt status' : 'low_svrt status'
                            }
                          >
                            {item.severity?.name}
                          </Box>
                        </Box>
                      </Td>
                      <Td>
                        <Box>
                          {item?.category?.name}
                        </Box>
                      </Td>
                      <Td align='center'>
                        <Box className='rprt_imgs_box'>
                          {
                            item?.tags && item?.tags.length > 0 ?

                              item.tags[0] == 'issue' ?
                                <Image src='/img/exclm_t_lst_ic.svg' alt='' /> :
                                item.tags[0] == 'request' ?
                                  <Image src="/img/traced_ic_as.svg" alt="" /> : <></> : <></>
                          }
                        </Box>
                      </Td>
                      <Td>
                        <Box
                          className={`${status} cetner_items class status_btn_prnt`}
                        >
                          <Button className='def_btn'>{item?.status}</Button>
                        </Box>
                      </Td>
                      <Td>
                        <Box className='last_two_btn'>
                          <Box className=''>
                            <Button
                              className=''
                              onClick={() => getDetail(item)}
                            >
                              {colorMode === 'light' ? (
                                <Image
                                  src='/img/eye_ic_dashbrd.svg'
                                  alt=''
                                  className=''
                                />
                              ) : (
                                <Image
                                  src='/img/eye_ic_dashbrd_dark.svg'
                                  alt=''
                                  className=''
                                />
                              )}
                            </Button>
                          </Box>
                        </Box>
                      </Td>
                    </Tr>
                  )
                })
              ) : !loading ? (
                <Tr className='border_befr_none'>
                  <Td className='min_height_14' colSpan={6}>
                    No Data
                  </Td>
                </Tr>
              ) : null}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      {ticket ?
        <TicketDetail
          ticketTable={ticket}
          isDetailOpen={isDetailOpen}
          handleBack={handleBack}
        />
        : <></>}
    </>
  )
}
