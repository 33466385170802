import { Box, Heading, useColorMode, Image } from "@chakra-ui/react";
import React from "react";
import AccountInfo from "../components/account/AccountInfo";
import NotificationsRightContent from "../components/dashboard/NotificationsRightContent";
import LayoutTwo from "../components/layouts/LayoutTwo";

export default function Account() {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <>
      <LayoutTwo>
        <Box className="userlist_page">
          <Box className="usr_list_cntnr">
            <Heading as="h2" className="def_head_dbrd">
              account
            </Heading>
            <Box className="dahboardgrid">
                <Box className="user_cntnt_box flex75">
                {colorMode === "light" ? (
                    <Image
                    src="/img/user_list_top_img.png"
                    alt=""
                    className="user_list_top_img"
                    />
                ) : (
                    <Image
                    src="/img/user_list_top_img_dark.png"
                    alt=""
                    className="user_list_top_img"
                    />
                )}
                {colorMode === "light" ? (
                    <Image
                    src="/img/user_list_btm_img.png"
                    alt=""
                    className="user_list_btm_img"
                    />
                ) : (
                    <Image
                    src="/img/user_list_btm_img_dark.png"
                    alt=""
                    className="user_list_btm_img"
                    />
                )}
                <Box className="user_cntr_line_bg" />
                  <AccountInfo />
                </Box>
                <Box className="flex25">
                  <Box className="main_rightbx">
                    {colorMode === "light" ? (
                        <Image
                        src="/img/ntfctn_tp.png"
                        alt=""
                        className="ntfctn_tp"
                        />
                    ) : (
                        <Image
                        src="/img/ntfctn_tp_dark.png"
                        alt=""
                        className="ntfctn_tp"
                        />
                    )}
                    {colorMode === "light" ? (
                        <Image
                        src="/img/ntfctn_btm.png"
                        alt=""
                        className="ntfctn_btm"
                        />
                    ) : (
                        <Image
                        src="/img/ntfctn_btm_dark.png"
                        alt=""
                        className="ntfctn_btm"
                        />
                    )}
                    <Box className="ntfctn_mddl" />
                      <Box className="right_content_main">
                          <NotificationsRightContent />
                      </Box>
                  </Box>
                </Box>
            </Box>
          </Box>
        </Box>
        
      </LayoutTwo>
    </>
  );
}

