import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../../pages/Footer'
import Header from '../../pages/Header'

const LayoutOne = (props) => {
  const navigate = useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  useEffect(() => {
    const token = localStorage.getItem('bluClientToken')
    if (token) {
      setIsAuthenticated(true)
      navigate('/dashboard')
    } else {
      setIsAuthenticated(false)
    }
  }, [])

  return isAuthenticated ? null : (
    <div className={props.className}>
      <Header />
      {props.children}
      <Footer />
    </div>
  )
}

export default LayoutOne
