import React from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-scroll";

export default function BecomeUnlimited() {
  return (
    <>
      <Box className="bcm_unlmtd_main_bx">
        <Box className="hdng_and_btn_bx">
          <Heading as="h4">AI powered, Integrity Driven</Heading>
          <Heading as="h2">Full stack development</Heading>
          <Link
            to="OurBuilds"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            className="vw_our_wrk_btn"
          >
            View Our Work
          </Link>
          {/* <Box as="a"   href="ourworkdetails" target="_blank" >View Our Work</Box> */}
        </Box>
        <Box className="plus_img_bx">
          <Image src="/img/plus_img.png" alt="" />
        </Box>
        <Box className="warning_grid_box">
          <SimpleGrid columns={12} columnGap={12} rowGap={0}>
            <GridItem colSpan={[12, 12, 12, 6]}>
              <Box className="warning_bx">
                <Text>
                  You can't afford to make bad decisions.
                  <span>
                  </span>
                </Text>
                <Text className="bttm_txt">
                  In the unforgiving landscape of blockchain development, one misstep can lead to substantial loss. Choosing the right partner isn't just a good decision—it's a survival strategy. 
                </Text>
              </Box>
            </GridItem>
            <GridItem colSpan={[12, 12, 12, 6]} className="flex_cntr">
              <Box className="circle_bx">
                <Text>
                  The path to blockchain 
                  <br />triumph is fraught with 
                  <span>  unsavory </span> <br />
                  <span>  characters  </span><br />
                  and <span> pitfalls </span> that can<br />
                  cost you <span>time</span>,<br />
                  <span>money</span> and your<br />
                  <span> reputation</span>.
                </Text>
              </Box>
            </GridItem>
          </SimpleGrid>
        </Box>
      </Box>
    </>
  );
}
