import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Image,
  useColorMode,
  Heading,
  SimpleGrid,
  GridItem,
  Text,
  Textarea,
  useToast,
  ModalBody,
  ModalFooter
} from '@chakra-ui/react';
import { BASE_URL, CLICKUP_TICKET_STATUS } from '../../services/constant';
import axios from 'axios';
import httpStatus from "http-status";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Select, { components } from "react-select";
import {
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import ViewReportSolution from './ViewReportSolution';

export const Control = (props) => {
  return (
    <>
      <Label isFloating={props.isFocused || props.hasValue}>{props?.selectProps?.data}</Label>
      <components.Control {...props} />
    </>
  );
};

const Label = styled.label`
  left: 10px;
  pointer-events: none;
  position: absolute;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  z-index: 1;

  top: ${(props) => (props.isFloating ? `5px` : `35%`)};
  font-size: ${(props) => (props.isFloating ? `0.5rem` : `1rem`)};
`;

export default function EditReport({ setIsRefresh, ticket, setIsView, getDetail }) {

  const { colorMode, toggleColorMode } = useColorMode()
  const [resize, setResize] = React.useState('horizontal')
  const [files, setFiles] = useState([])
  const [optionSeverity, setOptionSeverity] = useState([])
  const [optionOccurrence, setOptionOccurrence] = useState([])
  const [optionCategory, setOptionCategory] = useState([])
  const [optionClient, setOptionClient] = useState([])
  const [optionProject, setOptionProject] = useState([])
  const [isButtonDisable, setIsButtonDisable] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [attachment, setAttachment] = useState(null)
  const [attachmentFiles, setAttachmentFiles] = useState([])
  const [status, setStatus] = useState(ticket?.status ?? CLICKUP_TICKET_STATUS.OPEN)
  const navigate = useNavigate()
  const toast = useToast()

  const [form, setForm] = useState([
    {
      name: '',
      tags: false,
      description: '',
      severity: null,
      occurrence: null,
      category: null,
      client: null,
      project: null,
      files: {},
    },
  ])

  const resetForm = () => {
    setForm([
      {
        name: '',
        tags: false,
        description: '',
        severity: '',
        occurrence: '',
        category: '',
        client: '',
        project: '',
        files: {},
      },
    ])
  }

  const getFormData = async () => {
    try {
      const token = localStorage.getItem('bluClientToken')
      const resp = await axios({
        method: 'post',
        url: `${BASE_URL}/api/ticket/form`,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })

      if (resp.status === httpStatus.OK) {
        const json = resp.data
        for (const item of json) {
          let options = []
          for (const option of item.options) {
            options.push({
              value: option.id ? option.id : option._id,
              label: option.name,
            })
          }

          switch (item.name) {
            case 'Severity':
              setOptionSeverity(options)
              break
            case 'Occurrence':
              setOptionOccurrence(options)
              break
            case 'Category':
              setOptionCategory(options)
              break
            case 'Client':
              setOptionClient(options)
              break
            case 'Project':
              setOptionProject(options)
              break
            default:
              break
          }
        }
      }
    } catch (error) {
      console.error(error)
      if (error.response.status == httpStatus.UNAUTHORIZED) {
        toast({
          title: 'You are not logged in.',
          description: 'Please login first',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
        navigate('/')
      } else {
        let message = error.message
        if (error?.response?.data?.length > 0) {
          message = error?.response?.data[0]
        }
        toast({
          title: `Failed to load data`,
          description: message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    }
  }

  const addData = () => {
    let f = [
      ...form,
      {
        name: '',
        tags: false,
        description: '',
        severity: undefined,
        occurrence: undefined,
        category: undefined,
        client: undefined,
        project: undefined,
        files: undefined,
      },
    ]
    setForm(f)
  }

  const handleEditTicket = async () => {
    const data = form[0];
    try {
      setIsLoading(true)
      const token = localStorage.getItem('bluClientToken')
      const resp = await axios({
        method: 'put',
        url: `${BASE_URL}/api/ticket/update2/${ticket._id}`,
        headers: {
          Authorization: 'Bearer ' + token,
        },
        data: data,
      })
      if (resp?.status === httpStatus.OK) {
        const json = resp.data;
        if (attachmentFiles.length > 0) {
          try {
            const res = await axios.post(
              `${BASE_URL}/api/ticket/upload/${ticket.id_task_clickup}`,
              attachment,
              {
                headers: {
                  Authorization: 'Bearer ' + token,
                },
              },
            )
          } catch (error) {
            console.error(error)
          }
        }

        toast({
          title: json?.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })

      }
    } catch (error) {
      console.error(error)
      if (error.response.status == httpStatus.UNAUTHORIZED) {
        toast({
          title: 'You are not logged in.',
          description: 'Please login first',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
        navigate('/')
      } else {
        let message = error?.message
        if (error?.response?.data?.error) {
          message = error?.response?.data.error
        }
        toast({
          title: `Failed to update data`,
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    } finally {
      getDetail();
      setIsLoading(false);
      setIsView(true);
      // setIsRefresh(true)
      // resetForm()
    }
  }

  const handleRemove = (index) => {
    let f = [...form]
    setForm(f.filter((value, i) => i != index))
  }

  const handleRemoveAll = () => {
    let f = {
      name: '',
      description: '',
      severity: undefined,
      occurrence: undefined,
      category: undefined,
      client: undefined,
      project: undefined,
      files: undefined,
    }
    setAttachment(null)
    setAttachmentFiles([])
    setIsButtonDisable(false)
    setForm([f])
  }

  function uploadSingleFile(e, index) {
    let formData = new FormData()
    let files = []
    if (attachmentFiles.length > 0) files = attachmentFiles
    if (attachment) formData = attachment
    let data = [...form]
    for (const file of e.target.files) {
      formData.append('attachment', file)
      files.push(file)
    }
    setAttachment(formData)
    setAttachmentFiles(files)
    data[index].files_new = formData
    setForm(data)
  }

  function deleteFileLocal(indexForm, indexFile) {
    let data = [...form];
    const temp = data[indexForm].files.filter((item, index) => index != indexFile);
    data[indexForm].files = temp;
    setForm(data);
  }

  function deleteFileNew(indexForm, indexFile) {
    let data = [...form];
    const temp = data[indexForm].files_new.filter((item, index) => index != indexFile);
    data[indexForm].files_new = temp;
    setForm(data);

    const files = []
    const formData = new FormData()
    for (let i = 0; i < attachmentFiles.length; i += 1) {
      if (i !== indexFile) {
        formData.append('attachment', attachmentFiles[i])
        files.push(attachmentFiles[i])
      }
    }
    setAttachment(formData)
    setAttachmentFiles(files)
  }

  const loadTicket = async () => {
    await getFormData()
    if (ticket) {
      let f = [
        {
          name: ticket?.name,
          tags: false,
          description: ticket?.description,
          severity: {
            value: ticket?.severity?.id,
            label: ticket?.severity?.name
          },
          occurrence: {
            value: ticket?.occurrence?.id,
            label: ticket?.occurrence?.name
          },
          category: {
            value: ticket?.category?.id,
            label: ticket?.category?.name
          },
          client: {
            value: ticket?.id_client?._id,
            label: ticket?.id_client?.name,
          },
          project: {
            value: ticket?.id_project?._id,
            label: ticket?.id_project?.name,
          },
          files: ticket?.files,
          files_new: []
        },
      ]
      setForm(f)
    }
  }

  useEffect(() => {
    let disable = false
    for (const item of form) {
      if (item.name === '') {
        disable = true
        break
      }
      if (item.description === '') {
        disable = true
        break
      }
      if (item.severity === undefined) {
        disable = true
        break
      }
      if (item.occurrence === undefined) {
        disable = true
        break
      }
      if (item.category === undefined) {
        disable = true
        break
      }
      if (item.project === undefined) {
        disable = true
        break
      }
      if (item.client === undefined) {
        disable = true
        break
      }
    }
    setIsButtonDisable(disable)
  }, [form])

  useEffect(() => {
    loadTicket()
  }, [ticket])

  return (
    <>
      <ModalBody className='modal_body'>
        <Box className='reeport_prnt_min'>
          {form.map((item, index) => {
            return (
              <Box className='reaport_trik_inrr_bx' key={index}>
                <Box className='form_cntrl_box form_cntrl_boxv2_ab'>
                  <SimpleGrid columns={12} columnGap={5} rowGap={0}>
                    <GridItem colSpan={[12, 12, 6, 6]}>
                      <Text className='description_text_p'>
                        Data <span>All Fields Required</span>
                      </Text>
                      <Box className='inpt_prnt_box'>
                        <FormControl variant='floating' id='first-name'>
                          <Input
                            type='text'
                            placeholder=' '
                            value={item.name}
                            onChange={(e) => {
                              if (status === CLICKUP_TICKET_STATUS.INPROGRESS) return
                              let data = [...form];
                              data[index].name = e.target.value;
                              setForm(data);
                            }}
                          />
                          <FormLabel>Title</FormLabel>
                        </FormControl>
                      </Box>
                      <Box className='inpt_prnt_slct_nl'>
                        <Select
                          data="Severity"
                          placeholder=''
                          components={{ Control }}
                          // menuIsOpen={true}
                          options={optionSeverity}
                          // value={form.severity}
                          value={optionSeverity.filter(function (option) {
                            return option.value === item.severity.value;
                          })}
                          classNamePrefix="df_select"
                          onChange={(e) => {
                            if (status === CLICKUP_TICKET_STATUS.INPROGRESS) return
                            let data = [...form]
                            data[index].severity = e;
                            setForm(data)
                          }}
                        />
                      </Box>
                      <Box className='inpt_prnt_slct_nl'>
                        <Select
                          data="Occurrence"
                          placeholder=''
                          components={{ Control }}
                          classNamePrefix="df_select"
                          options={[...form][index].tags ? optionOccurrence.slice(-3) : optionOccurrence.slice(0, 5)}
                          value={optionOccurrence.filter(function (option) {
                            return option.value === item.occurrence.value;
                          })}
                          onChange={(e) => {
                            if (status === CLICKUP_TICKET_STATUS.INPROGRESS) return
                            let data = [...form]
                            data[index].occurrence = e;
                            setForm(data)
                          }}
                        >
                        </Select>
                      </Box>
                      <Box className='inpt_prnt_slct_nl'>
                        <Select
                          data="Category"
                          placeholder=''
                          components={{ Control }}
                          classNamePrefix="df_select"
                          options={[...form][index].tags ? optionCategory.slice(0, 6) : optionCategory.slice(-5)}
                          value={optionCategory.filter(function (option) {
                            return option.value === item.category.value;
                          })}
                          onChange={(e) => {
                            if (status === CLICKUP_TICKET_STATUS.INPROGRESS) return
                            let data = [...form]
                            data[index].category = e;
                            setForm(data)
                          }}
                        >
                        </Select>
                      </Box>
                      <Box className='inpt_prnt_slct_nl'>
                        <Select
                          data="Client"
                          placeholder=''
                          components={{ Control }}
                          classNamePrefix="df_select"
                          options={optionClient}
                          value={optionClient.filter(function (option) {
                            return option.value === item.client.value;
                          })}
                          onChange={(e) => {
                            if (status === CLICKUP_TICKET_STATUS.INPROGRESS) return
                            let data = [...form]
                            data[index].client = e;
                            setForm(data)
                          }}
                        >
                        </Select>
                      </Box>
                      <Box className='inpt_prnt_slct_nl'>
                        <Select
                          data="Project"
                          placeholder=''
                          components={{ Control }}
                          classNamePrefix="df_select"
                          options={optionProject}
                          value={optionProject.filter(function (option) {
                            return option.value === item.project.value;
                          })}
                          onChange={(e) => {
                            if (status === CLICKUP_TICKET_STATUS.INPROGRESS) return
                            let data = [...form]
                            data[index].project = e;
                            setForm(data)
                          }}
                        >
                        </Select>
                      </Box>
                    </GridItem>
                    <GridItem
                      colSpan={[12, 12, 6, 6]}
                      className='mt_bx_ripon_siv'
                    >
                      <Text className='description_text_p'>
                        {item.tags ? "Request" : "Issue"} Description
                      </Text>
                      <Box className='text_ariya_box'>
                        <Textarea
                          placeholder='Describe the issue in detail...'
                          size='sm'
                          resize={resize}
                          value={item.description}
                          onChange={(e) => {
                            if (status === CLICKUP_TICKET_STATUS.INPROGRESS) return
                            let data = [...form]
                            data[index].description = e.target.value
                            setForm(data)
                          }}
                        />
                      </Box>
                    </GridItem>
                  </SimpleGrid>
                  <Box className='Add_files_prnt'>
                    <div className='form-group preview'>
                      {item?.files && item?.files.length > 0
                        ? item.files.map((item, indexFile) => {
                          return (
                            <div key={index}>
                              {colorMode === 'light' ? (
                                <img src='/img/file_icon.svg' alt='' />
                              ) : (
                                <img src='/img/file_icon_dark.svg' alt='' />
                              )}
                              <p><Text><a href={`${item.url}`} target='_blank'>{item.title}</a></Text></p>
                              <button
                                type='button'
                                onClick={() => deleteFileLocal(index, indexFile)}
                              >
                                {colorMode === 'light' ? (
                                  <Image src='/img/delete_icon.svg' alt='' />
                                ) : (
                                  <Image src='/img/delete_icon_dark.svg' alt='' />
                                )}
                              </button>
                            </div>
                          )
                        })
                        : null}
                      {attachmentFiles &&
                        attachmentFiles.map((value, indexFile) => {
                          return (
                            <div key={indexFile}>
                              {colorMode === 'light' ? (
                                <img src='/img/file_icon.svg' alt='' />
                              ) : (
                                <img src='/img/file_icon_dark.svg' alt='' />
                              )}
                              <p><Text>{value?.name}</Text></p>
                              <button
                                type='button'
                                onClick={() => deleteFileNew(index, indexFile)}
                              >
                                {colorMode === 'light' ? (
                                  <Image src='/img/delete_icon.svg' alt='' />
                                ) : (
                                  <Image src='/img/delete_icon_dark.svg' alt='' />
                                )}
                              </button>
                            </div>
                          )
                        })}
                    </div>
                    <Box className='Add_files_bx'>
                      <form>
                        <div className='form-group'>
                          <Heading as='h4'>
                            + Add files
                            <input
                              type='file'
                              name='attachment'
                              disabled={files.length === 5}
                              className='form-control'
                              onChange={(e) => uploadSingleFile(e, index)}
                              multiple
                            />
                          </Heading>
                        </div>
                        {/* <button
                    type='button'
                    className='btn btn-primary btn-block'
                    onClick={upload}
                  >
                    Upload
                  </button> */}
                      </form>

                      <Text>You can add .JPG, .PNG, .PDF type of files</Text>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )
          })}
          {/* <Button className='more_ticket_def' onClick={addData}>
          <span>+ Add one more ticket</span>
        </Button> */}
        </Box>
        <ViewReportSolution ticket={ticket} />
      </ModalBody>
      <ModalFooter className='foter_this_modl'>
        <Button
          colorScheme='blue'
          mr={3}
          className='back_btn'
          onClick={(e) => {
            setIsView(true)
          }}
        >
          Cancel Edit
        </Button>

        <Button
          variant='ghost'
          className='close_btn'
          disabled={isButtonDisable || isLoading}
          onClick={() => handleEditTicket()}
        >
          {isLoading ? 'Saving' : 'Save Changes'}
        </Button>
      </ModalFooter>
    </>
  )
}
