import './assets/css/App.scss';
import './assets/css/DarkMode.scss';
// import Header from './pages/Header';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
// import Footer from './pages/Footer';
import OurWorkDetails from './pages/OurWorkDetails';
import UserList from './pages/UserList';
import Dashboard from './pages/Dashboard';
import Tickets from './pages/Tickets';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Account from './pages/Account';
import {
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import ClientReport from './pages/ClientReport';
import ClientReportDetail from './pages/report/ClientReportDetail';
import Invoices from './pages/Invoices';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)"
};

export const theme = extendTheme({
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles
              }
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label": {
              ...activeLabelStyles
            },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top"
            }
          }
        }
      }
    }
  }
});

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/userlist" element={<UserList />} />
            <Route path="/client-report" element={<ClientReport />} />
            <Route path="/clientreportdetail/:id" element={<ClientReportDetail />} />
            <Route path="/ourworkdetails" element={<OurWorkDetails />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/account" element={<Account />} />
            <Route path="/tickets" element={<Tickets />} />
            <Route path="/reset-password/:id" element={<HomePage />} />
            <Route path="/invoices" element={<Invoices />} />

          </Routes>

        </BrowserRouter>

        <ToastContainer
          position='top-center'
          autoClose={5000}
          autoDismiss={true}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          icon={true}
          theme={'colored'}
          pauseOnHover={false}
          rtl={false}
          limit={1}
        />
      </ChakraProvider>
    </Web3ReactProvider>
  );
}

export default App;