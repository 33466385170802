import React, { useEffect, useState } from 'react'
import LayoutTwo from '../components/layouts/LayoutTwo'

import {
  Heading,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Box,
  Button,
  Image,
  useColorMode,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
} from '@chakra-ui/react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { format, startOfMonth } from 'date-fns'
import { Textarea } from '@chakra-ui/react'
import httpStatus from 'http-status'
import { BASE_URL } from '../services/constant'
import { INVOICE_ACTION, INVOICE_CLIENT_STATUS } from '../utils/constants'
import InvoiceTable from '../components/invoices/InvoiceTable'
import Pagination from '../components/pagination'
import InvoiceModalDetail from '../components/invoices/InvoiceModalDetail'
import InvoiceModalPay from '../components/invoices/InvoiceModalPay'
import ModalConnectWallet from '../components/invoices/ModalConnectWallet'
import { useWeb3React } from '@web3-react/core'

export default function Invoices() {

  const { activate, account, library } = useWeb3React();
  const { colorMode, toggleColorMode } = useColorMode()
  const [selectedStatus, setSelectedStatus] = useState(INVOICE_CLIENT_STATUS.ALL);
  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), "MM/dd/yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM/dd/yyyy"));

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [pendingInvoices, setPendingInvoices] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [selectedInvoiceToPay, setSelectedInvoiceToPay] = useState([]);

  const { isOpen: isInvoiceDetailOpen, onOpen: onInvoiceDetailOpen, onClose: onInvoiceDetailClose } = useDisclosure()

  const { isOpen: isInvoiceExportOpen, onOpen: onInvoiceExportOpen, onClose: onInvoiceExportClose } = useDisclosure();
  const { isOpen: isConnectWalletOpen, onOpen: onConnectWalletOpen, onClose: onConnectWalletClose } = useDisclosure();
  const { isOpen: isInvoicePayConfirmOpen, onOpen: onInvoicePayConfirmOpen, onClose: onInvoicePayConfirmClose } = useDisclosure();

  const { isOpen: multipleInvoiceisOpen, onOpen: multipleInvoicelOpen, onClose: omultipleInvoiceClose } = useDisclosure();

  const { isOpen: MetamaskisOpen, onOpen: MetamaskOpen, onClose: MetamaskClose } = useDisclosure();
  const { isOpen: PaymtConformisOpen, onOpen: PaymtConformOpen, onClose: PaymtConformClose } = useDisclosure();
  const { isOpen: paidInvoceisOpen, onOpen: paidInvoceOpen, onClose: paidInvoceClose } = useDisclosure();
  const { isOpen: RejectInvoisOpen, onOpen: RejectInvoOpen, onClose: RejectInvoClose } = useDisclosure();
  const { isOpen: RejectInvoTwoisOpen, onOpen: RejectInvoTwoOpen, onClose: RejectInvoTwoClose } = useDisclosure();

  const openMetamaskModal = () => {
    MetamaskOpen()
    if (isInvoiceDetailOpen) {
      onInvoiceDetailClose()
    }
  }
  const openPaymentConfirmModal = () => {
    PaymtConformOpen()
    if (MetamaskisOpen) {
      MetamaskClose()
    }
  }

  const onSelectAll = () => {
    if (selectAll) {
      setSelectAll(false);
      setSelectedInvoices([]);
    }
    else {
      setSelectAll(true);
      setSelectedInvoices(pendingInvoices);
    }
  }

  const onSelectInvoice = (selectedInvoice, isSeleted) => {
    let result = [];
    if (isSeleted) {
      setSelectedInvoices([...selectedInvoices, selectedInvoice])
    } else {
      selectedInvoices.map((item) => {
        if (item !== selectedInvoice) result.push(item)
      })
      setSelectedInvoices(result)
    }
  }

  const getInvoices = async (page = 1) => {
    if (startDate === undefined || endDate === undefined) return;
    try {
      setInvoices([]);
      setLoading(true);
      const bluClientToken = localStorage.getItem('bluClientToken');
      const response = await fetch(BASE_URL + `/api/invoice-client/client/all?&page=${page}&status=${selectedStatus}&start=${startDate}&end=${endDate}`, {
        headers: { 'Authorization': 'Bearer ' + bluClientToken }
      });
      const data = await response.json()
      if (response.status == httpStatus.OK) {
        if (data.result.result.length > 0) {
          setInvoices(data.result.result);
          setIsAdmin(data.result.isAdmin);
          setPage(data.result.currentPage);
          setTotalPage(data.result.totalPages);

          let pay = [];
          for (const item of data.result.result) {
            if (item.status == INVOICE_CLIENT_STATUS.SUBMITTED) {
              pay.push(item);
            }
          }
          setPendingInvoices(pay);
          setSelectedInvoices(pay);
        }
      }
    } catch (error) {
    }
    setLoading(false);
  };

  const setInvoice = (invoice, action) => {
    setSelectedInvoice(invoice);
    switch (action) {
      case INVOICE_ACTION.DETAIL:
        onInvoiceDetailOpen();
        break;
      case INVOICE_ACTION.EXPORT:
        onInvoiceExportOpen();
        break;
    }
  }

  useEffect(() => {
    setSelectAll(selectedInvoices.length == pendingInvoices.length)
  }, [selectedInvoices])

  useEffect(() => {
    getInvoices();
  }, [selectedStatus, startDate, endDate]);

  const onClickPay = (invoices) => {
    if (!selectedInvoice) return;
    setSelectedInvoiceToPay(invoices);
    if (account && !isInvoicePayConfirmOpen) {
      onInvoicePayConfirmOpen();
    }
    else if (!isConnectWalletOpen) {
      onConnectWalletOpen();
    }
    else {
      setSelectedInvoiceToPay([]);
    }
  }

  const onSuccessPay = () => {
    getInvoices();
    onInvoiceDetailClose()
  }

  return (
    <>
      <LayoutTwo>
        <Box className='userlist_page '>
          <Box className='usr_list_cntnr'>
            <Box className='user_cntnt_box user_cntnt_box_new'>
              {colorMode === 'light' ? (
                <Image src='/img/user_list_top_img_v2.png' alt='' className='user_list_top_img' />
              ) : (
                <Image src='/img/user_list_top_img_v2.png' alt='' className='user_list_top_img' />
              )}
              {colorMode === 'light' ? (
                <Image src='/img/user_list_btm_img_v2.png' alt='' className='user_list_btm_img' />
              ) : (
                <Image src='/img/user_list_btm_img_v2.png' alt='' className='user_list_btm_img' />
              )}
              <Box className='user_cntr_line_bg user_cntr_line_bg_v2' />
              <Box className='invoc_tab'>
                <Box className='header_client_rpt_list invoice_min_bx'>
                  <Heading as='h2' className='def_head_dbrd'>
                    Invoices
                  </Heading>
                  <Box className='right_date_btn'>
                    <Box className='right_datepkr'>
                      <Box className='frst_right_dtepkr'>
                        {colorMode === "light" ? (
                          <Image src="/img/clock_ic.svg" alt="" className="clock_ic_as" />
                        ) : (
                          <Image src="/img/clock_ic_dark.svg" alt="" className="clock_ic_as" />
                        )}
                        <Text className="space_btwn">From</Text>
                        <Box className="date_bx">
                          <Image src="/img/datepicker_ic.svg" alt="" />
                          <DateRangePicker
                            initialSettings={{
                              singleDatePicker: true,
                              showDropdowns: false,
                              opens: 'left',
                            }}
                            onCallback={(start, end, label) => {
                              let date = format(start.toDate(), "MM/dd/yyy");
                              setStartDate(date);
                            }}
                          >
                            <input type="text" className="form-control"
                              placeholder='Choose Start Period'
                              value={startDate} />
                          </DateRangePicker>
                        </Box>
                        <Text className="space_btwn space_btwn_to">To</Text>
                        <Box className="date_bx">
                          <Image src="/img/datepicker_ic.svg" alt="" />
                          <DateRangePicker
                            initialSettings={{
                              singleDatePicker: true,
                              showDropdowns: false,
                              opens: 'left',
                            }}
                            onCallback={(start, end, label) => {
                              let date = format(end.toDate(), "MM/dd/yyy");
                              setEndDate(date)
                            }}
                          >
                            <input type="text" className="form-control"
                              placeholder='Choose End Period'
                              value={endDate} />
                          </DateRangePicker>
                        </Box>
                      </Box>
                    </Box>
                    <Box className='gnrl_rport_bx'>
                      <Button className='gnrl_rpt_btn'
                        disabled={selectedInvoices?.length == 0}
                        onClick={() => onClickPay(selectedInvoices)}
                      >
                        Pay Now
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <ul className='invoce_def_tb'>
                  <li className={selectedStatus == INVOICE_CLIENT_STATUS.ALL ? 'all_btn_invoce' : ''}><Button className="frst_all" onClick={() => setSelectedStatus(INVOICE_CLIENT_STATUS.ALL)}>All</Button></li>
                  <li className={selectedStatus == INVOICE_CLIENT_STATUS.PAID ? 'all_btn_invoce' : ''}><Button className="frst_draft" onClick={() => setSelectedStatus(INVOICE_CLIENT_STATUS.PAID)}>{INVOICE_CLIENT_STATUS.PAID}</Button></li>
                  <li className={selectedStatus == INVOICE_CLIENT_STATUS.SUBMITTED ? 'all_btn_invoce' : ''}><Button className="frst_submited" onClick={() => setSelectedStatus(INVOICE_CLIENT_STATUS.SUBMITTED)}>{INVOICE_CLIENT_STATUS.SUBMITTED}</Button></li>
                  <li className={selectedStatus == INVOICE_CLIENT_STATUS.REJECTED ? 'all_btn_invoce' : ''}><Button className="frst_submited" onClick={() => setSelectedStatus(INVOICE_CLIENT_STATUS.REJECTED)}>{INVOICE_CLIENT_STATUS.REJECTED}</Button></li>
                </ul>
              </Box>
              <InvoiceTable
                invoices={invoices}
                isAdmin={isAdmin}
                loading={loading}
                setInvoice={setInvoice}
                onSelectInvoice={onSelectInvoice}
                selectedInvoices={selectedInvoices}
                selectAll={selectAll}
                onSelectAll={onSelectAll} />

              <Pagination
                page={Number(page)}
                totalPages={Number(totalPage)}
                handlePagination={(page) => getInvoices(page)}
              />

              <InvoiceModalDetail
                invoice={selectedInvoice}
                isInvoiceDetailOpen={isInvoiceDetailOpen}
                onInvoiceDetailClose={onInvoiceDetailClose}
                onClickPayNow={onClickPay}
                getInvoices={getInvoices}
              />

              <InvoiceModalPay
                selectedInvoices={selectedInvoiceToPay}
                isInvoicePayConfirmOpen={isInvoicePayConfirmOpen}
                onInvoicePayConfirmClose={onInvoicePayConfirmClose}
                getInvoices={getInvoices}
                onSuccessPay={onSuccessPay}
              />

              <ModalConnectWallet
                isConnectWalletOpen={isConnectWalletOpen}
                onConnectWalletClose={onConnectWalletClose}
                onConnected={onInvoicePayConfirmOpen}
              />
            </Box>
          </Box>
        </Box>
      </LayoutTwo>

      {/* <Box className="modal_main">
        <Modal isOpen={isInvoiceDetailOpen} onClose={onInvoiceDetailClose} isCentered={false}>
          <ModalOverlay />
          <ModalContent className="modal_report Invoice_in_ab">
            <ModalHeader className="moda_hed">
              <Heading as="h2" className='invos_head'>Invoice #548</Heading>
            </ModalHeader>
            <ModalBody className="modal_body Invoice_in_ab_body">
              <Box className='input_min_bx_wi'>
                <Box className='for_set_gri'>
                  <Box className='inout_prnt_bx'>
                    <span>From</span>
                    <input type='texte' placeholder='Block Leader' disabled />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>From Email</span>
                    <input type='texte' placeholder='invoice@blockleader.io' disabled />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>Invoice Date</span>
                    <input type='texte' placeholder='04.06.2023' disabled />
                  </Box>
                </Box>
                <Box className='for_set_gri megn_lf_20'>
                  <Box className='inout_prnt_bx'>
                    <span>To</span>
                    <input type='texte' placeholder='Tre Nelson' disabled />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>To Email</span>
                    <input type='texte' placeholder='trenelson@onxdc.io' disabled />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>Due Date</span>
                    <input type='texte' placeholder='06.06..2023' disabled />
                  </Box>
                </Box>
              </Box>
              <Text className='on_xdc_tabl'>onXDC</Text>
              <TableContainer>
                <Table variant="simple" className='client_report_tbl client_report_tbl_task_only invos_tbl_ab'>
                  <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2 w-80'>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new t_had'>
                      <Td className="width_onl_10">Task ID</Td>
                      <Td className="width_onl_10">Task</Td>
                      <Td className="width_onl_10">Role</Td>
                      <Td className='brd_non'>Time</Td>
                      <Td className='brd_non'>$ per/h</Td>
                      <Td className='brd_non'>$ Cost</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'>
                      <Td className="width_onl_10">#541857</Td>
                      <Td className="width_onl_10 for_max_wi"><Text>Task name will be here also its can be in 2 lines so its looks like that</Text></Td>
                      <Td className="width_onl_10 weight400">2D Motion Graphics</Td>
                      <Td className='brd_non'>00.35</Td>
                      <Td className='brd_non'>25.00</Td>
                      <Td className='brd_non'>25.00</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'>
                      <Td className="width_onl_10">#541857</Td>
                      <Td className="width_onl_10 for_max_wi"><Text>Task name will be here also its can be in 2 lines so its looks like that</Text></Td>
                      <Td className="width_onl_10 weight400">2D Motion Graphics</Td>
                      <Td className='brd_non'>00.35</Td>
                      <Td className='brd_non'>25.00</Td>
                      <Td className='brd_non'>25.00</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new t_foot'>
                      <Td className="width_onl_10"></Td>
                      <Td className="width_onl_10"></Td>
                      <Td className="width_onl_10"></Td>
                      <Td className='for_totl_color brd_non'>Total:</Td>
                      <Td className='for_grin_color brd_non'>148.36</Td>
                      <Td className='for_grin_color brd_non'>1650</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
              <Text className='on_xdc_tabl brd_non'>XRPL HQ</Text>
              <TableContainer>
                <Table variant="simple" className='client_report_tbl client_report_tbl_task_only invos_tbl_ab'>
                  <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2 w-80'>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new t_had'>
                      <Td className="width_onl_10">Task ID</Td>
                      <Td className="width_onl_10">Task</Td>
                      <Td className="width_onl_10">Role</Td>
                      <Td className='brd_non'>Time</Td>
                      <Td className='brd_non'>$ per/h</Td>
                      <Td className='brd_non'>$ Cost</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'>
                      <Td className="width_onl_10">#541857</Td>
                      <Td className="width_onl_10 for_max_wi"><Text>Task name will be here also its can be in 2 lines so its looks like that</Text></Td>
                      <Td className="width_onl_10 weight400">2D Motion Graphics</Td>
                      <Td className='brd_non'>00.35</Td>
                      <Td className='brd_non'>25.00</Td>
                      <Td className='brd_non'>25.00</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'>
                      <Td className="width_onl_10">#541857</Td>
                      <Td className="width_onl_10 for_max_wi"><Text>Task name will be here also its can be in 2 lines so its looks like that</Text></Td>
                      <Td className="width_onl_10 weight400">2D Motion Graphics</Td>
                      <Td className='brd_non'>00.35</Td>
                      <Td className='brd_non'>25.00</Td>
                      <Td className='brd_non'>25.00</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new t_foot'>
                      <Td className="width_onl_10"></Td>
                      <Td className="width_onl_10"></Td>
                      <Td className="width_onl_10"></Td>
                      <Td className='for_totl_color brd_non'>Total:</Td>
                      <Td className='for_grin_color brd_non'>148.36</Td>
                      <Td className='for_grin_color brd_non'>1650</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </ModalBody>
            <ModalFooter className="foter_this_modl invoice_foot">
              <Box className='rejac_cnc_btn_bx'>
                <Button colorScheme='blue' mr={3} className="back_btn" onClick={onInvoiceDetailClose}>
                  Cancel
                </Button>
                <Button colorScheme='blue' mr={3} onClick={RejectInvoOpen} className="back_btn rejact_pink_btn">
                  Reject
                </Button>
              </Box>

              <Button className="close_btn" onClick={openMetamaskModal}>Pay Now</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box> */}

      {/* Metamask Transfer
      Metamask Transfer
      Metamask Transfer */}
      <Modal isOpen={MetamaskisOpen} onClose={MetamaskClose} isCentered>
        <ModalOverlay />
        <ModalContent className="modal_report Metamask_pop_contin">
          {/* <ModalHeader className="moda_hed">
            <Heading as="h2" className='invos_head'>Invoice #548</Heading>
          </ModalHeader> */}
          {/* <ModalCloseButton /> */}
          <ModalBody className="modal_body Metamask_pop_body">
            <Box className='meta_mask_bo'>
              <Image src="/img/Metamask_ic.svg" alt="" />
              <Text>Metamask Transfer</Text>
            </Box>
            <Text className='merta_contin_p'>Please connect your MetaMask wallet to pay for this invoice.</Text>
          </ModalBody>
          <ModalFooter className="foter_this_modl metamask_foot">
            <Box className='rejac_cnc_btn_bx'>
              <Button colorScheme='blue' mr={3} onClick={MetamaskClose} className="back_btn">
                Cancel
              </Button>
            </Box>
            <Button className="close_btn" onClick={openPaymentConfirmModal}>Pay Now</Button>
          </ModalFooter>
          {/* PaymtConformisOpen */}
        </ModalContent>
      </Modal>

      {/* Payment Confirmation
      Payment Confirmation
      Payment Confirmation */}
      <Box className="modal_main">
        <Modal isOpen={PaymtConformisOpen} onClose={PaymtConformClose} isCentered="false">
          <ModalOverlay />
          <ModalContent className="modal_report Payment_Confirmationab Invoice_in_ab">
            <ModalHeader className="moda_hed">
              <Heading as="h2" className='invos_head'>Payment Confirmation</Heading>
              <Text>Please confirm your payment</Text>
            </ModalHeader>
            <ModalBody className="modal_body Payment_Confirmationab_body">
              <TableContainer>
                <Table variant="simple" className='client_report_tbl client_report_tbl_task_only invos_tbl_ab'>
                  <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2 w-80'>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new t_had'>
                      <Td className="width_onl_10">Inv.Number</Td>
                      <Td className="width_onl_10">Wallet</Td>
                      <Td className="width_onl_10">Token</Td>
                      <Td className='brd_non'>Chain</Td>
                      <Td className='brd_non'>Amount</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'>
                      <Td className="width_onl_10">#5465</Td>
                      <Td className="width_onl_10 for_max_wi">0x6456...5aw4
                        <Button className="bax_copy_btn_pernt">
                          {colorMode === "light" ? (
                            <Image src="/img/bxs_copy-alt.svg" alt="" />
                          ) : (
                            <Image src="/img/bxs_copy-alt_dark.svg" alt="" />
                          )}{" "}
                        </Button>
                      </Td>
                      <Td className="width_onl_10 weight400">USDC</Td>
                      <Td className='brd_non'>ERC20</Td>
                      <Td className='brd_non'>$ 680</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'>
                      <Td className="width_onl_10">#5465</Td>
                      <Td className="width_onl_10 for_max_wi">0x6456...5aw4
                        <Button className="bax_copy_btn_pernt">
                          {colorMode === "light" ? (
                            <Image src="/img/bxs_copy-alt.svg" alt="" />
                          ) : (
                            <Image src="/img/bxs_copy-alt_dark.svg" alt="" />
                          )}{" "}
                        </Button>
                      </Td>
                      <Td className="width_onl_10 weight400">USDC</Td>
                      <Td className='brd_non'>ERC20</Td>
                      <Td className='brd_non'>$ 680</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'>
                      <Td className="width_onl_10">#5465</Td>
                      <Td className="width_onl_10 for_max_wi">0x6456...5aw4
                        <Button className="bax_copy_btn_pernt">
                          {colorMode === "light" ? (
                            <Image src="/img/bxs_copy-alt.svg" alt="" />
                          ) : (
                            <Image src="/img/bxs_copy-alt_dark.svg" alt="" />
                          )}{" "}
                        </Button>
                      </Td>
                      <Td className="width_onl_10 weight400">USDC</Td>
                      <Td className='brd_non'>ERC20</Td>
                      <Td className='brd_non'>$ 680</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new t_foot'>
                      <Td className="width_onl_10"></Td>
                      <Td className="width_onl_10"></Td>
                      <Td className="width_onl_10"></Td>
                      <Td className='for_totl_color brd_non'>Total:</Td>
                      <Td className='for_grin_color brd_non'>$ 1650</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>

            </ModalBody>
            <ModalFooter className="foter_this_modl invoice_foot">
              <Box className='rejac_cnc_btn_bx'>
                <Button colorScheme='blue' mr={3} onClick={PaymtConformClose} className="back_btn">
                  Cancel
                </Button>
              </Box>
              <Button className="close_btn" onClick={paidInvoceOpen}>Confirm & Paid</Button>

            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>

      {/* Paid Invoice
      Paid Invoice
      Paid Invoice */}
      <Box className="modal_main">
        <Modal isOpen={paidInvoceisOpen} onClose={paidInvoceClose} isCentered={false}>
          <ModalOverlay />
          <ModalContent className="modal_report Invoice_in_ab">
            <ModalHeader className="moda_hed">
              <Heading as="h2" className='invos_head for_grin_color'>Paid Invoice #548</Heading>
            </ModalHeader>
            <ModalBody className="modal_body Invoice_in_ab_body">
              <Box className='input_min_bx_wi'>
                <Box className='for_set_gri'>
                  <Box className='inout_prnt_bx'>
                    <span>From</span>
                    <input type='texte' placeholder='Block Leader' disabled />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>From Email</span>
                    <input type='texte' placeholder='invoice@blockleader.io' disabled />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>Invoice Date</span>
                    <input type='texte' placeholder='04.06.2023' disabled />
                  </Box>
                </Box>
                <Box className='for_set_gri megn_lf_20'>
                  <Box className='inout_prnt_bx'>
                    <span>To</span>
                    <input type='texte' placeholder='Tre Nelson' disabled />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>To Email</span>
                    <input type='texte' placeholder='trenelson@onxdc.io' disabled />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>Due Date</span>
                    <input type='texte' placeholder='06.06..2023' disabled />
                  </Box>
                </Box>
              </Box>
              <Text className='on_xdc_tabl'>onXDC</Text>
              <TableContainer>
                <Table variant="simple" className='client_report_tbl client_report_tbl_task_only invos_tbl_ab'>
                  <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2 w-80'>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new t_had'>
                      <Td className="width_onl_10">Task ID</Td>
                      <Td className="width_onl_10">Task</Td>
                      <Td className="width_onl_10">Role</Td>
                      <Td className='brd_non'>Time</Td>
                      <Td className='brd_non'>$ per/h</Td>
                      <Td className='brd_non'>$ Cost</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'>
                      <Td className="width_onl_10">#541857</Td>
                      <Td className="width_onl_10 for_max_wi"><Text>Task name will be here also its can be in 2 lines so its looks like that</Text></Td>
                      <Td className="width_onl_10 weight400">2D Motion Graphics</Td>
                      <Td className='brd_non'>00.35</Td>
                      <Td className='brd_non'>25.00</Td>
                      <Td className='brd_non'>25.00</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'>
                      <Td className="width_onl_10">#541857</Td>
                      <Td className="width_onl_10 for_max_wi"><Text>Task name will be here also its can be in 2 lines so its looks like that</Text></Td>
                      <Td className="width_onl_10 weight400">2D Motion Graphics</Td>
                      <Td className='brd_non'>00.35</Td>
                      <Td className='brd_non'>25.00</Td>
                      <Td className='brd_non'>25.00</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new t_foot'>
                      <Td className="width_onl_10"></Td>
                      <Td className="width_onl_10"></Td>
                      <Td className="width_onl_10"></Td>
                      <Td className='for_totl_color brd_non'>Total:</Td>
                      <Td className='for_grin_color brd_non'>148.36</Td>
                      <Td className='for_grin_color brd_non'>1650</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>

            </ModalBody>
            <ModalFooter className="foter_this_modl invoice_foot paid_invo">
              <Box className='rejac_cnc_btn_bx'>
                <Button colorScheme='blue' mr={3} onClick={paidInvoceClose} className="back_btn">
                  Cancel
                </Button>
              </Box>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>

      {/* Reject Invoice
      Reject Invoice
      Reject Invoice */}
      <Modal isOpen={RejectInvoisOpen} onClose={RejectInvoClose} isCentered="true">
        <ModalOverlay />
        <ModalContent className="modal_report Metamask_pop_contin">
          <ModalBody className="modal_body Metamask_pop_body">
            <Box className='meta_mask_bo'>
              {/* <Image src="/img/Metamask_ic.svg" alt="" /> */}
              <Text className='color_red'>Reject Invoice</Text>
            </Box>
            <Text className='ple_provi_p'>Please Provide Details For Why This Invoice Is Rejected.</Text>
            <Box className='rejact_rdd_bx'>
              <Text className='merta_contin_p'>Hey I think here a some issue, I cant find task number #456748 I dont think this task may be in my invoice.</Text>
            </Box>
          </ModalBody>
          <ModalFooter className="foter_this_modl metamask_foot for_rejacr_invo">
            <Text>Are you sure you want to reject this invoice?</Text>
            <Box className='for_rejacr_invo_two_btn'>
              <Box className='rejac_cnc_btn_bx'>
                <Button colorScheme='blue' mr={3} onClick={RejectInvoTwoOpen} className="back_btn rejact_pink_btn">
                  Reject Invoice
                </Button>
              </Box>
              <Button className="close_btn" onClick={RejectInvoClose}>Cancel</Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Rejected Invoice
      Rejected Invoice
      Rejected Invoice */}
      <Box className="modal_main">
        <Modal isOpen={RejectInvoTwoisOpen} onClose={RejectInvoTwoClose} isCentered={false}>
          <ModalOverlay />
          <ModalContent className="modal_report Invoice_in_ab">
            <ModalHeader className="moda_hed">
              <Heading as="h2" className='invos_head color_red'>Rejected Invoice #548</Heading>
            </ModalHeader>
            <ModalBody className="modal_body Invoice_in_ab_body">
              <Box className='input_min_bx_wi'>
                <Box className='for_set_gri'>
                  <Box className='inout_prnt_bx'>
                    <span>From</span>
                    <input type='texte' placeholder='Block Leader' />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>From Email</span>
                    <input type='texte' placeholder='invoice@blockleader.io' />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>Invoice Date</span>
                    <input type='texte' placeholder='04.06.2023' />
                  </Box>
                </Box>
                <Box className='for_set_gri megn_lf_20'>
                  <Box className='inout_prnt_bx'>
                    <span>To</span>
                    <input type='texte' placeholder='Tre Nelson' />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>To Email</span>
                    <input type='texte' placeholder='trenelson@onxdc.io' />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>Due Date</span>
                    <input type='texte' placeholder='06.06..2023' />
                  </Box>
                </Box>
              </Box>
              <Box className='Reject_reason_bx'>
                <Text>Reject reason:</Text>
                <Textarea placeholder='Here is a sample placeholder' className='reject_text_era' />
              </Box>
              <Text className='on_xdc_tabl'>onXDC</Text>
              <TableContainer>
                <Table variant="simple" className='client_report_tbl client_report_tbl_task_only invos_tbl_ab'>
                  <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2 w-80'>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new t_had'>
                      <Td className="width_onl_10">Task ID</Td>
                      <Td className="width_onl_10">Task</Td>
                      <Td className="width_onl_10">Role</Td>
                      <Td className='brd_non'>Time</Td>
                      <Td className='brd_non'>$ per/h</Td>
                      <Td className='brd_non'>$ Cost</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'>
                      <Td className="width_onl_10">#541857</Td>
                      <Td className="width_onl_10 for_max_wi"><Text>Task name will be here also its can be in 2 lines so its looks like that</Text></Td>
                      <Td className="width_onl_10 weight400">2D Motion Graphics</Td>
                      <Td className='brd_non'>00.35</Td>
                      <Td className='brd_non'>25.00</Td>
                      <Td className='brd_non'>25.00</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'>
                      <Td className="width_onl_10">#541857</Td>
                      <Td className="width_onl_10 for_max_wi"><Text>Task name will be here also its can be in 2 lines so its looks like that</Text></Td>
                      <Td className="width_onl_10 weight400">2D Motion Graphics</Td>
                      <Td className='brd_non'>00.35</Td>
                      <Td className='brd_non'>25.00</Td>
                      <Td className='brd_non'>25.00</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new t_foot'>
                      <Td className="width_onl_10"></Td>
                      <Td className="width_onl_10"></Td>
                      <Td className="width_onl_10"></Td>
                      <Td className='for_totl_color brd_non'>Total:</Td>
                      <Td className='for_grin_color brd_non'>148.36</Td>
                      <Td className='for_grin_color brd_non'>1650</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
              <Text className='on_xdc_tabl brd_non'>XRPL HQ</Text>
              <TableContainer>
                <Table variant="simple" className='client_report_tbl client_report_tbl_task_only invos_tbl_ab'>
                  <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2 w-80'>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new t_had'>
                      <Td className="width_onl_10">Task ID</Td>
                      <Td className="width_onl_10">Task</Td>
                      <Td className="width_onl_10">Role</Td>
                      <Td className='brd_non'>Time</Td>
                      <Td className='brd_non'>$ per/h</Td>
                      <Td className='brd_non'>$ Cost</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'>
                      <Td className="width_onl_10">#541857</Td>
                      <Td className="width_onl_10 for_max_wi"><Text>Task name will be here also its can be in 2 lines so its looks like that</Text></Td>
                      <Td className="width_onl_10 weight400">2D Motion Graphics</Td>
                      <Td className='brd_non'>00.35</Td>
                      <Td className='brd_non'>25.00</Td>
                      <Td className='brd_non'>25.00</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'>
                      <Td className="width_onl_10">#541857</Td>
                      <Td className="width_onl_10 for_max_wi"><Text>Task name will be here also its can be in 2 lines so its looks like that</Text></Td>
                      <Td className="width_onl_10 weight400">2D Motion Graphics</Td>
                      <Td className='brd_non'>00.35</Td>
                      <Td className='brd_non'>25.00</Td>
                      <Td className='brd_non'>25.00</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new t_foot'>
                      <Td className="width_onl_10"></Td>
                      <Td className="width_onl_10"></Td>
                      <Td className="width_onl_10"></Td>
                      <Td className='for_totl_color brd_non'>Total:</Td>
                      <Td className='for_grin_color brd_non'>148.36</Td>
                      <Td className='for_grin_color brd_non'>1650</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>

            </ModalBody>
            <ModalFooter className="foter_this_modl invoice_foot RejectInvoTwo_foot">
              <Box className='rejac_cnc_btn_bx'>
                <Button colorScheme='blue' mr={3} onClick={RejectInvoTwoClose} className="back_btn">
                  Back
                </Button>
              </Box>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  )
}
