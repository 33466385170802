import React from "react";
import {
  Container,
  Box,
  SimpleGrid,
  GridItem,
  Heading,
  Image,
} from "@chakra-ui/react";
import { Element } from "react-scroll";

export default function OurWorks() {
  return (
    <>
      <Element name="OurBuilds">
        <Box className="our_wrk_main_bx">
          <Container>
            <Heading as="h1" className="def_h1">
              our <span> BUILDS</span>
            </Heading>
            <SimpleGrid columns={12} columnGap={4} rowGap={6}>
              <GridItem colSpan={[12, 6, 4, 3]}>
                <Box className="ourwrk_bxs">
                  <Box className="img_tp_bx">
                    <Image src="/img/on_xdc_img.png" />
                  </Box>
                  <Box className="grdnt_bttm_bx">
                    <a
                      href="https://www.onxdc.io"
                      target="_blank"
                      className="same_btn"
                    >
                      onXDC
                    </a>
                  </Box>
                </Box>
              </GridItem>
              <GridItem colSpan={[12, 6, 4, 3]}>
                <Box className="ourwrk_bxs">
                  <Box className="img_tp_bx">
                    <Image src="/img/dop_three_img.png" />
                  </Box>
                  <Box className="grdnt_bttm_bx">
                    <a
                      href="https://www.d0p3clubnft.com"
                      target="_blank"
                      className="same_btn"
                    >
                      D0P3 CLUB NFT
                    </a>
                  </Box>
                </Box>
              </GridItem>
              {/* <GridItem colSpan={[12, 6, 4, 3]}>
                <Box className="ourwrk_bxs">
                  <Box className="img_tp_bx">
                    <Image src="/img/lava_img.png" />
                  </Box>
                  <Box className="grdnt_bttm_bx">
                    <a
                      href="/"
                      target="_blank"
                      className="same_btn"
                    >
                      on play
                    </a>
                  </Box>
                </Box>
              </GridItem> */}
              <GridItem colSpan={[12, 6, 4, 3]}>
                <Box className="ourwrk_bxs">
                  <Box className="img_tp_bx">
                    <Image src="/img/sliceoftime_img.png" />
                  </Box>
                  <Box className="grdnt_bttm_bx">
                    <a
                      href="https://sliceoftime.netlify.app/"
                      target="_blank"
                      className="same_btn"
                    >
                      Slice of time
                    </a>
                  </Box>
                </Box>
              </GridItem>
              <GridItem colSpan={[12, 6, 4, 3]}>
                <Box className="ourwrk_bxs">
                  <Box className="img_tp_bx">
                    <Image src="/img/onavax_img.png" />
                  </Box>
                  <Box className="grdnt_bttm_bx">
                    <a
                      href="https://onavax-frontend.vercel.app/dashboard"
                      target="_blank"
                      className="same_btn"
                    >
                      onavax
                    </a>
                  </Box>
                </Box>
              </GridItem>
              <GridItem colSpan={[12, 6, 4, 3]}>
                <Box className="ourwrk_bxs">
                  <Box className="img_tp_bx">
                    <Image src="/img/swapsicle.png" />
                  </Box>
                  <Box className="grdnt_bttm_bx">
                    <a
                      href="https://www.swapsicle.io/"
                      target="_blank"
                      className="same_btn"
                    >
                      swapsicle
                    </a>
                  </Box>
                </Box>
              </GridItem>
              <GridItem colSpan={[12, 6, 4, 3]}>
                <Box className="ourwrk_bxs">
                  <Box className="img_tp_bx">
                    <Image src="/img/piprotocol_img.png" />
                  </Box>
                  <Box className="grdnt_bttm_bx">
                    <a
                      href="https://www.pi-protocol.io"
                      target="_blank"
                      className="same_btn"
                    >
                      pi-protocol
                    </a>
                  </Box>
                </Box>
              </GridItem>
              <GridItem colSpan={[12, 6, 4, 3]}>
                <Box className="ourwrk_bxs">
                  <Box className="img_tp_bx">
                    <Image src="/img/biggerminds_img.png" />
                  </Box>
                  <Box className="grdnt_bttm_bx">
                    <a
                      href="https://biggerminds-io-frontend.vercel.app"
                      target="_blank"
                      className="same_btn"
                    >
                      bigger minds
                    </a>
                  </Box>
                </Box>
              </GridItem>
              <GridItem colSpan={[12, 6, 4, 3]}>
                <Box className="ourwrk_bxs">
                  <Box className="img_tp_bx">
                    <Image src="/img/cat_img.png" />
                  </Box>
                  <Box className="grdnt_bttm_bx">
                    <a
                      href="https://www.aggregator.capital/"
                      target="_blank"
                      className="same_btn"
                    >
                      C.A.T
                    </a>
                  </Box>
                </Box>
              </GridItem>
              {/* <GridItem colSpan={[12, 6, 4, 3]}>
                <Box className="ourwrk_bxs">
                  <Box className="img_tp_bx">
                    <Image src="/img/cat_img.png" />
                  </Box>
                  <Box className="grdnt_bttm_bx">
                    <a
                      href="https://www.aggregator.capital/"
                      target="_blank"
                      className="same_btn"
                    >
                      C.A.T
                    </a>
                  </Box>
                </Box>
              </GridItem> */}
              {/* <GridItem colSpan={[12, 6, 4, 3]}>
                <Box className="ourwrk_bxs">
                  <Box className="img_tp_bx">
                    <Image src="/img/cultivator_img.png" />
                  </Box>
                  <Box className="grdnt_bttm_bx">
                    <a
                      href="https://thecultivator.finance/"
                      target="_blank"
                      className="same_btn"
                    >
                      cultivator
                    </a>
                  </Box>
                </Box>
              </GridItem> */}
              {/* <GridItem colSpan={[12, 6, 4, 3]}>
                <Box className="ourwrk_bxs">
                  <Box className="img_tp_bx">
                    <Image src="/img/ecc_img.png" />
                  </Box>
                  <Box className="grdnt_bttm_bx">
                    <a
                      href="https://www.ecc.capital/"
                      target="_blank"
                      className="same_btn"
                    >
                      ecc
                    </a>
                  </Box>
                </Box>
              </GridItem> */}
            </SimpleGrid>
            <Box className="show_all_btn">
              <a href="ourworkdetails">
                <span>Show all</span>
              </a>
            </Box>
          </Container>
        </Box>
      </Element>
    </>
  );
}
