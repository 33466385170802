import React from "react";
import { Container, Box, Heading, Text } from "@chakra-ui/react";

export default function DevTeam() {
  return (
    <>
      <Box className="dev_team_main">
        <Container>
          <Box className="innr_cntnt">
            <Text>
            Scams are a painful reality in blockchain. At Dev Legion, our commitment to your success goes beyond development. We ensure a smooth, secure, and transparent journey free from risks and worries. Trust isn't a luxury—it's a necessity.
            </Text>
            <Heading as="h6">
            How important is it to have a dev team you can trust? It’s everything. It’s non-negotiable. 
            </Heading>
            <Box className="trust_priceless_box">
              <Heading as="h3">
                RISK-FREE
                <span>JOURNEY</span>
              </Heading>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
