import React from "react";
import { Box, Heading, Text, Image } from "@chakra-ui/react";

export default function OurResults() {
  return (
    <>
      <Box className="our_result_main">
        {/* <Container> */}
        <Box className="our_rslt_innr">
          <Box className="our_rslt_bx">
            <Heading as="h1" className="def_h1">
              our<span> RESULTS</span>
            </Heading>
            <Image
              src="/img/our_team_top_dsgn.svg"
              className="ourteam_dsgn_img"
            />
          </Box>
          <Box className="our_rslt_txt_cntnt">
            <Text>
              Unleash the transformative power of blockchain with Dev Legion. Our bulletproof process, empowered by the cutting-edge AI, ensures precision results on time, within budget, and communicated with clarity.
              {/* <Box as="a" href="#">
                Dev Legion
              </Box>{" "} */}
            </Text>
            <Text className="margn_pernt">Your success is not just our goal—it's our mission.</Text>
          </Box>
          <Box className="are_lgndry_bx">
            <Image
              src="/img/are_legendary_img.svg"
              className="ourteam_dsgn_img"
            />
            <Heading as="h1" className="def_h1">
              ARE<span> Legendary</span>
            </Heading>
          </Box>
        </Box>
        {/* </Container> */}
      </Box>
    </>
  );
}
