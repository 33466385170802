import { InjectedConnector } from "@web3-react/injected-connector";


export const INVOICE_CLIENT_STATUS = {
  ALL: "All",
  REJECTED: "Rejected",
  DRAFT: "Draft",
  SUBMITTED: 'Submitted',
  IN_REVIEW: 'In Review',
  PAID: 'Paid'
}

export const INVOICE_ACTION = {
  DETAIL: "DETAIL",
  UPDATE: "UPDATE",
  EXPORT: "EXPORT",
  DOWNLOAD: "DOWNLOAD",
  SEND: "SEND"
}

export const TOKEN_ADDRESS = {
  USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
}

export const transferAddress = process.env.NEXT_PUBLIC_TRANSFER_ADDRESS || "0x4391F0b2a77b7753E208403ff808340a81c8a158";

export const usdc = process.env.NEXT_PUBLIC_USDC || "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"

export const usdcDecimal = process.env.NEXT_PUBLIC_USDC_DECIMAL || 6

export const CHAIN_ID = 108;

export const DefaultNetwork = Number(CHAIN_ID)

export const NetworkContextName = 'NETWORK'

export const networkInfo = {
  43113: {
    label: 'Avalanche Fuji Testnet',
    rpcUrl: 'https://api.avax-test.network/ext/bc/C/rpc',
    nativeCurrency: 'AVAX',
    explorer: 'https://cchain.explorer.avax-test.network',
  },
  43114: {
    label: 'Avalanche Network',
    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
    nativeCurrency: 'AVAX',
    explorer: 'https://snowtrace.io/',
  },
  108: {
    label: 'Voodoo',
    nativeCurrency: 'JULU',
    rpcUrl: 'https://chain.blockvoodoo.io',
  },
  1: {
    label: 'Ethereum Mainnet',
    nativeCurrency: 'ETH',
    rpcUrl: 'https://mainnet.infura.io/v3/',
  },
  80001: {
    label: 'Mumbai Polygon Testnet',
    nativeCurrency: 'MATIC',
    rpcUrl: 'https://matic-mumbai.chainstacklabs.com',
  }
}

export const injectedConnector = new InjectedConnector({
  supportedChainIds: [43113, 43114, 108, 1],
});