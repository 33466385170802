import React, { useState } from 'react'
import {
  Box,
  Button,
  Image,
  Heading,
  SimpleGrid,
  GridItem,
  Text,
  useColorMode,
  ModalBody,
  ModalFooter,
  useToast,
  useDisclosure,
  Modal,
  ModalContent
} from '@chakra-ui/react'
import { BASE_URL, CLICKUP_TICKET_STATUS } from '../../services/constant'
import { DeleteIcon } from '@chakra-ui/icons'
import axios from 'axios';
import httpStatus from "http-status";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../redux/Auth/action'

export default function ViewReport({ ticket, setIsView, tags, getDetail, handleBack }) {
  const { colorMode, toggleColorMode } = useColorMode()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [comment, setComment] = useState('')

  const enabled = ticket.status === CLICKUP_TICKET_STATUS.OPEN
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [isLoadingComment, setIsLoadingComment] = useState(false)
  const [isLoadingRemove, setIsLoadingRemove] = useState(false)
  const [isLoadingClose, setIsLoadingClose] = useState(false)

  const handleRemoveTicket = async () => {
    try {
      setIsLoadingRemove(true);
      const token = localStorage.getItem('bluClientToken')
      const resp = await axios({
        method: 'delete',
        url: `${BASE_URL}/api/ticket/${ticket?._id}`,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })

      if (resp.status === httpStatus.OK) {
        toast({
          title: `Success to remove ticket ${ticket.name}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        handleBack()
      } else {
        toast({
          title: `Failed to remove ticket. ${ticket.name}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    }
    catch (error) {
      console.error(error)
      if (error?.response?.status == httpStatus.UNAUTHORIZED) {
        dispatch(logoutUser()).then(() => {
          toast({
            title: 'You are not logged in.',
            description: "Please login first",
            status: 'warning',
            duration: 3000,
            isClosable: true,
          });
          navigate('/')
        })
      }
      else {
        let message = error?.response?.data?.message;
        if (!message) message = error.message
        toast({
          title: `Failed to remove ticket ${ticket.name}`,
          description: message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    }
    finally {
      setIsLoadingRemove(false);
    }
  }


  const handleCloseTicket = async () => {
    try {
      setIsLoadingClose(true);
      const token = localStorage.getItem('bluClientToken')
      const resp = await axios({
        method: 'put',
        url: `${BASE_URL}/api/ticket/close/${ticket?._id}`,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })

      if (resp.status === httpStatus.OK) {
        const json = resp.data;
        toast({
          title: `Success to close ticket ${ticket.name}`,
          description: json.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        handleBack()
      } else {
        toast({
          title: `Failed to close ticket. ${ticket.name}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (error) {
      console.error(error)
      if (error?.response?.status == httpStatus.UNAUTHORIZED) {
        dispatch(logoutUser()).then(() => {
          toast({
            title: 'You are not logged in.',
            description: "Please login first",
            status: 'warning',
            duration: 3000,
            isClosable: true,
          });
          navigate('/')
        })
      }
      else {
        let message = error?.response?.data?.message;
        if (!message) message = error.message
        toast({
          title: `Failed to close ticket ${ticket.name}`,
          description: message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    }
    finally {
      setIsLoadingClose(false);
    }
  }

  const handleCommentTicket = async () => {
    try {
      setIsLoadingComment(true)
      const token = localStorage.getItem('bluClientToken')
      const resp = await axios({
        method: 'post',
        url: `${BASE_URL}/api/ticket/comment/${ticket._id}`,
        headers: {
          Authorization: 'Bearer ' + token,
        },
        data: {
          comment_text: comment
        },
      })
      if (resp?.status === httpStatus.OK) {
        const json = resp.data;
        toast({
          title: json?.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      }
    }
    catch (error) {
      console.error(error)
      if (error.response.status == httpStatus.UNAUTHORIZED) {
        dispatch(logoutUser()).then(() => {
          toast({
            title: 'You are not logged in.',
            description: "Please login first",
            status: 'warning',
            duration: 3000,
            isClosable: true,
          });
          navigate('/')
        })
      } else {
        let message = error?.message
        if (error?.response?.data?.error) {
          message = error?.response?.data.error
        }
        toast({
          title: `Failed to update data`,
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    }
    finally {
      setComment('')
      getDetail();
      setIsLoadingComment(false);
      // setIsView(true);
      // setIsRefresh(true)
      // resetForm()
    }
  }

  return (
    <>
      <ModalBody className='modal_body'>
        <Box className='dtls_Dscrib_poblm_main dtls_Dscrib_poblm_main_v2a'>
          <Box className='tick_srol_bx'>
            <SimpleGrid columns={12} columnGap={0} rowGap={2}>
              <GridItem colSpan={[12, 12, 12, 5]}>
                <Box className='lft_part_grid_bx'>
                  <Box className='details_box'>
                    <Heading as='h4' className='details_text'>Details:</Heading>
                    <Box className='details_content'>
                      <Box className='details_innnr'>
                        <Heading as='h6'>Title</Heading>
                        <Heading as='h2'>{ticket?.name}</Heading>
                      </Box>
                      <Box className='details_innnr'>
                        <Heading as='h6'>Severity:</Heading>
                        <Heading as='h2'>{ticket?.severity?.name}</Heading>
                      </Box>
                      <Box className='details_innnr'>
                        <Heading as='h6'>Occurance:</Heading>
                        <Heading as='h2'>{ticket?.occurrence?.name}</Heading>
                      </Box>
                      <Box className='details_innnr'>
                        <Heading as='h6'>Category:</Heading>
                        <Heading as='h2'>{ticket?.category?.name}</Heading>
                      </Box>
                      <Box className='details_innnr'>
                        <Heading as='h6'>Project:</Heading>
                        <Heading as='h2'>{ticket?.id_project?.name}</Heading>
                      </Box>
                      <Box className='details_innnr'>
                        <Heading as='h6'>Client:</Heading>
                        <Heading as='h2'>{ticket?.id_client?.name}</Heading>
                      </Box>
                    </Box>
                  </Box>
                  <Box className='request_description_part'>
                    <Heading as='h4' className='details_text'>{tags ? 'REQUEST' : 'ISSUE'} Description</Heading>
                    <Box className='details_text_bx'>
                      <Text>{ticket?.description}</Text>
                    </Box>
                  </Box>
                  <Box className='files_part'>
                    <Heading as='h4' className='details_text'>Files</Heading>
                    <Box className="main_img_box">
                      <ul>
                        {ticket.files && ticket.files.length > 0
                          ? ticket.files.map((item, index) => {
                            return (
                              <li
                                key={`file-attached-${index}`}
                                className='main_img_box_innr'
                              >
                                <Box className='flex_btn_prnt'>
                                  {colorMode === 'light' ? (
                                    <Image
                                      src='/img/pdf_img.svg'
                                      alt=''
                                      className='pdf_img'
                                    />
                                  ) : (
                                    <Image
                                      src='/img/pdf_img_dark.svg'
                                      alt=''
                                      className='pdf_img'
                                    />
                                  )}

                                  {/* <Image src={`${item.thumbnail_medium}`}/> */}
                                  <Text><a href={`${item.url}`} target='_blank'>{item.title ? item.title : item.name}</a></Text>
                                  <Button>
                                    {colorMode === 'light' ? (
                                      <Image
                                        src='/img/delt_img.svg'
                                        alt=''
                                        className=''
                                      />
                                    ) : (
                                      <Image
                                        src='/img/delt_img_dark.svg'
                                        alt=''
                                        className=''
                                      />
                                    )}
                                  </Button>
                                </Box>
                              </li>
                            )
                          })
                          : null}
                      </ul>
                    </Box>
                  </Box>
                </Box>
              </GridItem>
              <GridItem colSpan={[12, 12, 12, 7]}>
                <Box className='right_part_box_grid'>
                  <Box className='discussion_border'>
                    <Heading as='h4' className='details_text'>Discussion</Heading>
                  </Box>
                </Box>
                <Box className='client_box_min'>
                  <Box className='client_box_mini'>
                    <Image src="/img/client_ic.png" alt='' />
                    <Text>Client</Text>
                  </Box>
                  <textarea id="w3review" name="w3review" rows="5" cols="50" className='textarea_prnt'
                    value={comment}
                    disabled={isLoadingComment}
                    onChange={e => {
                      setComment(e.target.value)
                    }}>

                  </textarea>
                  <Box className='two_btv_wer'>
                    {/* <Button className='add_files'>Add Files</Button> */}
                    <Button className='logout_bg022_grin' disabled={isLoadingComment || comment == ''} onClick={() => handleCommentTicket()}>
                      {isLoadingComment ? 'Sending...' : 'Send Message'}
                    </Button>
                  </Box>
                </Box>
                <Box className='tick_srol_bx_in_deve'>
                  {ticket?.comments?.map(e => {
                    return (<Box className='client_box_min'>
                      <Box className='client_box_mini_spbtn'>
                        <Box className='client_box_mini client_box_mini_v2'>
                          {
                            e.user_id ? (
                              <>
                                <Image src="/img/client_ic.png" alt='' />
                                <Text>{e.user_id?.firstname} {e.user_id?.lastname}</Text>
                              </>
                            ) : (
                              <>
                                <Image src="/img/client_ic_02.png" alt='' />
                                <Text>Admin</Text>
                              </>
                            )
                          }
                        </Box>
                        {/* <Heading as="h2">18 Nov 2022, 10.13 AM</Heading> */}
                        <Heading as="h2">{new Date(e.date).toUTCString()}</Heading>
                      </Box>
                      <Box className='two_text_p'>
                        <Text>
                          {e.comment_text}
                        </Text>
                        {/* <Text>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper bibendum lectus laoreet enim volutpat ullamcorper at imperdiet curabitur. Nibh eu dictumst at viverra facilisis. Interdum sit euismod nisi tellus sem lectus. Et ac dolor nulla volutpat sed euismod in ultricies. Sed tortor cras fermentum, velit, ipsum lacus, dictum facilisi. Auctor ultrices pellentesque lorem viverra ornare iaculis amet.</Text>
                          <Text className='two_text_p_two'>Et ac dolor nulla volutpat sed euismod in ultricies. Sed tortor cras fermentum, velit, ipsum lacus, dictum facilisi. Auctor ultrices pellentesque lorem viverra </Text> */}
                      </Box>
                      <Box className='two_btv_wer two_btv_wer_v2'>
                        <Box className="main_img_box">
                          {/* <ul>
                              <li className="main_img_box_innr">
                                <Box className="flex_btn_prnt">
                                  {colorMode === "light" ? (
                                    <Image src="/img/pdf_img.svg" alt="" className="pdf_img" />
                                  ) : (
                                    <Image src="/img/pdf_img_dark.svg" alt="" className="pdf_img" />
                                  )}
                                  <Text>Filename.jpg</Text>
                                  <Button>
                                    {colorMode === "light" ? (
                                      <Image src="/img/delt_img.svg" alt="" className="" />
                                    ) : (
                                      <Image src="/img/delt_img_dark.svg" alt="" className="" />
                                    )}
                                  </Button>
                                </Box>
                              </li>
                              <li className="main_img_box_innr">
                                <Box className="flex_btn_prnt">
                                  {colorMode === "light" ? (
                                    <Image src="/img/pdf_img.svg" alt="" className="pdf_img" />
                                  ) : (
                                    <Image src="/img/pdf_img_dark.svg" alt="" className="pdf_img" />
                                  )}
                                  <Text>Filename.jpg</Text>
                                  <Button>
                                    {colorMode === "light" ? (
                                      <Image src="/img/delt_img.svg" alt="" className="" />
                                    ) : (
                                      <Image src="/img/delt_img_dark.svg" alt="" className="" />
                                    )}
                                  </Button>
                                </Box>
                              </li>
                            </ul> */}
                        </Box>
                      </Box>
                    </Box>)
                  })}
                </Box>
              </GridItem>
            </SimpleGrid>
          </Box>
        </Box>
        {/* <ViewReportSolution ticket={ticket} /> */}
      </ModalBody>

      {ticket?.status != 'CLOSED' ?
        <ModalFooter className='foter_this_modl foter_this_modl_ab'>
          <Box className='sp_btnv'>
            <Button
              className='remove_ticket_btn'
              onClick={onOpen}
            >
              <DeleteIcon />{isLoadingRemove ? 'Removing...' : 'Remove Ticket'}
            </Button>
            <Box className='fourer_two_btn'>
              <Button
                variant='ghost'
                className='close_btn'
                disabled={!enabled}
                onClick={(e) => {
                  enabled && setIsView(false)
                }}
              >
                Edit Ticket
              </Button>
              <Button
                variant='ghost'
                className='close_btn'
                onClick={handleCloseTicket}
                disabled={isLoadingClose}
              >
                {isLoadingClose ? 'Closing...' : 'Close Ticket'}
              </Button>
            </Box>
          </Box>

        </ModalFooter>
        : <></>}
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          {/* <ModalOverlay /> */}
          <ModalContent className="RemoveTicketModal">
            <ModalBody>
              <Box className='pop_min_box'>
                {colorMode === 'light' ? (
                  <Image
                    src='/img/pop_img_light.png'
                    alt=''
                    className='pop_img_light'
                  />
                ) : (
                  <Image
                    src='/img/pop_img_dark.png'
                    alt=''
                    className=''
                  />
                )}

                <Box className='pop_dat_box'>
                  <Heading as="h3" className='remov_h'>remove confirmation</Heading>
                  <Text className='remov_p'>Are you sure you want to delete this Ticket {ticket?.ticket_no}</Text>
                  <Box className='pop_to_btn'>
                    <Button onClick={onClose} className="def_cancel_ab">Cancel</Button>  
                    <Button className="def_remove_ab"
                      onClick={handleRemoveTicket}
                      disabled={isLoadingRemove}>Remove</Button>
                  </Box>
                </Box>
              </Box>              
              
            </ModalBody>
            
          </ModalContent>
        </Modal>
    </>
  )
}