import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Image,
  useColorMode,
  Heading,
  SimpleGrid,
  GridItem,
  Text,
  Textarea,
  useToast,
  Switch
} from '@chakra-ui/react';
import { BASE_URL } from '../../services/constant';
import axios from 'axios';
import httpStatus from "http-status";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Select, { components } from "react-select";
import {
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";

export const Control = (props) => {
  return (
    <>
      <Label isFloating={props.isFocused || props.hasValue}>{props?.selectProps?.data}</Label>
      <components.Control {...props} />
    </>
  );
};

const Label = styled.label`
  left: 10px;
  pointer-events: none;
  position: absolute;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  z-index: 1;

  top: ${(props) => (props.isFloating ? `5px` : `35%`)};
  font-size: ${(props) => (props.isFloating ? `0.5rem` : `1rem`)};
`;

export default function CreateReport({ setIsRefresh }) {
  const { colorMode, toggleColorMode } = useColorMode()
  const [resize, setResize] = React.useState('horizontal')
  const [files, setFiles] = useState([])
  const [optionSeverity, setOptionSeverity] = useState([])
  const [optionOccurrence, setOptionOccurrence] = useState([])
  const [optionCategory, setOptionCategory] = useState([])
  const [optionClient, setOptionClient] = useState([])
  const [optionProject, setOptionProject] = useState([])
  const [form, setForm] = useState([
    {
      name: '',
      tags: false,
      description: '',
      severity: null,
      occurrence: null,
      category: null,
      client: null,
      project: null,
      files: {},
    },
  ])
  const [isButtonDisable, setIsButtonDisable] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [attachment, setAttachment] = useState(null)
  const [attachmentFiles, setAttachmentFiles] = useState([])
  const navigate = useNavigate()
  const toast = useToast()

  const resetForm = () => {
    setForm([
      {
        name: '',
        tags: false,
        description: '',
        severity: '',
        occurrence: '',
        category: '',
        client: '',
        project: '',
        files: {},
      },
    ])
  }
  const getFormData = async () => {
    try {
      const token = localStorage.getItem('bluClientToken')
      const resp = await axios({
        method: 'post',
        url: `${BASE_URL}/api/ticket/form`,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })

      if (resp.status === httpStatus.OK) {
        const json = resp.data
        for (const item of json) {
          let options = []
          for (const option of item.options) {
            options.push({
              value: option.id ? option.id : option._id,
              label: option.name,
            })
          }

          let data;
          switch (item.name) {
            case 'Severity':
              setOptionSeverity(options)
              break
            case 'Occurrence':
              setOptionOccurrence(options)
              break
            case 'Category':
              setOptionCategory(options)
              break
            case 'Client':
              setOptionClient(options)
              if (options.length > 0) {
                data = [...form]
                for (let i = 0; i < data.length; i++) {
                  let item = data[i];
                  item.client = options[0];
                  data[i] = item;
                }
                setForm(data)
                if (options[0]?.value) {
                  getProjectsByClient(options[0].value)
                }
              }
              break
            case 'Project':
              setOptionProject(options)
              if (options.length > 0) {
                data = [...form]
                for (let i = 0; i < data.length; i++) {
                  let item = data[i];
                  item.project = options[0];
                  data[i] = item;
                }
                setForm(data)
              }
              break
          }
        }
      }
    } catch (error) {
      console.error(error)
      if (error.response.status == httpStatus.UNAUTHORIZED) {
        toast({
          title: 'You are not logged in.',
          description: 'Please login first',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
        navigate('/')
      } else {
        let message = error.message
        if (error?.response?.data?.length > 0) {
          message = error?.response?.data[0]
        }
        toast({
          title: `Failed to load data`,
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    }
  }

  const getProjectsByClient = async (clientId) => {
    if (!clientId) {
      console.error("Please select client");
      return;
    }
    try {
      const token = localStorage.getItem('bluClientToken')
      const resp = await axios({
        method: 'get',
        url: `${BASE_URL}/api/project/client/${clientId}`,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })

      if (resp.status === httpStatus.OK) {
        const json = resp.data

        let options = []
        for (const option of json?.result?.result) {
          options.push({
            value: option?._id ? option?._id : option._id,
            label: option?.name,
          })
        }
        setOptionProject(options)
        if (options.length > 0) {
          let data = [...form]
          for (let i = 0; i < data.length; i++) {
            let item = data[i];
            item.project = options[0];
            data[i] = item;
          }
          setForm(data)
        }
      }
    } catch (error) {
      console.error(error)
      if (error.response.status == httpStatus.UNAUTHORIZED) {
        toast({
          title: 'You are not logged in.',
          description: 'Please login first',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
        navigate('/')
      } else {
        let message = error.message
        if (error?.response?.data?.length > 0) {
          message = error?.response?.data[0]
        }
        toast({
          title: `Failed to load data`,
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    }
  }

  const addData = () => {
    let f = [
      ...form,
      {
        name: '',
        tags: false,
        description: '',
        severity: undefined,
        occurrence: undefined,
        category: undefined,
        client: undefined,
        project: undefined,
        files: undefined,
      },
    ]
    setForm(f)
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const token = localStorage.getItem('bluClientToken')
      const resp = await axios({
        method: 'post',
        url: `${BASE_URL}/api/ticket`,
        headers: {
          Authorization: 'Bearer ' + token,
          // 'Content-Type': 'multipart/form-data',
        },
        data: form,
      })

      if (resp?.status === httpStatus.OK) {
        const json = resp.data
        // console.log('UPLOAD TICKET ATTACHMENT : ', json)
        if (attachmentFiles.length > 0) {
          try {
            const res = await axios.post(
              `${BASE_URL}/api/ticket/upload/${json[0].taskId}`,
              attachment,
              {
                headers: {
                  Authorization: 'Bearer ' + token,
                },
              },
            )

            // console.log(res.data)
          } catch (error) {
            console.error(error)
          }
        }
        for (const item of json) {
          toast({
            title: item?.message,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        }
        handleRemoveAll()
      }
    } catch (error) {
      console.error(error)
      if (error.response.status == httpStatus.UNAUTHORIZED) {
        toast({
          title: 'You are not logged in.',
          description: 'Please login first',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
        navigate('/')
      } else {
        let message = error?.message
        if (error?.response?.data?.error) {
          message = error?.response?.data.error
        }
        toast({
          title: `Failed to load data`,
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    } finally {
      setIsRefresh(true)
      setIsLoading(false)
      // resetForm()
    }
  }

  const handleRemove = (index) => {
    let f = [...form]
    setForm(f.filter((value, i) => i != index))
  }

  const handleRemoveAll = () => {
    let f = {
      name: '',
      description: '',
      severity: undefined,
      occurrence: undefined,
      category: undefined,
      client: undefined,
      project: undefined,
      files: undefined,
      tags: false
    }
    setAttachment([])
    setAttachmentFiles([])
    setIsButtonDisable(false)

    if (optionClient.length == 1) {
      f.client = optionClient[0];
    }
    if (optionProject.length == 1) {
      f.project = optionProject[0];
    }

    setForm([f])
  }

  useEffect(() => {
    let disable = false
    for (const item of form) {
      if (item.name === '') {
        disable = true
        break
      }
      if (item.description === '') {
        disable = true
        break
      }
      if (item.severity === undefined) {
        disable = true
        break
      }
      if (item.occurrence === undefined) {
        disable = true
        break
      }
      if (item.category === undefined) {
        disable = true
        break
      }
      if (item.project === undefined) {
        disable = true
        break
      }
      if (item.client === undefined) {
        disable = true
        break
      }
    }
    // console.log(form)
    setIsButtonDisable(disable)
  }, [form])

  useEffect(() => {
    getFormData()
    if (form.length === 0) {
      addData()
    }
  }, [])

  function uploadSingleFile(e, index) {
    let formData = new FormData()
    let files = []
    if (attachmentFiles.length > 0) files = attachmentFiles
    if (attachment) formData = attachment
    let data = [...form]
    // let tempFiles = [...data[index].files];
    for (const file of e.target.files) {
      formData.append('attachment', file)
      files.push(file)
      // console.log("test-file", file.name, file);
    }
    // console.log(e.target.files[0])
    // formData.append('attachment', e.target.files[0])
    // formData.forEach((file) => console.log(file))
    console.log(files)

    setAttachment(formData)
    setAttachmentFiles(files)
    data[index].files = formData
    setForm(data)

    // console.log(e.target.files[0].name);
    // let ImagesArray = Object.entries(e.target.files).map((e) => {
    //   URL.createObjectURL(e[1]);
    //   console.log("test-uploadSingleFile-name", e[1].name);
    //   setFilename(e[1].name);
    // });

    // console.log("test-uploadSingleFile-array", ImagesArray);
    // setFile([...file, ...ImagesArray]);
    // console.log('test-all file', file);
  }

  function upload(e) {
    e.preventDefault()
    // console.log(files)
  }

  function deleteFile(index) {
    const formData = new FormData()
    const files = []
    for (let i = 0; i < attachmentFiles.length; i += 1) {
      if (i !== index) {
        formData.append('attachment', attachmentFiles[i])
        files.push(attachmentFiles[i])
      }
    }

    console.log([...formData])

    setAttachment(formData)
    setAttachmentFiles(files)
  }

  return (
    <>
      <Box className='reeport_prnt_min'>
        {form.map((item, index) => {
          return (
            <Box className='reaport_trik_inrr_bx' key={index}>
              <Box className='modal_footer report_ticket_hed'>
                {/* <Heading as='h2'>Ticket #{(index + 1)}</Heading> */}
                <Box className='essue_rqst_swtch_box'>
                  <Heading as='h2'>
                    {!item.tags ? (
                      <Image src='/img/exclaim_ic.svg' alt='' />
                    ) : (
                      <Image src='/img/exclaimoff_ic.svg' alt='' />
                    )}
                    ISSUE
                  </Heading>
                  <Switch
                    className='isue_rqst_switch'
                    value={item.tags}
                    onChange={(e) => {
                      let data = [...form]
                      data[index].tags = e.target.checked
                      setForm(data)
                    }}
                  />
                  <Heading as='h2'>
                    {item.tags ? (
                      <Image src='/img/tracedon_ic.svg' alt='' />
                    ) : (
                      <Image src='/img/traced_ic.svg' alt='' />
                    )}
                    REQUEST
                  </Heading>
                </Box>
                {index == 0 ? (
                  <Button
                    className='fill_btn'
                    disabled={isButtonDisable || isLoading}
                    onClick={() => handleSubmit()}
                  >
                    {isLoading ? 'Creating' : 'Fill to create'}
                  </Button>
                ) : (
                  <Button
                    className='reprt_cls_btn'
                    onClick={() => handleRemove(index)}
                  >
                    Close <Image src='/img/close_ic.svg' alt='' />
                  </Button>
                )}
              </Box>
              <Box className='form_cntrl_box form_cntrl_boxv2_ab'>
                <SimpleGrid columns={12} columnGap={5} rowGap={0}>
                  <GridItem colSpan={[12, 12, 6, 6]}>
                    <Text className='description_text_p'>
                      Data <span>All Fields Required</span>
                    </Text>
                    <Box className='inpt_prnt_box'>
                      <FormControl variant='floating' id='first-name'>
                        <Input
                          type='text'
                          placeholder=' '
                          value={item.name}
                          onChange={(e) => {
                            let data = [...form];
                            data[index].name = e.target.value;
                            setForm(data);
                          }}
                        />
                        <FormLabel>Title</FormLabel>
                      </FormControl>
                    </Box>
                    <Box className='inpt_prnt_slct_nl'>
                      <Select
                        data="Severity"
                        placeholder=''
                        components={{ Control }}
                        // menuIsOpen={true}
                        options={optionSeverity}
                        value={optionSeverity.filter(function (option) {
                          return option.value === item.severity?.value;
                        })}
                        classNamePrefix="df_select"
                        onChange={(e) => {
                          let data = [...form]
                          data[index].severity = e;
                          setForm(data)
                        }}
                      />
                    </Box>
                    <Box className='inpt_prnt_slct_nl'>
                      <Select
                        data="Occurrence"
                        placeholder=''
                        components={{ Control }}
                        classNamePrefix="df_select"
                        options={[...form][index].tags ? optionOccurrence.slice(-3) : optionOccurrence.slice(0, 5)}
                        value={optionOccurrence.filter(function (option) {
                          return option.value === item.occurrence?.value;
                        })}
                        onChange={(e) => {
                          let data = [...form]
                          data[index].occurrence = e;
                          setForm(data)
                        }}
                      >
                        {optionOccurrence.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Select>
                    </Box>
                    <Box className='inpt_prnt_slct_nl'>
                      <Select
                        data="Category"
                        className='catgry_slect'
                        placeholder=''
                        components={{ Control }}
                        classNamePrefix="df_select"
                        options={[...form][index].tags ? optionCategory.slice(0, 6) : optionCategory.slice(-5)}
                        value={optionCategory.filter(function (option) {
                          return option.value === item.category?.value;
                        })}
                        onChange={(e) => {
                          let data = [...form]
                          data[index].category = e;
                          setForm(data)
                        }}
                      >
                        {optionCategory.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Select>
                    </Box>
                    <Box className='inpt_prnt_slct_nl'>
                      <Select
                        data="Client"
                        placeholder=''
                        components={{ Control }}
                        classNamePrefix="df_select"
                        options={optionClient}
                        value={optionClient.filter(function (option) {
                          return option.value === item.client?.value;
                        })}
                        onChange={(e) => {
                          let data = [...form]
                          data[index].client = e;
                          setForm(data)
                          console.log("client", e)
                          getProjectsByClient(e?.value);
                        }}
                      >
                        {optionClient.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Select>
                    </Box>
                    <Box className='inpt_prnt_slct_nl'>
                      <Select
                        data="Project"
                        placeholder=''
                        components={{ Control }}
                        classNamePrefix="df_select"
                        options={optionProject}
                        value={optionProject.filter(function (option) {
                          return option.value === item.project?.value;
                        })}
                        onChange={(e) => {
                          let data = [...form]
                          data[index].project = e;
                          setForm(data)
                        }}
                      >
                        {optionProject.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Select>
                    </Box>
                  </GridItem>
                  <GridItem
                    colSpan={[12, 12, 6, 6]}
                    className='mt_bx_ripon_siv'
                  >
                    <Text className='description_text_p'>
                      {item.tags ? "Request" : "Issue"} Description
                    </Text>
                    <Box className='text_ariya_box'>
                      <Textarea
                        placeholder='Describe the issue in detail...'
                        size='sm'
                        resize={resize}
                        value={item.description}
                        onChange={(e) => {
                          let data = [...form]
                          data[index].description = e.target.value
                          setForm(data)
                        }}
                      />
                    </Box>
                  </GridItem>
                </SimpleGrid>
                <Box className='Add_files_prnt'>
                  <div className='form-group preview'>
                    {attachmentFiles &&
                      attachmentFiles.map((value, indexFile) => {
                        return (
                          <div key={indexFile}>
                            {colorMode === 'light' ? (
                              <img src='/img/file_icon.svg' alt='' />
                            ) : (
                              <img src='/img/file_icon_dark.svg' alt='' />
                            )}
                            <p>{value?.name}</p>
                            <button
                              type='button'
                              onClick={() => deleteFile(indexFile)}
                            >
                              {colorMode === 'light' ? (
                                <Image src='/img/delete_icon.svg' alt='' />
                              ) : (
                                <Image src='/img/delete_icon_dark.svg' alt='' />
                              )}
                            </button>
                          </div>
                        )
                      })}
                  </div>
                  <Box className='Add_files_bx'>
                    <form>
                      <div className='form-group'>
                        <Heading as='h4'>
                          + Add files
                          <input
                            type='file'
                            name='attachment'
                            disabled={files.length === 5}
                            className='form-control'
                            onChange={(e) => uploadSingleFile(e, index)}
                            multiple
                          />
                        </Heading>
                      </div>
                      {/* <button
                    type='button'
                    className='btn btn-primary btn-block'
                    onClick={upload}
                  >
                    Upload
                  </button> */}
                    </form>

                    <Text>You can add .JPG, .PNG, .PDF type of files</Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        })}
        {/* <Button className='more_ticket_def' onClick={addData}>
          <span>+ Add one more ticket</span>
        </Button> */}
      </Box>
    </>
  )
}
