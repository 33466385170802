import React, { useEffect, useState } from 'react'
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Image,
  Button,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  CircularProgress,
} from '@chakra-ui/react'
import { FiMenu } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { logoutUser } from '../../redux/Auth/action'

const LayoutTwo = (props) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    document.body.classList.add('dashboard_pages')
    return () => {
      document.body.classList.remove('dashboard_pages')
    }
  }, [])
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    const token = localStorage.getItem('bluClientToken')
    const user = localStorage.getItem('bluClientUser')
    if (token) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
      navigate('/')
    }
    if (user) {
      const userData = JSON.parse(user)
      if (userData.type === 'admin') {
        setIsAdmin(true)
      } else {
        setIsAdmin(false)
      }
    }
  }, [])

  return isAuthenticated ? (
    <>
      <Box minH='100vh' className='sidbar_main'>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: 'none', lg: 'block' }}
          className='sidebar_inn'
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement='left'
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size='full'
        >
          <DrawerContent className='mdl_cntnt_cntnt_prnt'>
            <SidebarContent onClose={onClose} className='mdl_cntnt_cntnt' />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav display={{ base: 'flex', lg: 'none' }} onOpen={onOpen} />
        <Box ml={{ base: 0, lg: 60 }} className='bodymain'>
          {props.children}
        </Box>
      </Box>
      {colorMode === 'light' ? (
        <Image
          src='/img/user_list_btm_sdw_circle_img_light.png'
          alt=''
          className='user_list_btm_sdw_circle_img'
        />
      ) : (
        <Image
          src='/img/user_list_btm_sdw_circle_img.png'
          alt=''
          className='user_list_btm_sdw_circle_img'
        />
      )}
      {colorMode === 'light' ? (
        <Image src='/img/page_logo.png' alt='' className='pagelogo' />
      ) : (
        <Image src='/img/page_logo_dark.png' alt='' className='pagelogo' />
      )}
    </>
  ) : null
}

const SidebarContent = ({ onClose, ...rest }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const { pathname } = location
  const splitLocation = pathname.split('/')

  const handleLogout = () => {
    setIsLoading(true)
    dispatch(logoutUser()).then(() => {
      setIsLoading(false)
      onDrawerClose()
      navigate('/')
    })
  }

  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    const user = localStorage.getItem('bluClientUser')
    if (user) {
      const userData = JSON.parse(user)
      if (userData.type === 'admin') {
        setIsAdmin(true)
      } else {
        setIsAdmin(false)
      }
    }
  }, [])

  return (
    <>
      <Box
        bg={useColorModeValue('white', 'gray.900')}
        borderRight='1px'
        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
        w={{ base: 'full', lg: 60 }}
        pos='fixed'
        h='full'
        {...rest}
      >
        {colorMode === 'light' ? (
          <Image
            src='/img/sidebar_border_ic.png'
            alt=''
            className='sidebar_border_ic'
          />
        ) : (
          <>
            <Image
              src='/img/sidebar_border_ic_dark.png'
              alt=''
              className='sidebar_border_ic'
              display={{ base: 'none', lg: 'block' }}
            />
            <Image
              src='/img/sidebar_border_ic_dark_mbl.png'
              alt=''
              className='sidebar_border_ic'
              display={{ base: 'block', lg: 'none' }}
            />
          </>
        )}

        <Flex
          alignItems='center'
          justifyContent='space-between'
          className='sidebr_inncnnt'
        >
          <NavLink to='/' className='sidebar_logo_link'>
            {colorMode === 'light' ? (
              <Image
                src='/img/logo_light.svg'
                alt=''
                className='sidebar_logo'
              />
            ) : (
              <Image src='/img/logo_dark.svg' alt='' className='sidebar_logo' />
            )}
          </NavLink>
          <CloseButton
            display={{ base: 'flex', lg: 'none' }}
            onClick={onClose}
            className='sidmenu_close'
          />
        </Flex>
        <Box className='sidebarlink_prnt'>
          <NavLink
            to='/dashboard'
            className={splitLocation[1] === 'dashboard' ? 'active' : ''}
          >
            <span>
              {colorMode === 'light' ? (
                <>
                  <Image
                    src='/img/dashboard_ic.svg'
                    alt=''
                    className='user_list_ic user_list_ic_non_act'
                  />
                  <Image
                    src='/img/dashboard_ic_actv.svg'
                    alt=''
                    className='user_list_ic user_list_ic_actv'
                  />
                </>
              ) : (
                <>
                  <Image
                    src='/img/dashboard_ic_dark.svg'
                    alt=''
                    className='user_list_ic user_list_ic_non_act'
                  />
                  <Image
                    src='/img/dashboard_ic_dark_actv.svg'
                    alt=''
                    className='user_list_ic user_list_ic_actv'
                  />
                </>
              )}
            </span>{' '}
            Dashboard
          </NavLink>
          <NavLink
            to='/tickets'
            className={splitLocation[1] === 'tickets' ? 'active' : ''}
          >
            <span>
              {colorMode === 'light' ? (
                <>
                  <Image
                    src='/img/tickets_ic.svg'
                    alt=''
                    className='user_list_ic user_list_ic_non_act'
                  />
                  <Image
                    src='/img/tickets_ic_actv.svg'
                    alt=''
                    className='user_list_ic user_list_ic_actv'
                  />
                </>
              ) : (
                <>
                  <Image
                    src='/img/tickets_ic_dark.svg'
                    alt=''
                    className='user_list_ic user_list_ic_non_act'
                  />
                  <Image
                    src='/img/tickets_ic_dark_actv.svg'
                    alt=''
                    className='user_list_ic user_list_ic_actv'
                  />
                </>
              )}
            </span>{' '}
            Tickets
          </NavLink>

          <NavLink
            to='/invoices'
            className={splitLocation[1] === 'invoices' ? 'active' : ''}
          >
            <span>
              {colorMode === 'light' ? (
                <>
                  <Image
                    src='/img/invoices_ic.svg'
                    alt=''
                    className='user_list_ic user_list_ic_non_act'
                  />
                  <Image
                    src='/img/invoices_icactv.svg'
                    alt=''
                    className='user_list_ic user_list_ic_actv'
                  />
                </>
              ) : (
                <>
                  <Image
                    src='/img/invoices_ic_dark.svg'
                    alt=''
                    className='user_list_ic user_list_ic_non_act'
                  />
                  <Image
                    src='/img/invoices_ic_dark_actv.svg'
                    alt=''
                    className='user_list_ic user_list_ic_actv'
                  />
                </>
              )}
            </span>{' '}
            invoices
          </NavLink>
          
          {isAdmin ? (
            <NavLink
              to='/userlist'
              className={splitLocation[1] === 'userlist' ? 'active' : ''}
            >
              <span>
                {colorMode === 'light' ? (
                  <>
                    <Image
                      src='/img/user_list_ic.svg'
                      alt=''
                      className='user_list_ic user_list_ic_non_act'
                    />
                    <Image
                      src='/img/user_list_ic_actv.svg'
                      alt=''
                      className='user_list_ic user_list_ic_actv'
                    />
                  </>
                ) : (
                  <>
                    <Image
                      src='/img/user_list_ic_dark.svg'
                      alt=''
                      className='user_list_ic user_list_ic_non_act'
                    />
                    <Image
                      src='/img/user_list_ic_dark_actv.svg'
                      alt=''
                      className='user_list_ic user_list_ic_actv'
                    />
                  </>
                )}
              </span>{' '}
              User list
            </NavLink>
          ) : null}
           {/* {isAdmin ? (
          ) : null} */}
            <NavLink
              to='/client-report'
              className={splitLocation[1] === 'client-report' || splitLocation[1] === 'clientreportdetail' ? 'active' : ''}
            >
              <span>
                {colorMode === 'light' ? (
                  <>
                    <Image
                      src='/img/client_rpt_list_ic.svg'
                      alt=''
                      className='user_list_ic user_list_ic_non_act'
                    />
                    <Image
                      src='/img/client_rpt_list_ic_actv.svg'
                      alt=''
                      className='user_list_ic user_list_ic_actv'
                    />
                  </>
                ) : (
                  <>
                    <Image
                      src='/img/user_list_ic_dark.svg'
                      alt=''
                      className='user_list_ic user_list_ic_non_act'
                    />
                    <Image
                      src='/img/user_list_ic_dark_actv.svg'
                      alt=''
                      className='user_list_ic user_list_ic_actv'
                    />
                  </>
                )}
              </span>{' '}
              client Report
            </NavLink>
          {/* <NavLink to="/" className={splitLocation[1] === "project_list" ? "active" : ""}>
          <span>
            {colorMode === "light" ? (
              <>
              <Image
                src="/img/prjct_list_ic.svg"
                alt=""
                className="user_list_ic user_list_ic_non_act"
              />
              <Image
                src="/img/prjct_list_ic_actv.svg"
                alt=""
                className="user_list_ic user_list_ic_actv"
              />
              </>
            ) : (
              <>
              <Image
                src="/img/prjct_list_ic_dark.svg"
                alt=""
                className="user_list_ic user_list_ic_non_act"
              />
              <Image
                src="/img/prjct_list_ic_dark_actv.svg"
                alt=""
                className="user_list_ic user_list_ic_actv"
              />
              </>
            )}
          </span>{" "}
          Project list
        </NavLink> */}
          <NavLink
            to='/account'
            className={splitLocation[1] === 'account' ? 'active' : ''}
          >
            <span>
              {colorMode === 'light' ? (
                <>
                  <Image
                    src='/img/account_ic.svg'
                    alt=''
                    className='user_list_ic user_list_ic_non_act'
                  />
                  <Image
                    src='/img/account_ic_actv.svg'
                    alt=''
                    className='user_list_ic user_list_ic_actv'
                  />
                </>
              ) : (
                <>
                  <Image
                    src='/img/account_ic_dark.svg'
                    alt=''
                    className='user_list_ic user_list_ic_non_act'
                  />
                  <Image
                    src='/img/account_ic_dark_actv.svg'
                    alt=''
                    className='user_list_ic user_list_ic_actv'
                  />
                </>
              )}
            </span>{' '}
            account
          </NavLink>
          <NavLink
            to='/'
            className={
              splitLocation[1] === 'account' ? 'log_out_link' : 'log_out_link'
            }
            onClick={(e) => e.preventDefault()}
          >
            <span>
              {colorMode === 'light' ? (
                <Image
                  src='/img/logout_ic.svg'
                  alt=''
                  className='user_list_ic'
                />
              ) : (
                <Image
                  src='/img/logout_ic_dark.svg'
                  alt=''
                  className='user_list_ic'
                />
              )}
            </span>{' '}
            <Button className='logout_btn' onClick={onDrawerOpen}>
              logout
            </Button>
          </NavLink>
        </Box>
        <Box className='light_to_darkbtn_prnt d_none_fr0m_991'>
          <Button onClick={toggleColorMode}>
            light <span /> Dark
          </Button>
        </Box>
        <Box className='copyright_bx'>
          DEV LEgion (c) 2022 Powered bitgraphix
        </Box>
      </Box>
      <Modal isOpen={isDrawerOpen} onClose={onDrawerClose} isCentered>
        <ModalOverlay />
        <ModalContent className='widht_set_pernt'>
          <Box className='modal_bg_main modal_bg_main_light'>
            <span>
              {colorMode === 'light' ? (
                <Image
                  src='/img/pop_tp_bg_light.png'
                  alt=''
                  className='pop_tp_bg'
                />
              ) : (
                <Image src='/img/pop_tp_bg.png' alt='' className='pop_tp_bg' />
              )}
            </span>{' '}
            <span>
              {colorMode === 'light' ? (
                <Image
                  // src="/img/pop_tp_bg.png"
                  src='/img/pop_bot_bg_ligjt.png'
                  alt=''
                  className='pop_btm_bg'
                />
              ) : (
                <Image
                  // src="/img/pop_tp_bg_light.png"
                  src='/img/pop_btm_bg.png'
                  alt=''
                  className='pop_btm_bg'
                />
              )}
            </span>{' '}
            {/* <Image src="/img/pop_tp_bg.png" alt="" className="pop_tp_bg" />
            <Image src="/img/pop_btm_bg.png" alt="" className="pop_btm_bg" /> */}
            <Box className='pop_midol_bg' />
            <ModalHeader className='lgout_hed'>Logout</ModalHeader>
            <ModalBody>
              <Text className='modl_body_pera'>
                Are you sure you want to log out?
              </Text>
            </ModalBody>
            <ModalFooter className='footer_modal footer_modal_v2'>
              <Button
                colorScheme='blue'
                mr={3}
                onClick={onDrawerClose}
                className='back_button'
              >
                Back
              </Button>
              <Button
                variant='ghost'
                className='logout_btn'
                onClick={() => handleLogout()}
              >
                {isLoading ? (
                  <CircularProgress isIndeterminate size='24px' />
                ) : (
                  'Logout'
                )}
              </Button>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
    </>
  )
}
const MobileNav = ({ onOpen, ...rest }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 10)
    })
  }, [])
  return (
    <Flex
      ml={{ base: 0, lg: 60 }}
      px={{ base: 4, lg: 24 }}
      alignItems='center'
      borderBottomWidth='1px'
      justifyContent='flex-start'
      {...rest}
      //   className="mbl_header"
      className={scroll ? 'mbl_header scrolled' : 'mbl_header'}
    >
      <IconButton
        variant='outline'
        onClick={onOpen}
        aria-label='open menu'
        icon={<FiMenu />}
        className='mbl_menu_togle_btn'
      />

      <Text className='sidebar_logo_mbl_prnt'>
        <NavLink to='/' className='sidebar_logo_link'>
          {colorMode === 'light' ? (
            <Image src='/img/logo_light.svg' alt='' className='sidebar_logo' />
          ) : (
            <Image src='/img/logo_dark.svg' alt='' className='sidebar_logo' />
          )}
        </NavLink>
      </Text>
      <Box className='light_to_darkbtn_prnt'>
        <Button onClick={toggleColorMode}>
          light <span /> Dark
        </Button>
      </Box>
    </Flex>
  )
}

export default LayoutTwo
