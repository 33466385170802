import React, { useEffect, useState } from 'react'
import {
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Button,
  Image,
  useColorMode,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  GridItem,
  Select,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Text,
  Checkbox,
  AccordionIcon,
  Tfoot,
  Flex
} from '@chakra-ui/react'
import { format, intervalToDuration, lastDayOfWeek, startOfMonth } from "date-fns";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { ArrowBackIcon, SettingsIcon } from '@chakra-ui/icons'
import axios from 'axios'
import httpStatus from 'http-status'
import LayoutTwo from '../../components/layouts/LayoutTwo'
// import Pagination from '../../components/pagination'
import { BASE_URL } from '../../services/constant'
import { useNavigate, useParams } from 'react-router-dom'
import { exportToExcel } from '../../utils';
import ClientReportExport from '../../components/report/ClientReportExport';
import ClientReportExportModal from '../../components/report/ClientReportExportModal';
// import { FormControl, FormLabel, Input } from '@chakra-ui/react'
// import PaginationNew from '../../components/paginationNew'

export default function ClientReportDetail() {
  const params = useParams();
  const { colorMode, toggleColorMode } = useColorMode()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState(undefined)
  const [optionUserType, setOptionUserType] = useState([])
  const [optionClient, setOptionClient] = useState([])
  const [isAdmin, setIsAdmin] = useState(false)
  const [selectedFields, setSelectedFields] = useState({
    project: true,
    task: true,
    role: true,
    user: true,
    time: true,
    price: true
  });
  const [form, setForm] = useState({
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    type: undefined,
    client_id: undefined,
  })
  const navigate = useNavigate()
  const toast = useToast()

  const [data, setData] = useState({});
  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), "MM/dd/yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM/dd/yyyy"));
  const [clientId, setClientId] = useState();
  const [fileName, setFileName] = useState('Report-Client');
  const [finalData, setFinalData] = useState({});
  const [excelData, setExcelData] = useState([]);
  const [user, setUser] = useState()
  const [download, setDownload] = useState(false);
  const [downloaded, setDownloaded] = useState(0);
  const { isOpen: isInvoiceExportOpen, onOpen: onInvoiceExportOpen, onClose: onInvoiceExportClose } = useDisclosure();
  const { isOpen: isgenerateReporttOpen, onOpen: ongenerateReporttOpen, onClose: ongenerateReporttClose } = useDisclosure();

  const getData = async () => {
    if (clientId == undefined || startDate == undefined || endDate == undefined) return;

    try {
      const bluClientToken = localStorage.getItem('bluClientToken');
      const start = new Date(startDate);
      const end = new Date(endDate);
      const response = await fetch(BASE_URL + "/api/report/clientportal/" + clientId + "?startTime=" + start.getTime() + "&endTime=" + end.getTime(), {
        headers: { 'Authorization': 'Bearer ' + bluClientToken }
      });
      if (response.status == httpStatus.OK) {
        const data = await response.json();
        const result = data.result;
        setData(result);
        setFileName(`Report-Client-${result?.name}`);
        setDownload(false);
        setDownloaded(0);
      }
    }
    catch (err) {
    }
  }

  const updateSelection = (field) => {
    if (field == 'project') {
      setSelectedFields(
        {
          ...selectedFields,
          project: !selectedFields[field]
        }
      )
    }
    else if (field == 'task') {
      setSelectedFields(
        {
          ...selectedFields,
          task: !selectedFields[field]
        }
      )
    }
    else if (field == 'role') {
      setSelectedFields(
        {
          ...selectedFields,
          role: !selectedFields[field]
        }
      )
    }
    else if (field == 'user') {
      setSelectedFields(
        {
          ...selectedFields,
          user: !selectedFields[field]
        }
      )
    }
    else if (field == 'time') {
      setSelectedFields(
        {
          ...selectedFields,
          time: !selectedFields[field]
        }
      )
    }
    else if (field == 'price') {
      setSelectedFields(
        {
          ...selectedFields,
          price: !selectedFields[field]
        }
      )
    }
  }

  useEffect(() => {
    getData();
  }, [startDate, endDate, clientId]);

  useEffect(() => {
    if (!data['projects']) return;
    let report = {
      id: data.id,
      name: data.name,
      hours_worked: data.hours_worked,
      total_cost: data.total_cost,
      child: {}
    };

    let child = {};
    if (selectedFields['project'] == 1) {
      for (const itemProject of data['projects']) {
        child[itemProject.id] = {
          id: itemProject.id,
          name: itemProject.name,
          hours_worked: itemProject.hours_worked,
          total_cost: itemProject.total_cost,
          child: {}
        }

        let child1 = {};
        for (const itemTask of itemProject['tasks']) {
          if (selectedFields['task'] == 1) {
            child1[itemTask.id] = {
              id: itemTask.id,
              name: itemTask.name,
              hours_worked: itemTask.hours_worked,
              total_cost: itemTask.total_cost,
              child: []
            }
            let child2 = {};
            for (const itemRole of itemTask['roles']) {
              let itemRoleKey = `${itemRole.id}-${itemRole.notes}`;
              if (selectedFields['user'] == 1) {
                for (const itemUser of itemRole['users']) {
                  child2[itemRoleKey] = {
                    id: itemUser.id,
                    name: itemUser.name,
                    hours_worked: itemUser.hours_worked,
                    total_cost: itemUser.total_cost,
                    notes: itemRole.notes
                  }
                  if (selectedFields['role'] == 1) {
                    child2[itemRoleKey]['role'] = itemRole.role;
                    child2[itemRoleKey]['level'] = itemRole.level;
                    child2[itemRoleKey]['hours_worked'] = itemRole.hours_worked;
                    child2[itemRoleKey]['total_cost'] = itemRole.total_cost;
                  }
                }
              }
              else if (selectedFields['role'] == 1) {
                child2[itemRoleKey] = {
                  id: itemRole.id,
                  role: itemRole.role,
                  level: itemRole.level,
                  hours_worked: itemRole.hours_worked,
                  total_cost: itemRole.total_cost,
                  notes: itemRole.notes,
                  child: []
                }
              }
            }
            child1[itemTask.id]['child'] = child2;
          }
          else {
            for (const itemRole of itemTask['roles']) {
              let itemRoleKey = `${itemRole.id}-${itemRole.notes}`;
              if (selectedFields['user'] == 1) {
                for (const itemUser of itemRole['users']) {
                  if (!child1[itemRoleKey]) {
                    child1[itemRoleKey] = {
                      id: itemUser.id,
                      name: itemUser.name,
                      hours_worked: itemUser.hours_worked,
                      total_cost: itemUser.total_cost,
                      notes: itemRole.notes
                    }
                  }
                  else {
                    child1[itemRoleKey]['hours_worked'] += itemUser.hours_worked;
                    child1[itemRoleKey]['total_cost'] += itemUser.total_cost;
                  }
                  if (selectedFields['role'] == 1) {
                    child1[itemRoleKey]['role'] = itemRole.role;
                    child1[itemRoleKey]['level'] = itemRole.level;
                  }
                }
              }
              else if (selectedFields['role'] == 1) {
                if (!child1[itemRoleKey]) {
                  child1[itemRoleKey] = {
                    id: itemRole.id,
                    role: itemRole.role,
                    level: itemRole.level,
                    hours_worked: itemRole.hours_worked,
                    total_cost: itemRole.total_cost,
                    notes: itemRole.notes,
                    child: []
                  }
                }
                else {
                  child1[itemRoleKey]['hours_worked'] += itemRole.hours_worked;
                  child1[itemRoleKey]['total_cost'] += itemRole.total_cost;
                }
              }
            }
          }
        }
        child[itemProject.id]['child'] = child1;
      }
    }
    else {
      for (const itemProject of data['projects']) {
        for (const itemTask of itemProject['tasks']) {
          if (selectedFields['task'] == 1) {
            child[itemTask.id] = {
              id: itemTask.id,
              name: itemTask.name,
              hours_worked: itemTask.hours_worked,
              total_cost: itemTask.total_cost,
              child: []
            }
            let child1 = {};
            for (const itemRole of itemTask['roles']) {
              let itemRoleKey = `${itemRole.id}-${itemRole.notes}`;
              if (selectedFields['user'] == 1) {
                for (const itemUser of itemRole['users']) {
                  child1[itemUser.id] = {
                    id: itemUser.id,
                    name: itemUser.name,
                    hours_worked: itemUser.hours_worked,
                    total_cost: itemUser.total_cost,
                    notes: itemRole.notes
                  }
                  if (selectedFields['role'] == 1) {
                    child1[itemUser.id]['role'] = itemRole.role;
                    child1[itemUser.id]['level'] = itemRole.level;
                  }
                }
              }
              else if (selectedFields['role'] == 1) {
                child1[itemRoleKey] = {
                  id: itemRole.id,
                  role: itemRole.role,
                  level: itemRole.level,
                  hours_worked: itemRole.hours_worked,
                  total_cost: itemRole.total_cost,
                  notes: itemRole.notes,
                  child: []
                }
              }
            }
            child[itemTask.id]['child'] = child1;
          }
          else {
            for (const itemRole of itemTask['roles']) {
              let itemRoleKey = `${itemRole.id}-${itemRole.notes}`;
              if (selectedFields['user'] == 1) {
                for (const itemUser of itemRole['users']) {
                  if (!child[itemUser.id]) {
                    child[itemUser.id] = {
                      id: itemUser.id,
                      name: itemUser.name,
                      hours_worked: itemUser.hours_worked,
                      total_cost: itemUser.total_cost,
                      notes: itemRole.notes
                    }
                  }
                  else {
                    child[itemUser.id]['hours_worked'] += itemUser.hours_worked;
                    child[itemUser.id]['total_cost'] += itemUser.total_cost;
                  }
                  if (selectedFields['role'] == 1) {
                    child[itemUser.id]['role'] = itemRole.role;
                    child[itemUser.id]['level'] = itemRole.level;
                  }
                }
              }
              else if (selectedFields['role'] == 1) {
                if (!child[itemRoleKey]) {
                  child[itemRoleKey] = {
                    id: itemRole.id,
                    role: itemRole.role,
                    level: itemRole.level,
                    hours_worked: itemRole.hours_worked,
                    total_cost: itemRole.total_cost,
                    notes: itemRole.notes,
                    child: []
                  }
                }
                else {
                  child[itemRoleKey]['hours_worked'] += itemRole.hours_worked;
                  child[itemRoleKey]['total_cost'] += itemRole.total_cost;
                }
              }
            }
          }
        }
      }
    }
    report['child'] = child;
    console.log("report", JSON.stringify(report));

    let finalReport = JSON.parse(JSON.stringify(report));
    let childs = [];

    if (Object.keys(report['child']).length > 0) {
      for (const childId0 in report['child']) {
        if (Object.hasOwnProperty.call(report['child'], childId0)) {
          const child0 = report['child'][childId0];
          let newChild0 = child0;
          let childs0 = [];
          if (child0['child']) {
            if (Object.keys(child0['child']).length > 0) {
              for (const childId1 in child0['child']) {
                if (Object.hasOwnProperty.call(child0['child'], childId1)) {
                  const child1 = child0['child'][childId1];
                  let newChild1 = child1;
                  let childs1 = [];
                  if (child1['child']) {
                    if (Object.keys(child1['child']).length > 0) {
                      for (const childId2 in child1['child']) {
                        if (Object.hasOwnProperty.call(child1['child'], childId2)) {
                          const child2 = child1['child'][childId2];
                          childs1.push(child2);
                        }
                      }
                    }
                  }
                  newChild1['child'] = childs1;
                  childs0.push(newChild1);
                }
              }
            }
          }
          newChild0['child'] = childs0;
          childs.push(newChild0);
        }
      }
    }
    finalReport['child'] = childs;

    setFinalData(finalReport);

    let no = 1;
    let excel = [];
    let cell = {
      "No": no,
      "Type": 'Client',
      "Name": data?.name,
    };
    if (selectedFields['time'] == 1) {
      cell['Hours Worked'] = Number(data?.hours_worked).toFixed(2);
    }
    if (selectedFields['price'] == 1) {
      cell['Total Cost'] = Number(data?.total_cost).toFixed(2);
    }
    excel.push(cell);
    no++;
    for (const project of data?.projects) {
      if (selectedFields['project'] == 1) {
        let cell = {
          "No": no,
          "Type": 'Project',
          "Name": project?.name
        };
        if (selectedFields['time'] == 1) {
          cell['Hours Worked'] = Number(project?.hours_worked).toFixed(2);
        }
        if (selectedFields['price'] == 1) {
          cell['Total Cost'] = Number(project?.total_cost).toFixed(2);
        }
        excel.push(cell);
        no++;
      }
      for (const task of project?.tasks) {
        if (selectedFields['task'] == 1) {
          let cell = {
            "No": no,
            "Type": 'Task',
            "Name": task?.name,
          };
          if (selectedFields['time'] == 1) {
            cell['Hours Worked'] = Number(task?.hours_worked).toFixed(2);
          }
          if (selectedFields['price'] == 1) {
            cell['Total Cost'] = Number(task?.total_cost).toFixed(2);
          }
          excel.push(cell);
          no++;
        }

        for (const role of task?.roles) {
          if (selectedFields['role'] == 1) {
            let cell = {
              "No": no,
              "Type": 'Role',
              "Name": role?.name,
            };
            if (selectedFields['time'] == 1) {
              cell['Hours Worked'] = Number(role?.hours_worked).toFixed(2);
            }
            if (selectedFields['price'] == 1) {
              cell['Total Cost'] = Number(role?.total_cost).toFixed(2);
            }
            excel.push(cell);
            no++;
          }

          for (const user of role?.users) {
            if (selectedFields['user'] == 1) {
              let cell = {
                "No": no,
                "Type": 'User',
                "Name": user?.name,
              };
              if (selectedFields['time'] == 1) {
                cell['Hours Worked'] = Number(user?.hours_worked).toFixed(2);
              }
              if (selectedFields['price'] == 1) {
                cell['Total Cost'] = Number(user?.total_cost).toFixed(2);
              }
              excel.push(cell);
              no++;
            }
          }
        }
      }
    }
    setExcelData(excel);
  }, [selectedFields, data]);

  // useEffect(() => {
  //   setClientId(user?.client_id)
  // }, [user])

  useEffect(() => {
    const user = localStorage.getItem('bluClientUser')
    if (user) {
      const userData = JSON.parse(user)
      setUser(userData)
      if (userData.type === 'admin') {
        setIsAdmin(true)
      } else {
        setIsAdmin(false)
      }
    }
  }, [])

  useEffect(() => {
    if (params?.id) {
      setClientId(params?.id)
    }
  }, [params])

  return (
    <>
      <LayoutTwo>
        <>
          <Box className='userlist_page'>
            <Box className='usr_list_cntnr'>
              <Heading as='h2' className='def_head_dbrd'>
                client report <span>detailed task view</span>
              </Heading>
              <Box className='user_cntnt_box user_cntnt_box_new'>
                {colorMode === 'light' ? (
                  <Image
                    src='/img/user_list_top_img_v2.png'
                    alt=''
                    className='user_list_top_img'
                  />
                ) : (
                  <Image
                    src='/img/user_list_top_img_dark.png'
                    alt=''
                    className='user_list_top_img'
                  />
                )}
                {colorMode === 'light' ? (
                  <Image
                    src='/img/user_list_btm_img_v2.png'
                    alt=''
                    className='user_list_btm_img'
                  />
                ) : (
                  <Image
                    src='/img/user_list_btm_img_dark.png'
                    alt=''
                    className='user_list_btm_img'
                  />
                )}
                <Box className='user_cntr_line_bg user_cntr_line_bg_v2' />
                <Box className='header_client_rpt_list alignEnd'>
                  {/* <Box className='had_txt_back_btn'>
                    <Button className='back_btn_header' as="a" href="client-report">
                      <ArrowBackIcon />
                      Back
                    </Button>
                    <Heading as="h3" className='dflt_h3_ttl'>{data.name} detail view</Heading>
                  </Box> */}
                  <Box className='newRhead'>
                    <label>Client</label>
                    <h3>{data.name}</h3>
                  </Box>
                  <Box className='right_datepkr right_datepkr_v2'>
                    <Box className='frst_right_dtepkr'>
                      {colorMode === "light" ? (
                        <Image src="/img/clock_ic.svg" alt="" className="clock_ic_as" />
                      ) : (
                        <Image src="/img/clock_ic_dark.svg" alt="" className="clock_ic_as" />
                      )}
                      <Text className="space_btwn">From</Text>
                      <Box className="date_bx">
                        <Image src="/img/datepicker_ic.svg" alt="" />
                        {/* <input type="date" /> */}
                        <DateRangePicker
                          initialSettings={{
                            singleDatePicker: true,
                            showDropdowns: false,
                            opens: 'left',
                          }}
                          onCallback={(start, end, label) => {
                            let date = format(start.toDate(), "MM/dd/yyy");
                            // localStorage.setItem("bluTimeframeStart", date);
                            setStartDate(date);
                            // localStorage.setItem("bluTimeframeType", TIMEFRAME_TYPE.CUSTOM.toString());
                          }}
                        >
                          <input type="text" className="form-control"
                            placeholder='Choose Start Period'
                            value={startDate} />
                        </DateRangePicker>
                      </Box>
                      <Text className="space_btwn space_btwn_to">To</Text>
                      <Box className="date_bx">
                        <Image src="/img/datepicker_ic.svg" alt="" />
                        {/* <input type="date" /> */}
                        <DateRangePicker
                          initialSettings={{
                            singleDatePicker: true,
                            showDropdowns: false,
                            opens: 'left',
                          }}
                          onCallback={(start, end, label) => {
                            let date = format(end.toDate(), "MM/dd/yyy");
                            // localStorage.setItem("bluTimeframeEnd", date);
                            setEndDate(date)
                            // localStorage.setItem("bluTimeframeType", TIMEFRAME_TYPE.CUSTOM.toString());
                          }}
                        >
                          <input type="text" className="form-control"
                            placeholder='Choose End Period'
                            value={endDate} />
                        </DateRangePicker>
                      </Box>
                    </Box>
                    <Box className='gnrl_rport_bx gnrl_rport_bx_v2'>
                      {/* <Button className='gnrl_rpt_btn'
                        onClick={() => {
                          onInvoiceExportOpen();
                          // setDownload(true);
                          // setDownloaded(0);
                        }}
                      >
                        Generate Pdf
                      </Button> */}
                      <Button onClick={() => {
                        setDownloaded(0);
                        onInvoiceExportOpen()
                      }}
                        className='gnrl_rpt_btn'
                      >
                        Generate report
                      </Button>
                      {/* <Button className="setting_btn_prjct"
                        onClick={onOpen}
                      >
                        <Image src="/img/setting_ic_prjt.svg" alt="" />
                      </Button> */}
                      {/* <Button className='gnrl_rpt_btn'
                        onClick={() => {
                          exportToExcel(fileName, excelData)
                        }}
                      >
                        Generate Excel
                      </Button> */}
                      {/* <Button className='stng_btn_head' onClick={onOpen}><SettingsIcon /></Button> */}
                      <Button className='stng_btn_head' onClick={onOpen}><SettingsIcon /></Button>
                    </Box>
                  </Box>
                </Box>

                {/* DATA */}
                <Box className='table_client_list table_client_list_report only_lvl_tbl prjct1_2mix'>
                  {/* <TableContainer>
                      <Table variant="simple" className='client_report_tbl'>
                        <Thead className='tbl_clnt_head'>
                          <Tr className='bg_traprnt pd-0'>
                            <Th className="client_tbl_frst" colSpan={3}>Client</Th>
                            {selectedFields['time'] && (
                              <Th className="">
                                Hours Worked (hr)
                              </Th>
                            )}
                            {selectedFields['price'] && (
                              <Th className="last_tbl_report_th">
                                Total Cost ($)
                              </Th>
                            )}
                          </Tr>
                          <Tr className='dflt_bg_tr'>
                            <Th className="client_tbl_frst" colSpan={3}>{data.name}</Th>
                            {selectedFields['time'] && (
                              <Th className="">
                                {Number(data?.hours_worked).toFixed(2)}
                              </Th>
                            )}
                            {selectedFields['price'] && (
                              <Th className="last_tbl_report_th">
                                {Number(data?.total_cost).toFixed(2)}
                              </Th>
                            )}
                          </Tr>
                        </Thead>
                        <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2'>
                        </Tbody>
                      </Table>
                    </TableContainer> */}

                  {selectedFields['project'] ? (
                    <Accordion defaultIndex={[0]} allowMultiple className='acrdian_new_panl'>

                      {finalData?.child?.map((item) => {
                        return (
                          <AccordionItem>
                            <h2>
                              <AccordionButton className='prjct_txt_btn'>
                                <Box as="span" flex='1' textAlign='left'>
                                  <span className='prjct_clr_bg'>{item.name}</span>
                                </Box>
                                <Box className='right_acrdin_header'>
                                  {selectedFields['time'] && (
                                    <h3>Hours Worked (hr)<span>{Number(item?.hours_worked).toFixed(2)}</span></h3>
                                  )}
                                  {selectedFields['price'] && (
                                    <h3>Total Cost ($)<span>{Number(item?.total_cost).toFixed(2)}</span></h3>
                                  )}
                                  <Button className='hide_btn_new'>
                                    <span className='hide_dtl'>Hide details</span>
                                    <span className='show_dtl'>Show details</span>
                                    <img src='/img/button_show.svg' className='accArrow' alt='' />
                                  </Button>
                                </Box>
                              </AccordionButton>
                            </h2>

                            <AccordionPanel className='accdian_pnl_new_pdng'>
                              <TableContainer>
                                <Table variant="simple" className='client_report_tbl client_report_tbl_task_only'>
                                  <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2 w-80'>

                                    {selectedFields['task'] ?
                                      <>
                                        <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new'>
                                          <Td className="width_onl_10">Task ID</Td>
                                          <Td className="width_onl_10">Task</Td>
                                          {selectedFields['role'] && (
                                            <>
                                              <Td className="width_onl_10">Role</Td>
                                              <Td className="width_onl_10">Notes</Td>
                                            </>
                                          )}
                                          <Td className="width_onl_10">Date</Td>
                                          {selectedFields['time'] && (
                                            <Td className=''>Hours Worked (hr)</Td>
                                          )}
                                          {selectedFields['price'] && (
                                            <Td className=''>Total Cost ($)</Td>
                                          )}
                                        </Tr>

                                        {item?.child?.map((item1) => {
                                          return item1?.child?.map((item2) => {
                                            let colSpan = 1;
                                            if (selectedFields['time']) colSpan++;
                                            if (selectedFields['price']) colSpan++;
                                            return (
                                              <>
                                                <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr task_tr_new'>
                                                  <Td className="width_onl_10">{item1.id.substring(item1.id.length - 6)}</Td>
                                                  <Td className="width_onl_10">{item1.name}</Td>
                                                  {selectedFields['role'] && (
                                                    <>
                                                      <Td className="width_onl_10 weight400">{item2.role}</Td>
                                                      <Td className="width_onl_10 weight400">{item2.notes}</Td>
                                                    </>
                                                  )}
                                                  <Td className="width_onl_10 weight400">{item2.name}</Td>
                                                  {selectedFields['time'] && (
                                                    <Td className=''>{Number(item2?.hours_worked).toFixed(2)}</Td>
                                                  )}
                                                  {selectedFields['price'] && (
                                                    <Td className=''>{Number(item2?.total_cost).toFixed(2)}</Td>
                                                  )}
                                                </Tr>

                                                {/* {(selectedFields['user'] || selectedFields['role']) && (
                                                    <Tr>
                                                      <Box className='table_client_list table_client_list_report only_lvl_tbl paddding_58'>
                                                        <TableContainer>
                                                          <Table variant="simple" className='client_report_tbl'>
                                                            <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2 w-80'>
                                                              <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian'>
                                                                {selectedFields['user'] && (
                                                                  <Td className=''>Employee</Td>
                                                                )}
                                                                {selectedFields['role'] && (
                                                                  <>
                                                                    <Td className="width_onl_10">Role</Td>
                                                                    <Td className='width_onl_70'>level</Td>
                                                                  </>
                                                                )}
                                                                {selectedFields['time'] && (
                                                                  <Td className=''>Hours Worked (hr)</Td>
                                                                )}

                                                                {selectedFields['price'] && (
                                                                  <Td className=''>Total Cost ($)</Td>
                                                                )}
                                                              </Tr>

                                                              {item1?.child?.map((item2) => {
                                                                return (<Tr className='inr_body_tr_2'>
                                                                  {selectedFields['user'] && (
                                                                    <Td className="frst_web3">{item2.name}</Td>
                                                                  )}
                                                                  {selectedFields['role'] && (
                                                                    <>
                                                                      <Td className='third_40width'>
                                                                        <Box className='lvl_1_bx'>
                                                                          <span>{item2.role}</span>
                                                                        </Box>
                                                                      </Td>
                                                                      <Td className='third_40width'>
                                                                        <Box className='lvl_1_bx'>
                                                                          <span>{item2.level}</span>
                                                                        </Box>
                                                                      </Td>
                                                                    </>

                                                                  )}
                                                                  {selectedFields['time'] && (
                                                                    <Td className=''>{Number(item2?.hours_worked).toFixed(2)}</Td>
                                                                  )}
                                                                  {selectedFields['price'] && (
                                                                    <Td className=''>{Number(item2?.total_cost).toFixed(2)}</Td>
                                                                  )}
                                                                </Tr>)
                                                              })}
                                                            </Tbody>
                                                          </Table>
                                                        </TableContainer>
                                                      </Box>
                                                    </Tr>
                                                  )} */}
                                              </>
                                            )
                                          })
                                        })}
                                      </>
                                      : <></>
                                    }
                                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr task_tr_new'>
                                      <Td></Td>
                                      <Td></Td>
                                      {selectedFields['role'] && (
                                        <Td></Td>
                                      )}
                                      <Td></Td>
                                      {selectedFields['time'] && (
                                        <Td>
                                          <label>Hours Worked (hr)</label>
                                          {Number(item?.hours_worked).toFixed(2)}
                                        </Td>
                                      )}
                                      {selectedFields['price'] && (
                                        <Td>
                                          <label>Total Cost ($)</label>
                                          {Number(item?.total_cost).toFixed(2)}
                                        </Td>
                                      )}
                                    </Tr>
                                  </Tbody>
                                </Table>
                              </TableContainer>
                            </AccordionPanel>
                          </AccordionItem>
                        )
                      })}
                    </Accordion>
                  ) : (<></>)}
                </Box>

                {/* <Box className='table_client_list table_client_list_report'>
                    <TableContainer>
                      <Table variant="simple" className='client_report_tbl'>
                        <Thead className='tbl_clnt_head'>
                          <Tr className='bg_traprnt pd-0'>
                            <Th className="client_tbl_frst">Client</Th>
                            <Th className="">Hours Worked (hr)</Th>
                            <Th className="last_tbl_report_th">Total Cost ($)</Th>
                          </Tr>
                          <Tr className='dflt_bg_tr'>
                            <Th className="client_tbl_frst">Swapsicle</Th>
                            <Th className="">104.6</Th>
                            <Th className="last_tbl_report_th">361.00</Th>
                          </Tr>
                        </Thead>
                        <Tbody className='table_body table_body_swpcl_tbl'>
                          <Tr>
                            <Td className='frst_td_nl'><span className='span_prjct_grdant'>Project 1</span></Td>
                            <Td>Hours Worked (hr)<span>104.6</span></Td>
                            <Td className='last_bdy_td_dflt'>Total Cost ($)<span>361.00</span></Td>
                          </Tr>
                          <Tr>
                            <Td className='frst_td_nl'><span className='span_prjct_grdant'>Project 2</span></Td>
                            <Td>Hours Worked (hr)<span>104.6</span></Td>
                            <Td className='last_bdy_td_dflt'>Total Cost ($)<span>361.00</span></Td>
                          </Tr>
                          <Tr>
                            <Td className='frst_td_nl'><span className='span_prjct_grdant'>Project 3</span></Td>
                            <Td>Hours Worked (hr)<span>104.6</span></Td>
                            <Td className='last_bdy_td_dflt'>Total Cost ($)<span>361.00</span></Td>
                          </Tr>
                          <Tr>
                            <Td className='frst_td_nl'><span className='span_prjct_grdant'>Project 4</span></Td>
                            <Td>Hours Worked (hr)<span>104.6</span></Td>
                            <Td className='last_bdy_td_dflt'>Total Cost ($)<span>361.00</span></Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <PaginationNew /> */}

                {/* Payment_2 */}
                {/* <Box className='table_client_list table_client_list_report only_lvl_tbl'>
                    <TableContainer>
                      <Table variant="simple" className='client_report_tbl'>
                        <Thead className='tbl_clnt_head'>
                          <Tr className='bg_traprnt pd-0'>
                            <Th className="client_tbl_frst" colSpan={3}>User</Th>
                            <Th className="">Hours Worked (hr)</Th>
                            <Th className="last_tbl_report_th">Total Cost ($)</Th>
                          </Tr>
                          <Tr className='dflt_bg_tr'>
                            <Th className="client_tbl_frst" colSpan={3}>Swapsicle</Th>
                            <Th className="">104.6</Th>
                            <Th className="last_tbl_report_th">361.00</Th>
                          </Tr>
                        </Thead>
                        <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2'>
                          <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clras'>
                            <Td className='frst_td_nl'>Employee</Td>
                            <Td>Role</Td>
                            <Td className='third_40width'>Level</Td>
                            <Td className=''>Hours Worked (hr)</Td>
                            <Td className=''>Total Cost ($)</Td>
                          </Tr>


                          <Tr className='inr_body_tr_2 inr_body_tr_2_as'>
                            <Td className='frst_td_nl'>
                              <Heading as="h3">
                                <img src='./img/man_sml_jen.svg' alt='' />
                                Jen
                              </Heading>
                            </Td>
                            <Td>Web 3</Td>
                            <Td className='third_40width'>
                              <Box className='lvl_1_bx'>
                                <span>Level 2</span>
                              </Box>
                            </Td>
                            <Td className=''>4.44</Td>
                            <Td className=''>16.88</Td>
                          </Tr>
                          <Tr className='inr_body_tr_2 inr_body_tr_2_as'>
                            <Td className='frst_td_nl'>
                              <Heading as="h3">
                                <img src='./img/man_sml_jen.svg' alt='' />
                                Billy
                              </Heading>
                            </Td>
                            <Td>Web 3</Td>
                            <Td className='third_40width'>
                              <Box className='lvl_1_bx'>
                                <span>Level 3</span>
                              </Box>
                            </Td>
                            <Td className=''>4.44</Td>
                            <Td className=''>16.88</Td>
                          </Tr>
                          <Tr className='inr_body_tr_2 inr_body_tr_2_as'>
                            <Td className='frst_td_nl'>
                              <Heading as="h3">
                                <img src='./img/man_sml_jen.svg' alt='' />
                                Alex
                              </Heading>
                            </Td>
                            <Td>2D Design</Td>
                            <Td className='third_40width'>
                              <Box className='lvl_1_bx'>
                                <span>Level 3</span>
                              </Box>
                            </Td>
                            <Td className=''>4.44</Td>
                            <Td className=''>16.88</Td>
                          </Tr>
                          <Tr className='inr_body_tr_2 inr_body_tr_2_as'>
                            <Td className='frst_td_nl'>
                              <Heading as="h3">
                                <img src='./img/man_sml_jen.svg' alt='' />
                                Dima
                              </Heading>
                            </Td>
                            <Td>Motion Design</Td>
                            <Td className='third_40width'>
                              <Box className='lvl_1_bx'>
                                <span>Level 2</span>
                              </Box>
                            </Td>
                            <Td className=''>4.44</Td>
                            <Td className=''>16.88</Td>
                          </Tr>
                          <Tr className='inr_body_tr_2 inr_body_tr_2_as'>
                            <Td className='frst_td_nl'>
                              <Heading as="h3">
                                <img src='./img/man_sml_jen.svg' alt='' />
                                Ash
                              </Heading>
                            </Td>
                            <Td>Front - end</Td>
                            <Td className='third_40width'>
                              <Box className='lvl_1_bx'>
                                <span>Level 3</span>
                              </Box>
                            </Td>
                            <Td className=''>4.44</Td>
                            <Td className=''>16.88</Td>
                          </Tr>
                          <Tr className='inr_body_tr_2 inr_body_tr_2_as'>
                            <Td className='frst_td_nl'>
                              <Heading as="h3">
                                <img src='./img/man_sml_jen.svg' alt='' />
                                Jen
                              </Heading>
                            </Td>
                            <Td>2D Design</Td>
                            <Td className='third_40width'>
                              <Box className='lvl_1_bx'>
                                <span>Level 3</span>
                              </Box>
                            </Td>
                            <Td className=''>4.44</Td>
                            <Td className=''>16.88</Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <PaginationNew /> */}

                {/* Payment_2 */}
                {/* <Box className='table_client_list table_client_list_report only_lvl_tbl'>
                    <TableContainer>
                      <Table variant="simple" className='client_report_tbl'>
                        <Thead className='tbl_clnt_head'>
                          <Tr className='bg_traprnt pd-0'>
                            <Th className="client_tbl_frst" colSpan={3}>User</Th>
                            <Th className="">Hours Worked (hr)</Th>
                            <Th className="last_tbl_report_th">Total Cost ($)</Th>
                          </Tr>
                          <Tr className='dflt_bg_tr'>
                            <Th className="client_tbl_frst" colSpan={3}>Swapsicle</Th>
                            <Th className="">104.6</Th>
                            <Th className="last_tbl_report_th">361.00</Th>
                          </Tr>
                        </Thead>
                        <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2'>

                        </Tbody>
                      </Table>
                    </TableContainer>
                    <Accordion defaultIndex={[0]} allowMultiple className='acrdian_new_panl'>
                      <AccordionItem>
                        <h2>
                          <AccordionButton className='prjct_txt_btn'>
                            <Box as="span" flex='1' textAlign='left'>
                              <span className='prjct_clr_bg'>Project 1</span>
                            </Box>
                            <Box className='right_acrdin_header'>
                              <h3>Hours Worked (hr)<span>104.6</span></h3>
                              <h3>Total Cost ($)<span>361.00</span></h3>
                              <Button className='hide_btn_new'>
                                <span className='hide_dtl'>Hide details</span>
                                <span className='show_dtl'>Show details</span>
                                <img src='./img/button_show.svg' alt='' />
                              </Button>
                            </Box>
                          </AccordionButton>
                        </h2>
                        <AccordionPanel>
                          <Box className='table_client_list table_client_list_report only_lvl_tbl'>
                            <TableContainer>
                              <Table variant="simple" className='client_report_tbl'>
                                <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2 w-80'>
                                  <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian'>
                                    <Td colSpan={3} className="width_onl_80">Role</Td>
                                    <Td className=''>Hours Worked (hr)</Td>
                                    <Td className=''>Total Cost ($)</Td>
                                  </Tr>

                                  <Tr className='inr_body_tr_2'>
                                    <Td colSpan={3} className="frst_web3">Web 3</Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td colSpan={3} className="frst_web3">Web 3</Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td colSpan={3} className="frst_web3">2D Design</Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td colSpan={3} className="frst_web3">Motion Design</Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td colSpan={3} className="frst_web3">Front - end</Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td colSpan={3} className="frst_web3">2D Design</Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                </Tbody>
                              </Table>
                            </TableContainer>
                            <TableContainer className='cntnrt_psn_46'>
                              <Table variant="simple" className='client_report_tbl'>
                                <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2 w-80'>
                                  <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian'>
                                    <Td colSpan={3} className="width_onl_80">Role</Td>
                                    <Td className=''>Hours Worked (hr)</Td>
                                    <Td className=''>Total Cost ($)</Td>
                                  </Tr>

                                  <Tr className='inr_body_tr_2'>
                                    <Td colSpan={3} className="frst_web3">Web 3</Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td colSpan={3} className="frst_web3">Web 3</Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td colSpan={3} className="frst_web3">2D Design</Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td colSpan={3} className="frst_web3">Motion Design</Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td colSpan={3} className="frst_web3">Front - end</Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td colSpan={3} className="frst_web3">2D Design</Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                </Tbody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Box> */}

                {/* payment three 3 */}
                {/* <Box className='table_client_list table_client_list_report only_lvl_tbl prjct1_2mix'>
                    <TableContainer>
                      <Table variant="simple" className='client_report_tbl'>
                        <Thead className='tbl_clnt_head'>
                          <Tr className='bg_traprnt pd-0'>
                            <Th className="client_tbl_frst" colSpan={3}>User</Th>
                            <Th className="">Hours Worked (hr)</Th>
                            <Th className="last_tbl_report_th">Total Cost ($)</Th>
                          </Tr>
                          <Tr className='dflt_bg_tr'>
                            <Th className="client_tbl_frst" colSpan={3}>Swapsicle</Th>
                            <Th className="">104.6</Th>
                            <Th className="last_tbl_report_th">361.00</Th>
                          </Tr>
                        </Thead>
                        <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2'>

                        </Tbody>
                      </Table>
                    </TableContainer>
                    <Accordion defaultIndex={[0]} allowMultiple className='acrdian_new_panl'>
                      <AccordionItem>
                        <h2>
                          <AccordionButton className='prjct_txt_btn'>
                            <Box as="span" flex='1' textAlign='left'>
                              <span className='prjct_clr_bg'>Project 1</span>
                            </Box>
                            <Box className='right_acrdin_header'>
                              <h3>Hours Worked (hr)<span>104.6</span></h3>
                              <h3>Total Cost ($)<span>361.00</span></h3>
                              <Button className='hide_btn_new'>
                                <span className='hide_dtl'>Hide details</span>
                                <span className='show_dtl'>Show details</span>
                                <img src='./img/button_show.svg' alt='' />
                              </Button>
                            </Box>
                          </AccordionButton>
                        </h2>
                        <AccordionPanel>
                          <Box className='table_client_list table_client_list_report only_lvl_tbl'>
                            <TableContainer>
                              <Table variant="simple" className='client_report_tbl'>
                                <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2 w-80'>
                                  <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian'>
                                    <Td className="width_onl_10">Role</Td>
                                    <Td className='width_onl_70'>level</Td>
                                    <Td className=''>Hours Worked (hr)</Td>
                                    <Td className=''>Total Cost ($)</Td>
                                  </Tr>

                                  <Tr className='inr_body_tr_2'>
                                    <Td className="frst_web3">Web 3</Td>
                                    <Td className='third_40width'>
                                      <Box className='lvl_1_bx'>
                                        <span>Level 2</span>
                                      </Box>
                                    </Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td className="frst_web3">Web 3</Td>
                                    <Td className='third_40width'>
                                      <Box className='lvl_1_bx'>
                                        <span>Level 3</span>
                                      </Box>
                                    </Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td className="frst_web3">2D Design</Td>
                                    <Td className='third_40width'>
                                      <Box className='lvl_1_bx'>
                                        <span>Level 3</span>
                                      </Box>
                                    </Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td className="frst_web3">Motion Design</Td>
                                    <Td className='third_40width'>
                                      <Box className='lvl_1_bx'>
                                        <span>Level 2</span>
                                      </Box>
                                    </Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td className="frst_web3">Front - end</Td>
                                    <Td className='third_40width'>
                                      <Box className='lvl_1_bx'>
                                        <span>Level 3</span>
                                      </Box>
                                    </Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td className="frst_web3">2D Design</Td>
                                    <Td className='third_40width'>
                                      <Box className='lvl_1_bx'>
                                        <span>Level 3</span>
                                      </Box>
                                    </Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                </Tbody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem className='prjct_2_item'>
                        <h2>
                          <AccordionButton className='prjct_txt_btn'>
                            <Box as="span" flex='1' textAlign='left'>
                              <span className='prjct_clr_bg'>Project 2</span>
                            </Box>
                            <Box className='right_acrdin_header'>
                              <h3>Hours Worked (hr)<span>104.6</span></h3>
                              <h3>Total Cost ($)<span>361.00</span></h3>
                              <Button className='hide_btn_new'>
                                <span className='hide_dtl'>Hide details</span>
                                <span className='show_dtl'>Show details</span>
                                <img src='./img/button_show.svg' alt='' />
                              </Button>
                            </Box>
                          </AccordionButton>
                        </h2>
                        <AccordionPanel>
                          <Box className='table_client_list table_client_list_report only_lvl_tbl'>
                            <TableContainer>
                              <Table variant="simple" className='client_report_tbl'>
                                <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2 w-80'>
                                  <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian'>
                                    <Td className="width_onl_10">Role</Td>
                                    <Td className='width_onl_70'>level</Td>
                                    <Td className=''>Hours Worked (hr)</Td>
                                    <Td className=''>Total Cost ($)</Td>
                                  </Tr>

                                  <Tr className='inr_body_tr_2'>
                                    <Td className="frst_web3">Web 3</Td>
                                    <Td className='third_40width'>
                                      <Box className='lvl_1_bx'>
                                        <span>Level 2</span>
                                      </Box>
                                    </Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td className="frst_web3">Web 3</Td>
                                    <Td className='third_40width'>
                                      <Box className='lvl_1_bx'>
                                        <span>Level 3</span>
                                      </Box>
                                    </Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td className="frst_web3">2D Design</Td>
                                    <Td className='third_40width'>
                                      <Box className='lvl_1_bx'>
                                        <span>Level 3</span>
                                      </Box>
                                    </Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td className="frst_web3">Motion Design</Td>
                                    <Td className='third_40width'>
                                      <Box className='lvl_1_bx'>
                                        <span>Level 2</span>
                                      </Box>
                                    </Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td className="frst_web3">Front - end</Td>
                                    <Td className='third_40width'>
                                      <Box className='lvl_1_bx'>
                                        <span>Level 3</span>
                                      </Box>
                                    </Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                  <Tr className='inr_body_tr_2'>
                                    <Td className="frst_web3">2D Design</Td>
                                    <Td className='third_40width'>
                                      <Box className='lvl_1_bx'>
                                        <span>Level 3</span>
                                      </Box>
                                    </Td>
                                    <Td className=''>4.44</Td>
                                    <Td className=''>16.88</Td>
                                  </Tr>
                                </Tbody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Box> */}

                {/* task nmber */}
                {/* <Box className='table_client_list table_client_list_report only_lvl_tbl prjct1_2mix'>
                    <TableContainer>
                      <Table variant="simple" className='client_report_tbl'>
                        <Thead className='tbl_clnt_head'>
                          <Tr className='bg_traprnt pd-0'>
                            <Th className="client_tbl_frst" colSpan={3}>User</Th>
                            <Th className="">Hours Worked (hr)</Th>
                            <Th className="last_tbl_report_th">Total Cost ($)</Th>
                          </Tr>
                          <Tr className='dflt_bg_tr'>
                            <Th className="client_tbl_frst" colSpan={3}>Swapsicle</Th>
                            <Th className="">104.6</Th>
                            <Th className="last_tbl_report_th">361.00</Th>
                          </Tr>
                        </Thead>
                        <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2'>

                        </Tbody>
                      </Table>
                    </TableContainer>
                    <TableContainer>
                      <Table variant="simple" className='client_report_tbl client_report_tbl_task_only task_last_tbl'>
                        <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2 w-80'>
                          <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new'>
                            <Td className="width_onl_10">Task</Td>
                            <Td className=''>Hours Worked (hr)</Td>
                            <Td className=''>Total Cost ($)</Td>
                          </Tr>
                          <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr task_tr_new'>
                            <Td className="width_onl_10 fnt_wtg_400">Task name number one</Td>
                            <Td className=''>4.44</Td>
                            <Td className=''>16.88</Td>
                          </Tr>
                          <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr task_tr_new'>
                            <Td className="width_onl_10 fnt_wtg_400">Task name number one</Td>
                            <Td className=''>4.44</Td>
                            <Td className=''>16.88</Td>
                          </Tr>
                          <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr task_tr_new'>
                            <Td className="width_onl_10 fnt_wtg_400">Task name number one</Td>
                            <Td className=''>4.44</Td>
                            <Td className=''>16.88</Td>
                          </Tr>
                          <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr task_tr_new'>
                            <Td className="width_onl_10 fnt_wtg_400">Task name number one</Td>
                            <Td className=''>4.44</Td>
                            <Td className=''>16.88</Td>
                          </Tr>
                          <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr task_tr_new'>
                            <Td className="width_onl_10 fnt_wtg_400">Task name number one</Td>
                            <Td className=''>4.44</Td>
                            <Td className=''>16.88</Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box> */}
                {/* <PaginationNew /> */}
              </Box>
            </Box>
          </Box>

          <Modal isOpen={isOpen} onClose={onClose} className="asasaasaassasa">
            <ModalOverlay className='sas' />
            <ModalContent className="new_modele_setting">
              <ModalHeader>
                <Button onClick={onClose} className="back_btn_header">
                  <ArrowBackIcon />
                  Back
                </Button>
              </ModalHeader>
              {/* <ModalCloseButton /> */}
              <ModalBody>
                <Box className="mdl_rprt_client">
                  <Heading as="h2">Select fields to show on your report</Heading>
                  <Box className='chckbx_modl_in'>
                    <Checkbox isChecked={selectedFields['project']}
                      onChange={(e) => {
                        updateSelection('project')
                      }}
                    >Project</Checkbox>
                    <Checkbox isChecked={selectedFields['task']}
                      onChange={(e) => {
                        updateSelection('task')
                      }}
                    >Task</Checkbox>
                    <Checkbox isChecked={selectedFields['role']}
                      onChange={(e) => {
                        updateSelection('role')
                      }}
                    >Role</Checkbox>
                    {/* <Checkbox isChecked={selectedFields['user']}
                      onChange={(e) => {
                        updateSelection('user')
                      }}
                    >User</Checkbox> */}
                    <Checkbox isChecked={selectedFields['time']}
                      onChange={(e) => {
                        updateSelection('time')
                      }}
                    >Time</Checkbox>
                    <Checkbox isChecked={selectedFields['price']}
                      onChange={(e) => {
                        updateSelection('price')
                      }}
                    >Price</Checkbox>
                  </Box>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Box className='last_mdl_two_btns'>
                  <Button onClick={onClose} className="cncl_btn_mdl">Save</Button>
                  {/* <Button className="cnfrm_btn_mdl">Confirm and Pay</Button> */}
                </Box>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
        <ClientReportExportModal
          // finalData={finalData}
          clientId={clientId}
          startDate={startDate}
          endDate={endDate}
          isSave={download}
          downloaded={downloaded}
          setDownloaded={setDownloaded}
          isInvoiceExportOpen={isInvoiceExportOpen}
          onInvoiceExportClose={onInvoiceExportClose}
        />
        <Box className='repor_mod_min_bx'>
          <Modal isOpen={isgenerateReporttOpen} onClose={ongenerateReporttClose}>
            <ModalOverlay />
            <ModalContent className='report_mod_contin'>
              <Image
                src='/img/pop_sedow_top.png'
                alt=''
                className='pop_sedow_top'
              />
              <Image
                src='/img/pop_sedow_bot.png'
                alt=''
                className='pop_sedow_bot'
              />
              <ModalHeader className='repor_mod_head'>
                <Image
                  src='/img/logo_light.svg'
                  alt=''
                  className='sidebar_logo'
                />
                <Text className='top_riport_P'> Report: #0131 </Text>

              </ModalHeader>
              {/* <ModalCloseButton /> */}
              <ModalBody>
                <Box className='rport_data_bx'>
                  <Image src='/img/riport_pop_bxtp_img.png' alt='' className='riport_pop_bxtp_img' />
                  <Image src='/img/riport_pop_bxbtm_img.png' alt='' className='riport_pop_bxbtm_img' />
                  <Box className='rport_data_bx_contin'>
                    <Box className='rport_data_lft_contin'>
                      <Box className='adrs_flex_bx'>
                        <Image src='/img/name_ic.svg' alt='' className='' />
                        <span>Name: </span>
                        <Text>Block Leader inc.</Text>
                      </Box>
                      <Box className='adrs_flex_bx'>
                        <Image src='/img/address.svg' alt='' className='' />
                        <span>Address: </span>
                        <Text>720 South Sapodilla, Suite 506, West Palm Beach, FL 33401</Text>
                      </Box>
                      <Box className='adrs_flex_bx'>
                        <Image src='/img/email.svg' alt='' className='' />
                        <span>Email: </span>
                        <Text>accounts@blockleader.io</Text>
                      </Box>
                      <Box className='adrs_flex_bx adrs_flex_bx_2v'>
                        <Image src='/img/wallet.svg' alt='' className='' />
                        <span>Wallet Address: </span>
                        <Text>0x6747fhr7262538dfgrd4hkrhjd</Text>
                      </Box>
                    </Box>
                    <Box className='rport_data_righ_data'>
                      <Box>
                        <Text className='dat_tip_p'>Date: 08/15/2022</Text>
                        <Flex className='def_dat_flx'>
                          <Image src="/img/calendar.svg" alt="" />
                          <span>Due Date:</span>
                          <Text> 08/22/2022</Text>
                        </Flex>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className='dat_v2e_btm'>
                  <Flex className='def_dat_flx'>
                    <Image src="/img/calendar.svg" alt="" />
                    <span>Time Period:  </span>
                    <Text> 08/06/ 2022 - 08/14/2022</Text>
                  </Flex>
                </Box>
                <Box className='tbl_box_min_swap'>
                  <Box className='brd_tbl'>
                    <Text className='swap_had_P'>Swapsicle</Text>
                    <TableContainer>
                      <Table variant='striped' colorScheme='teal'>
                        <Thead className='t_had_bx'>
                          <Tr>
                            <Th colSpan="2">Task name will be here</Th>
                            <Th className='txt_end'>Task Total:</Th>
                            <Th>5.30</Th>
                            <Th>$ 224.63</Th>
                          </Tr>
                        </Thead>
                        <Tbody className='t_body'>
                          <Tr className='hight_34'>
                            <Td>Role</Td>
                            <Td>Level</Td>
                            <Td></Td>
                            <Td>Hours</Td>
                            <Td>Cost</Td>
                          </Tr>
                          <Tr className='hight_64'>
                            <Td>Web 3</Td>
                            <Td>Level 2</Td>
                            <Td></Td>
                            <Td>2.45</Td>
                            <Td>$42.25</Td>
                          </Tr>
                          <Tr className='hight_64'>
                            <Td>Web 3</Td>
                            <Td>Level 3</Td>
                            <Td></Td>
                            <Td>2.45</Td>
                            <Td>$42.25</Td>
                          </Tr>
                          <Tr className='hight_64'>
                            <Td>2D Design</Td>
                            <Td>Level 3</Td>
                            <Td></Td>
                            <Td>2.45</Td>
                            <Td>$42.25</Td>
                          </Tr>
                          <Tr className='hight_64'>
                            <Td>Motion Design</Td>
                            <Td>Level 2</Td>
                            <Td></Td>
                            <Td>2.45</Td>
                            <Td>$42.25</Td>
                          </Tr>
                          <Tr className='hight_64'>
                            <Td>Front - end</Td>
                            <Td>Level 2</Td>
                            <Td></Td>
                            <Td>2.45</Td>
                            <Td>$42.25</Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>

                    <TableContainer>
                      <Table variant='striped' colorScheme='teal'>
                        <Thead className='t_had_bx'>
                          <Tr>
                            <Th colSpan="2">Task name will be here</Th>
                            <Th className='txt_end'>Task Total:</Th>
                            <Th>5.30</Th>
                            <Th>$ 224.63</Th>
                          </Tr>
                        </Thead>
                        <Tbody className='t_body t_body_v2'>
                          <Tr className='hight_34'>
                            <Td>Role</Td>
                            <Td>Level</Td>
                            <Td></Td>
                            <Td>Hours</Td>
                            <Td>Cost</Td>
                          </Tr>
                          <Tr className='hight_64'>
                            <Td>Web 3</Td>
                            <Td>Level 2</Td>
                            <Td></Td>
                            <Td>2.45</Td>
                            <Td>$42.25</Td>
                          </Tr>
                          <Tr className='hight_64'>
                            <Td>Web 3</Td>
                            <Td>Level 3</Td>
                            <Td></Td>
                            <Td>2.45</Td>
                            <Td>$42.25</Td>
                          </Tr>
                          <Tr className='hight_64'>
                            <Td>2D Design</Td>
                            <Td>Level 3</Td>
                            <Td></Td>
                            <Td>2.45</Td>
                            <Td>$42.25</Td>
                          </Tr>
                          <Tr className='hight_64'>
                            <Td>Motion Design</Td>
                            <Td>Level 2</Td>
                            <Td></Td>
                            <Td>2.45</Td>
                            <Td>$42.25</Td>
                          </Tr>
                          <Tr className='hight_64'>
                            <Td>Front - end</Td>
                            <Td>Level 2</Td>
                            <Td></Td>
                            <Td>2.45</Td>
                            <Td>$42.25</Td>
                          </Tr>
                          <Tr className='hight_64 last_foter'>
                            <Td></Td>
                            <Td></Td>
                            <Td className='txt_end'>Total:</Td>
                            <Td>5.30</Td>
                            <Td>$ 224.63</Td>
                          </Tr>
                        </Tbody>

                      </Table>
                    </TableContainer>
                  </Box>

                </Box>
              </ModalBody>

              {/* <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick={onClose}>
                  Close
                </Button>
                <Button variant='ghost'>Secondary Action</Button>
              </ModalFooter> */}
            </ModalContent>
          </Modal>
        </Box>
      </LayoutTwo>
    </>
  )
}
