import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Heading,
  Text,
  Select,
  useDisclosure,
  useToast,
  CircularProgress,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../redux/Auth/action";
import { BASE_URL } from "../../services/constant";
import { useParams } from "react-router-dom";
import axios from "axios";

export default function LoginModal({ isOpen, onClose }) {
  const params = useParams();
  const { isOpen: isOpenSignupModal, onOpen: onOpenSignupModal, onClose: onCloseSignupModal } = useDisclosure();
  const { isOpen: isOpenResetModal, onOpen: onOpenResetModal, onClose: onCloseResetModal } = useDisclosure();
  const { isOpen: isOpenResetReq, onOpen: onOpenResetReq, onClose: onCloseResetReq } = useDisclosure();

  const { isOpen: isOpenResetTyp, onOpen: onOpenResetTyp, onClose: onCloseResetTyp } = useDisclosure();
  const { isOpen: isOpenResetBac, onOpen: onOpenResetBac, onClose: onCloseResetBac } = useDisclosure();

  const openManageModal = () => {
    onOpenResetModal()
    if (isOpenSignupModal) {
      onCloseSignupModal()
    }
  }

  const openResetModal = () => {
    onOpenResetReq()
    if (isOpenResetModal) {
      onCloseResetModal()
    }
  }

  // const openResetModal = () => {
  //   onOpenResetTyp()
  //   if (isOpenResetModal) {
  //     onCloseResetModal()
  //   }
  // }

  useEffect(() => {
    if (params?.id) {
      onOpenResetTyp()
    }
  }, [params])

  const toast = useToast()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const emailRef = useRef < HTMLInputElement > (null)
  const passwordRef = useRef < HTMLInputElement > (null)

  useEffect(() => {
    emailRef.current && emailRef.current.focus()
    // const credential = localStorage.getItem('bluClientUser')
    // if (credential) {
    //   setEmail(JSON.parse(credential).email)
    //   setPassword(JSON.parse(credential).password)
    // }
  }, [])

  const handleLogin = () => {
    setIsLoading(true)
    dispatch(loginUser(email, password)).then(() => {
      setIsLoading(false)
      navigate('/dashboard');
    }
    ).catch((e) => {
      setIsLoading(false)
      toast({
        title: 'You are not logged in.',
        description: "Invalid username or password.",
        status: 'warning',
        duration: 9000,
        isClosable: true,
      })
    })
  }

  const setFocusPassword = (e) => {
    if (e.key === 'Enter') {
      passwordRef.current && passwordRef.current.focus()
    }
  }

  const hadleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  }

  const handleResetPassword = async () => {
    try {
      await axios({
        method: 'post',
        url: `${BASE_URL}/api/userClient/forgotPassword`,
        data: {email}
      });
      openResetModal()
    } catch (error) {
      console.log(error)
      toast({
        title: 'Something went wrong!',
        description: error?.response?.data?.message || "Failed to find that email!",
        status: 'warning',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  const handleResetSubmit = async () => {
    try {
      if (password !== confirmPassword) {
        toast({
          title: 'Confirmation password is not matched!',
          description: "",
          status: 'warning',
          duration: 9000,
          isClosable: true,
        })

        return
      }
      await axios({
        method: 'post',
        url: `${BASE_URL}/api/userClient/forgotPasswordUpdate`,
        data: { resetToken: params.id, password}
      });
      onOpenResetBac()
      onCloseResetTyp()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Box className="login_popup">
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent className="login_mdlcntnt">
            <ModalBody>
              <Box className="lgn_innr_html">
                <Box className="txt_cntnt">
                  <Heading as="h4">
                    CLIENT <span>LOGIN</span>
                  </Heading>
                  <Text>Login for Support Tickets and Requests</Text>
                </Box>
                <Box className="inpt_bx">
                  {/* inpt_bx_v2_alt */}
                  <Box className="input_box">
                    <input
                      type="text"
                      placeholder='Email'
                      value={email}
                      useRef={emailRef}
                      onChange={e => setEmail(e.target.value)}
                      onKeyPress={setFocusPassword}
                    />
                  </Box>
                  <Box className="input_box">
                    <input
                      type="password"
                      placeholder='Password'
                      value={password}
                      useRef={passwordRef}
                      onChange={e => setPassword(e.target.value)}
                      onKeyPress={hadleKeyPress}
                    />
                  </Box>
                </Box>
                {/* <Text className="opps_red_p">Opps...something wrong, please try again</Text> */}
                {/* <Box className="option_box">
                  <Button onClick={onOpenSignupModal}>Sign Up </Button>
                  <Text> if you now have account</Text>
                </Box> */}
                <Box className="login_btn_bx">
                  <Button className="pop_login_btn" onClick={handleLogin}>
                    {isLoading ? <CircularProgress isIndeterminate size='24px' /> : 'Login'}
                  </Button>
                </Box>
                <Button className="forg_btn_pop" onClick={openManageModal}>Forgot password?</Button>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
      <Box className="signup_popup">
        <Modal isOpen={isOpenSignupModal} onClose={onCloseSignupModal}>
          <ModalOverlay />
          <ModalContent className="signup_mdlcntn">
            <ModalBody>
              <Box className="signup_innr_html">
                <Box className="back_and_close_box">
                  <Button onClick={onCloseSignupModal}>
                    <Image src="/img/popup_back_ic.svg" alt="" />
                    Back
                  </Button>
                  <Button onClick={onCloseSignupModal}>
                    close
                    <Image src="/img/popup_close_ic.svg" alt="" />
                  </Button>
                </Box>
                <Box className="txt_cntnt">
                  <Heading as="h4">Sign Up</Heading>
                </Box>
                <Box className="inpt_bx">
                  <Box className="input_box">
                    <input type="text" placeholder="Name" />
                  </Box>
                  <Box className="input_box">
                    <input type="text" placeholder="Email" />
                  </Box>
                  <Box className="input_box">
                    <input type="password" placeholder="Password" />
                  </Box>
                  <Box className="input_box">
                    <input type="password" placeholder="Confirm Password" />
                  </Box>
                  <Box className="input_box slct_box">
                    <Select placeholder="Project">
                      <option value="option1">Option 1</option>
                      <option value="option2">Option 2</option>
                      <option value="option3">Option 3</option>
                    </Select>
                  </Box>
                </Box>
                <Box className="signup_btn_bx">
                  <Button className="pop_signup_btn">Sign Up</Button>
                </Box>
                <Box className="option_box">
                  <Button onClick={onCloseSignupModal}>Login</Button>
                  <Text>if you already have account</Text>
                </Box>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
      {/* 11111 */}
      <Box className="login_popup">
        <Modal isOpen={isOpenResetModal} onClose={onCloseResetModal} isCentered>
          <ModalOverlay />
          <ModalContent className="login_mdlcntnt">
            <ModalBody>
              <Box className="lgn_innr_html">
                <Box className="txt_cntnt">
                  <Heading as="h4">
                  RESET  <span>request</span>
                  </Heading>
                  <Text>type your account email</Text>
                </Box>
                <Box className="inpt_bx">
                  <Box className="input_box">
                    <input
                      type="text"
                      placeholder='Email'
                      value={email}
                      useRef={emailRef}
                      onChange={e => setEmail(e.target.value)}
                      onKeyPress={setFocusPassword}
                    />
                  </Box>
                  {/* <Box className="input_box">
                    <input
                      type="password"
                      placeholder='Password'
                      value={password}
                      useRef={passwordRef}
                      onChange={e => setPassword(e.target.value)}
                      onKeyPress={hadleKeyPress}
                    />
                  </Box> */}
                </Box>
                
                {/* <Box className="option_box">
                  <Button onClick={onOpenSignupModal}>Sign Up </Button>
                  <Text> if you now have account</Text>
                </Box> */}
                {/* <Box className="login_btn_bx">
                  <Button className="pop_login_btn" onClick={handleLogin}>
                    {isLoading ? <CircularProgress isIndeterminate size='24px' /> : 'Login'}
                  </Button>
                </Box> */}
                <Box className="rese_btn_box">
                  <Button className="def_cancel_ab" onClick={onCloseResetModal}>Cancel</Button>
                  <Button className="def_remove_ab" onClick={handleResetPassword}>Reset Password</Button>
                </Box>
                {/* <Button className="forg_btn_pop">Forgot password?222</Button> */}
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>

      {/* 22222 */}
      <Box className="login_popup">
        <Modal isOpen={isOpenResetReq} onClose={onCloseResetReq}>
          <ModalOverlay />
          <ModalContent className="login_mdlcntnt">
            <ModalBody>
              <Box className="lgn_innr_html">
                <Box className="txt_cntnt txt_cntnt_v2">
                  <Heading as="h4">
                  RESET <span>request</span>
                  </Heading>
                  <Text>type your account email</Text>
                </Box>
                <Text className="res_grin_p">Reset password email sent! </Text>
                {/* <Box className="inpt_bx">
                  <Box className="input_box">
                    <input
                      type="text"
                      placeholder='Email'
                      value={email}
                      useRef={emailRef}
                      onChange={e => setEmail(e.target.value)}
                      onKeyPress={setFocusPassword}
                    />
                  </Box>
                  <Box className="input_box">
                    <input
                      type="password"
                      placeholder='Password'
                      value={password}
                      useRef={passwordRef}
                      onChange={e => setPassword(e.target.value)}
                      onKeyPress={hadleKeyPress}
                    />
                  </Box>
                </Box> */}
                
                {/* <Box className="option_box">
                  <Button onClick={onOpenSignupModal}>Sign Up </Button>
                  <Text> if you now have account</Text>
                </Box> */}
                {/* <Box className="login_btn_bx">
                  <Button className="pop_login_btn" onClick={handleLogin}>
                    {isLoading ? <CircularProgress isIndeterminate size='24px' /> : 'Login'}
                  </Button>
                </Box> */}
                <Box className="rese_btn_box">
                  <Button className="pop_login_btn" onClick={onCloseResetReq}>Back  to Login</Button>
                </Box>
                {/* <Button className="forg_btn_pop">Forgot password?222</Button> */}
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>

      {/* 33333333 */}
      <Box className="login_popup">
        <Modal isOpen={isOpenResetReq} onClose={onCloseResetReq}>
          <ModalOverlay />
          <ModalContent className="login_mdlcntnt">
            <ModalBody>
              <Box className="lgn_innr_html">
                <Box className="txt_cntnt txt_cntnt_v2">
                  <Heading as="h4">
                  RESET <span>request</span>
                  </Heading>
                  <Text>type your account email</Text>
                </Box>
                <Text className="res_grin_p">Reset password email sent! </Text>
                {/* <Box className="inpt_bx">
                  <Box className="input_box">
                    <input
                      type="text"
                      placeholder='Email'
                      value={email}
                      useRef={emailRef}
                      onChange={e => setEmail(e.target.value)}
                      onKeyPress={setFocusPassword}
                    />
                  </Box>
                  <Box className="input_box">
                    <input
                      type="password"
                      placeholder='Password'
                      value={password}
                      useRef={passwordRef}
                      onChange={e => setPassword(e.target.value)}
                      onKeyPress={hadleKeyPress}
                    />
                  </Box>
                </Box> */}
                
                {/* <Box className="option_box">
                  <Button onClick={onOpenSignupModal}>Sign Up </Button>
                  <Text> if you now have account</Text>
                </Box> */}
                {/* <Box className="login_btn_bx">
                  <Button className="pop_login_btn" onClick={handleLogin}>
                    {isLoading ? <CircularProgress isIndeterminate size='24px' /> : 'Login'}
                  </Button>
                </Box> */}
                <Box className="rese_btn_box rese_btn_box_v2c">
                  <Button className="pop_login_btn" onClick={onCloseResetReq}>Back  to Login</Button>
                  
                </Box>
                {/* <Button className="forg_btn_pop">Forgot password?222</Button> */}
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>

      {/* 4444444 */}
      <Box className="login_popup">
        <Modal isOpen={isOpenResetTyp} onClose={onCloseResetTyp}>
          <ModalOverlay />
          <ModalContent className="login_mdlcntnt">
            <ModalBody>
              <Box className="lgn_innr_html">
                <Box className="txt_cntnt txt_cntnt_v2">
                  <Heading as="h4">
                  password <span>reset</span>
                  </Heading>
                  <Text>type your password</Text>
                </Box>                
                <Box className="inpt_bx inpt_bx_v2_alt_eis">
                  <Box className="input_box">
                    <input
                      type="password"
                      placeholder='Password'
                      value={password}
                      useRef={passwordRef}
                      onChange={e => setPassword(e.target.value)}
                      onKeyPress={hadleKeyPress}
                    />
                  </Box>
                  <Box className="input_box">
                    <input
                      type="password"
                      placeholder='Confirm Password'
                      value={confirmPassword}
                      useRef={passwordRef}
                      onChange={e => setConfirmPassword(e.target.value)}
                      onKeyPress={hadleKeyPress}
                    />
                  </Box>
                  {/* <Text className="opps_red_p">Opps...something wrong, please try again</Text> */}
                </Box>
                <Box className="rese_btn_box rese_btn_box_v2">
                  <Button className="darkgrin_btn_img" onClick={onCloseResetTyp}>Cancel</Button>
                  <Button className="pop_login_btn" onClick={handleResetSubmit}>Reset Password</Button>
                </Box>
                {/* <Button className="forg_btn_pop">Forgot password?222</Button> */}
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>

      {/* 555555555 */}
      <Box className="login_popup">
        <Modal isOpen={isOpenResetBac} onClose={onCloseResetBac}>
          <ModalOverlay />
          <ModalContent className="login_mdlcntnt">
            <ModalBody>
              <Box className="lgn_innr_html">
                <Box className="txt_cntnt txt_cntnt_v2">
                  <Heading as="h4">
                  RESET <span>request</span>
                  </Heading>
                  <Text>type your password</Text>
                </Box>
                <Text className="res_grin_p">Password is reset!</Text>
                {/* <Box className="inpt_bx">
                  <Box className="input_box">
                    <input
                      type="text"
                      placeholder='Email'
                      value={email}
                      useRef={emailRef}
                      onChange={e => setEmail(e.target.value)}
                      onKeyPress={setFocusPassword}
                    />
                  </Box>
                  <Box className="input_box">
                    <input
                      type="password"
                      placeholder='Password'
                      value={password}
                      useRef={passwordRef}
                      onChange={e => setPassword(e.target.value)}
                      onKeyPress={hadleKeyPress}
                    />
                  </Box>
                </Box> */}
                
                {/* <Box className="option_box">
                  <Button onClick={onOpenSignupModal}>Sign Up </Button>
                  <Text> if you now have account</Text>
                </Box> */}
                {/* <Box className="login_btn_bx">
                  <Button className="pop_login_btn" onClick={handleLogin}>
                    {isLoading ? <CircularProgress isIndeterminate size='24px' /> : 'Login'}
                  </Button>
                </Box> */}
                <Box className="rese_btn_box rese_btn_box_v2c">
                  <Button className="pop_login_btn" onClick={() => {
                    onCloseResetBac()
                      navigate('/')
                    }}>Back  to Login</Button>
                  {/* <Button className="pop_login_btn" onClick={onOpenResetTyp}>AAAAA</Button> */}
                </Box>
                {/* <Button className="forg_btn_pop">Forgot password?222</Button> */}
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </>
  )
}
