import React, { useState } from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  Image,
  ModalFooter,
  useColorMode,
} from "@chakra-ui/react";
import { Contract, ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import ERC20_ABI from "../../utils/abis/erc20.json"
import transferAbi from "../../utils/abis/transfer.json"
import { INVOICE_CLIENT_STATUS, networkInfo, transferAddress, usdc, usdcDecimal } from "../../utils/constants";
import { chainIdToHexString } from "../../utils";
import { toast } from "react-toastify";
import httpStatus from "http-status";
import { BASE_URL } from "../../services/constant";
import axios from "axios";

export default function InvoiceModalPay({
  selectedInvoices,
  isInvoicePayConfirmOpen,
  onInvoicePayConfirmClose,
  getInvoices,
  onSuccessPay,
}) {


  const { colorMode, toggleColorMode } = useColorMode()
  const { account, library, chainId } = useWeb3React();
  const cancelRef = React.useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const shortenAddress = (address) => {
    return `${address.slice(0, 5)}...${address.slice(-3)}`;
  }

  const totalInvoice = (invoices) => {
    let total = 0;
    invoices?.map((invoice) => {
      total += parseFloat(invoice?.total)
    })
    return total.toFixed(2);
  }

  const changeNetworkBulk = async () => {
    const wa = window
    const { ethereum } = wa
    let chainId = 1

    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainIdToHexString(chainId) }],
      })
    } catch (switchError) {
      // eslint-disable-next-line no-shadow
      const error = JSON.parse(JSON.stringify(switchError))
      if (
        error.code === 4902 ||
        (error.code === -32603 && error?.data?.originalError.code === 4902)
      ) {
        try {
          const item = networkInfo[chainId]
          let params = {
            chainId: chainIdToHexString(chainId),
            chainName: item.label,
            rpcUrls: [item.rpcUrl],
            nativeCurrency: {
              name: item.nativeCurrency,
              symbol: item.nativeCurrency,
              decimals: 18,
            }
          };
          if (item.explorer) {
            params['blockExplorerUrls'] = [item.explorer];
          }
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              params
            ],
          })
        } catch (error) {
          toast.error(error?.message ? error?.message : error)
        }
      }
    }
  }

  const doTransferBulk = async () => {
    try {
      const signer = library.getSigner(account).connectUnchecked();
      const contract = new Contract(
        transferAddress,
        transferAbi,
        signer,
      );

      const contractUsd = new Contract(
        usdc,
        ERC20_ABI,
        signer,
      );
      const allowance = await contractUsd.allowance(
        account,
        transferAddress
      );
      // console.log("test-doTransferBulk-allowance", allowance)

      if (Number(allowance) == 0) {
        let tx = await contractUsd.approve(transferAddress, "115792089237316195423570985008687907853269984665640564039457584007913129639935");
        // console.log("test-doTransferBulk-tx", tx)
        let tx2 = await tx.wait();
        // console.log("test-doTransferBulk-tx2", tx2)
      }

      let wallets = selectedInvoices.map((e) => {
        return e.payment_methods.walletAddress
      })
      let amounts = selectedInvoices.map((e) => {
        return ethers.utils.parseUnits(e.total, usdcDecimal).toString()
      })

      let totalAmount = amounts.reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0).toString()
      // console.log("test-doTransferBulk-wallets", wallets)
      // console.log("test-doTransferBulk-amounts", amounts)
      // console.log("test-doTransferBulk-totalAmount", totalAmount)

      const mintResult = await contract.batchTransferERC20(usdc,
        wallets,
        amounts,
        totalAmount
      );
      const transferResult = await mintResult.wait();
      // console.log("test-doTransferBulk-transferResult", transferResult);
      if (transferResult?.transactionHash) {
        return { status: true, message: transferResult };
      } else {
        return { status: false, message: transferResult };
      }
    } catch (error) {
      console.error("test-doTransferBulk-error", error)
      return { status: false, message: error };
    }
  }

  const doUpdateBulk = async (invoice, hash = "") => {
    try {
      const body = {
        id: invoice?._id,
        status: INVOICE_CLIENT_STATUS.PAID,
        hash: hash,
      };
      const bluClientToken = localStorage.getItem('bluClientToken');
      const response = await axios({
        method: 'PUT',
        data: body,
        url: `${BASE_URL}/api/invoice-client/client/status`,
        headers: {
          Authorization: 'Bearer ' + bluClientToken,
        },
      })
      console.log("response", response)
      const data = await response.data
      if (response.status == httpStatus.OK) {
        if (data?.success) {
          return { status: true, message: data?.message };
        }
        else {
          return { status: false, message: data?.message };
        }
      }
      else {
        return { status: false, message: 'Internal server error' };
      }
    } catch (error) {
      console.error("Error : ", error)
      return { status: false, message: error };
    }
  };

  const onPayBulk = async () => {
    await changeNetworkBulk();
    setIsProcessing(true)

    if (!selectedInvoices) {
      toast.error("There is no invoice.");
      return;
    }

    try {
      setIsProcessing(true);
      const transfer = await doTransferBulk();
      // let transfer = {
      //   status: 200,
      //   message: {
      //     transactionHash: "abcd"
      //   }
      // }
      if (transfer.status) {
        const hash = transfer?.message?.transactionHash;

        let messages = [];
        let totalUpdated = 0;
        for (const invoice of selectedInvoices) {
          const update = await doUpdateBulk(invoice, hash);
          if (update.status) {
            totalUpdated++;
          }
          else {
            messages.push(update.message)
          }
        }

        if (totalUpdated == selectedInvoices.length) {
          toast.success("Payment success\n" + hash);
          getInvoices();
          onInvoicePayConfirmClose();
          onSuccessPay();
          setIsProcessing(false);
        } else {
          for (const msg of messages) {
            toast.error(msg);
          }
        }
      } else {
        toast.error(transfer?.message?.message);
      }
    } catch (error) {
      // console.error("Error : ", error)
      toast.error(error?.message ? error?.message : error);
    } finally {
      setIsProcessing(false);
    }
  }

  // const changeNetworkSingle = async () => {
  //   const wa = window
  //   const { ethereum } = wa

  //   let transferChain = invoice?.payment_methods.blockchain;
  //   let currentChain = 1
  //   if (transferChain == 'ERC20') {
  //     currentChain = 1
  //   }

  //   if (currentChain != chainId) {
  //     try {
  //       console.log("switch chain")
  //       await ethereum.request({
  //         method: 'wallet_switchEthereumChain',
  //         params: [{ chainId: chainIdToHexString(currentChain) }],
  //       })
  //     } catch (switchError) {
  //       // eslint-disable-next-line no-shadow
  //       const error = JSON.parse(JSON.stringify(switchError))
  //       if (
  //         error.code === 4902 ||
  //         (error.code === -32603 && error?.data?.originalError.code === 4902)
  //       ) {
  //         try {
  //           const item = networkInfo[currentChain]
  //           let params = {
  //             chainId: chainIdToHexString(currentChain),
  //             chainName: item.label,
  //             rpcUrls: [item.rpcUrl],
  //             nativeCurrency: {
  //               name: item.nativeCurrency,
  //               symbol: item.nativeCurrency,
  //               decimals: 18,
  //             }
  //           };
  //           if (item.explorer) {
  //             params['blockExplorerUrls'] = [item.explorer];
  //           }
  //           await ethereum.request({
  //             method: 'wallet_addEthereumChain',
  //             params: [
  //               params
  //             ],
  //           })
  //         } catch (error) {
  //           toast.error(error?.message ? error?.message : error)
  //         }
  //       }
  //     }
  //   }
  // }

  // const doTransferSingle = async () => {
  //   try {
  //     let token = TOKEN_ADDRESS[invoice?.payment_methods.token];
  //     token = usdc;
  //     // let value = ethers.utils.parseEther(invoice?.total);
  //     let value = ethers.utils.parseUnits(invoice?.total, usdcDecimal);
  //     let toAddress = invoice?.payment_methods.walletAddress;

  //     // wavax // account 1
  //     // token = "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7";
  //     // toAddress = "0x2D9187509f6a7896E303552D17413a5F26da9C5b";
  //     // value = ethers.utils.parseEther("0.001");

  //     const signer = library.getSigner(account).connectUnchecked();
  //     const contract = new Contract(token, ERC20_ABI, signer);

  //     let resp = await contract.transfer(toAddress, value);
  //     const transferResp = await resp.wait();
  //     if (transferResp?.transactionHash) {
  //       return { status: true, message: transferResp };
  //     } else {
  //       return { status: false, message: transferResp };
  //     }
  //   } catch (error) {
  //     return { status: false, message: error };
  //   }
  // };

  // const doUpdateSingle = async (hash = "") => {
  //   try {
  //     const body = {
  //       id: invoice?._id,
  //       status: INVOICE_STATUS.PAID,
  //       hash: hash,
  //     };
  //     const response = await fetchPutApi("api/invoice/admin/status", body, true);
  //     if (response?.status == httpStatus.OK) {
  //       let data = await response.json();
  //       if (data?.success) {
  //         return { status: true, message: data?.message };
  //       }
  //       else {
  //         return { status: false, message: data?.message };
  //       }
  //     }
  //     else {
  //       return { status: false, message: 'Internal server error' };
  //     }
  //   } catch (error) {
  //     return { status: false, message: error };
  //   }
  // };

  // const onPaySingle = async () => {
  //   await changeNetwork()

  //   if (!invoice) {
  //     toast.error("Please select invoice!");
  //     return;
  //   }

  //   try {
  //     setIsProcessing(true);
  //     const transfer = await doTransferSingle();
  //     if (transfer.status) {
  //       const hash = transfer?.message?.transactionHash;
  //       const update = await doUpdateSingle(hash);
  //       if (update.status) {
  //         toast.success("Payment success\n" + hash);

  //         invoice.status = INVOICE_STATUS.PAID;
  //         getInvoices();
  //         onInvoicePayConfirmClose();
  //         setIsProcessing(false);
  //       } else {
  //         toast.error(update.message);
  //       }
  //     } else {
  //       toast.error(transfer?.message?.message);
  //     }
  //   } catch (error) {
  //     toast.error(error?.message ? error?.message : error);
  //   } finally {
  //     setIsProcessing(false);
  //   }
  // };

  return (
    <>
      <Box className="modal_main">
        <Modal
          isOpen={isInvoicePayConfirmOpen}
          onClose={onInvoicePayConfirmClose}
          isCentered="false">
          <ModalOverlay />
          <ModalContent className="modal_report Payment_Confirmationab Invoice_in_ab">
            <ModalHeader className="moda_hed">
              <Heading as="h2" className='invos_head'>Payment Confirmation</Heading>
              <Text>Please confirm your payment</Text>
            </ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody className="modal_body Payment_Confirmationab_body">
              <TableContainer>
                <Table variant="simple" className='client_report_tbl client_report_tbl_task_only invos_tbl_ab'>
                  <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2 w-80'>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new t_had'>
                      <Td className="width_onl_10">Inv.Number</Td>
                      <Td className="width_onl_10">Wallet</Td>
                      <Td className="width_onl_10">Token</Td>
                      <Td className='brd_non'>Chain</Td>
                      <Td className='brd_non'>Amount</Td>
                    </Tr>
                    {
                      selectedInvoices?.map((invoice, index) => (
                        <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'
                          key={index}
                        >
                          <Td className="width_onl_10">{invoice?.invoice_ref}</Td>
                          <Td className="width_onl_10 for_max_wi">{shortenAddress(invoice?.payment_methods.walletAddress)}
                            <Button className="bax_copy_btn_pernt">
                              {colorMode === "light" ? (
                                <Image src="/img/bxs_copy-alt.svg" alt="" />
                              ) : (
                                <Image src="/img/bxs_copy-alt_dark.svg" alt="" />
                              )}{" "}
                            </Button>
                          </Td>
                          <Td className="width_onl_10 weight400">{invoice?.payment_methods.token}</Td>
                          <Td className='brd_non'>{invoice?.payment_methods.blockchain}</Td>
                          <Td className='brd_non'>{`$ ${invoice?.total}`}</Td>
                        </Tr>
                      ))
                    }
                    {/* <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'>
                      <Td className="width_onl_10">#5465</Td>
                      <Td className="width_onl_10 for_max_wi">0x6456...5aw4
                        <Button className="bax_copy_btn_pernt">
                          {colorMode === "light" ? (
                            <Image src="/img/bxs_copy-alt.svg" alt="" />
                          ) : (
                            <Image src="/img/bxs_copy-alt_dark.svg" alt="" />
                          )}{" "}
                        </Button>
                      </Td>
                      <Td className="width_onl_10 weight400">USDC</Td>
                      <Td className='brd_non'>ERC20</Td>
                      <Td className='brd_non'>$ 680</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'>
                      <Td className="width_onl_10">#5465</Td>
                      <Td className="width_onl_10 for_max_wi">0x6456...5aw4
                        <Button className="bax_copy_btn_pernt">
                          {colorMode === "light" ? (
                            <Image src="/img/bxs_copy-alt.svg" alt="" />
                          ) : (
                            <Image src="/img/bxs_copy-alt_dark.svg" alt="" />
                          )}{" "}
                        </Button>
                      </Td>
                      <Td className="width_onl_10 weight400">USDC</Td>
                      <Td className='brd_non'>ERC20</Td>
                      <Td className='brd_non'>$ 680</Td>
                    </Tr>
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'>
                      <Td className="width_onl_10">#5465</Td>
                      <Td className="width_onl_10 for_max_wi">0x6456...5aw4
                        <Button className="bax_copy_btn_pernt">
                          {colorMode === "light" ? (
                            <Image src="/img/bxs_copy-alt.svg" alt="" />
                          ) : (
                            <Image src="/img/bxs_copy-alt_dark.svg" alt="" />
                          )}{" "}
                        </Button>
                      </Td>
                      <Td className="width_onl_10 weight400">USDC</Td>
                      <Td className='brd_non'>ERC20</Td>
                      <Td className='brd_non'>$ 680</Td>
                    </Tr> */}
                    <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new t_foot'>
                      <Td className="width_onl_10"></Td>
                      <Td className="width_onl_10"></Td>
                      <Td className="width_onl_10"></Td>
                      <Td className='for_totl_color brd_non'>Total:</Td>
                      <Td className='for_grin_color brd_non'>{`$ ${totalInvoice(selectedInvoices)}`}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>

            </ModalBody>
            <ModalFooter className="foter_this_modl invoice_foot">
              <Box className='rejac_cnc_btn_bx'>
                <Button colorScheme='blue' mr={3} onClick={onInvoicePayConfirmClose} className="back_btn">
                  Cancel
                </Button>
              </Box>
              <Button
                className="close_btn"
                onClick={onPayBulk}
                disabled={isProcessing}
              >
                Confirm & Paid
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
}
