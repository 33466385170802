import React from "react";
import { Box, Image, useColorMode, Button } from "@chakra-ui/react";


const Pagination = ({
  page,
  totalPages,
  handlePagination,
}) => {
  const { colorMode } = useColorMode();

  return (
    <>
      <Box className="pagination_box ">
        {page !== 1 && (
          <Button
            className="prew_next_p_btn prew_p_btn"
            onClick={() => handlePagination(page - 1)}
          >
            {colorMode === 'light' ? (
              <Image src="/img/play_ic.svg" alt="" className="play_ic" />
            ) : (
              <Image src="/img/play_ic.svg" alt="" className="play_ic" />
            )}
          </Button>
        )}
        <Button className={`${page === 1 ? 'active' : ''}`} onClick={() => handlePagination(1)}>{1}</Button>
        {page > 3 && <Box>...</Box>}
        {page === totalPages && totalPages > 3 && (
          <Button onClick={() => handlePagination(page - 1)}>{page - 2}</Button>
        )}
        {page > 2 && (
          <Button onClick={() => handlePagination(page - 1)}>{page - 1}</Button>
        )}
        {page !== 1 && page !== totalPages && (
          <Button className="one_btn active" onClick={() => handlePagination(page)}>{page}</Button>
        )}
        {page < totalPages - 1 && (
          <Button
            onClick={() => handlePagination(page + 1)}
          >
            {page + 1}
          </Button>
        )}
        {page === 1 && totalPages > 3 && (
          <Button
            onClick={() => handlePagination(page + 2)}
          >
            {page + 2}
          </Button>
        )}
        {page < totalPages - 2 && <Box>...</Box>}
        {totalPages > 1 && (
          <Button
            className={`${page === totalPages ? 'active' : ''}`}
            onClick={() => handlePagination(totalPages)}
          >
            {totalPages}
          </Button>
        )}
        {page !== totalPages && (
          <Button
            className="prew_next_p_btn next_p_btn"
            onClick={() => handlePagination(page + 1)}
          >
            {colorMode === 'light' ? (
              <Image src="/img/play_ic.svg" alt="" className="play_ic" />
            ) : (
              <Image src="/img/play_ic.svg" alt="" className="play_ic" />
            )}
          </Button>
        )}
      </Box>
    </>
  )
}

export default Pagination