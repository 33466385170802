import React from "react";
import {
  Box,
  Image,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useColorMode,
  Checkbox,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { printInvoiceButton } from "../../utils";
import { INVOICE_ACTION, INVOICE_CLIENT_STATUS } from "../../utils/constants";

export default function InvoiceTable({ invoices, isAdmin, loading, setInvoice, selectedInvoices, onSelectInvoice, selectAll, onSelectAll }) {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <>
      <Box className="userlist_table_prnt INvoices_min_tabl_box">
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr className='111111111'>
                <Th >
                  <Checkbox className='def_check_box'
                    isChecked={selectAll}
                    onChange={(e) => { onSelectAll() }}
                  />
                </Th>
                <Th >Invoice Ref</Th>
                <Th className={selectAll ? 'activeBord' : ''} >User</Th>
                <Th>Date Invoice Created</Th>
                <Th>Payment Method</Th>
                <Th>Payment Amount</Th>
                <Th className='align_centar'>Status</Th>
                <Th>Invoice</Th>
              </Tr>
            </Thead>
            <Tbody>
              {invoices?.length > 0 ? invoices?.map((invoice, index) => {
                let isCheck = false;
                let checkedValue = selectedInvoices.filter((item) => item._id == invoice._id)
                isCheck = checkedValue?.length > 0;
                return (
                  <Tr className={isCheck ? 'activeBord' : ''} key={index}>
                    <Td >
                      <span className={isCheck ? 'for_data_che_bx_actvin' : 'For_zindex'}>
                        <Checkbox
                          className="def_check_box for_mrg"
                          isChecked={isCheck}
                          disabled={invoice.status == INVOICE_CLIENT_STATUS.PAID || invoice.status == INVOICE_CLIENT_STATUS.REJECTED}
                          onChange={(e) => onSelectInvoice(invoice, e.target.checked)}
                        />
                        {/* <Checkbox onChange={handleClick} className='def_check_box for_mrg' /> */}
                      </span>
                    </Td>
                    <Td >
                      <Box className=' for_bord_riad'>{invoice?.invoice_ref}</Box>
                    </Td>
                    <Td>
                      <Box>{invoice?.invoice_from?.firstName + ' ' + invoice?.invoice_from?.lastName}</Box>
                    </Td>
                    <Td>
                      <Box>{format(new Date(invoice?.createdAt), "MM.dd.yyyy")}</Box>
                    </Td>
                    <Td>
                      <Box>
                        {invoice?.payment_methods?.token} ({invoice?.payment_methods?.blockchain})
                        {' - '}
                        {invoice?.payment_methods?.walletAddress}
                      </Box>
                    </Td>
                    <Td>
                      <Box><span className='for_grin_color'>{invoice?.total} USD</span></Box>
                    </Td>
                    <Td>
                      {printInvoiceButton(invoice?.status)}
                    </Td>
                    <Td>
                      <Box>
                        <Box className="clctn_last_flex clctn_last_flex_ab">
                          {" "}
                          <Button
                            disabled={invoice.status != INVOICE_CLIENT_STATUS.PAID}
                            onClick={() => window.open(`https://etherscan.io/tx/${invoice?.hash}`, '_blank')}
                          >
                            {colorMode === "light" ? (
                              <Image src="/img/ethersca_ic.svg" alt="" className="pncl_ic" />
                            ) : (
                              <Image src="/img/ethersca_ic.svg" alt="" className="pncl_ic" />
                            )}
                          </Button>
                          <Button
                            onClick={() => setInvoice(invoice, INVOICE_ACTION.EXPORT)}
                          >
                            {colorMode === "light" ? (
                              <Image src="/img/pdf_img_txt.svg" alt="" className="pncl_ic" />
                            ) : (
                              <Image src="/img/pdf_img_txt.svg" alt="" className="pncl_ic" />
                            )}
                          </Button>
                          <Button className='dol_sin_btn'
                            onClick={() => setInvoice(invoice, INVOICE_ACTION.DETAIL)}
                          >
                            {colorMode === "light" ? (
                              <Image src="/img/dol_sin.svg" alt="" className="pncl_ic" />
                            ) : (
                              <Image src="/img/dol_sin.svg" alt="" className="pncl_ic" />
                            )}
                          </Button>
                        </Box>
                      </Box>
                    </Td>
                  </Tr>
                )
              }) : (
                <Tr>
                  <Td colSpan={8}>{loading ? "Loading..." : "No data"}</Td>
                </Tr>
              )}

              {/* <Tr className={isActive ? 'activeBord' : ''}>
                <Td >
                  <span className={isActive ? 'for_data_che_bx_actvin' : 'For_zindex'}><Checkbox onChange={handleClick} className='def_check_box for_mrg' /></span>
                </Td>
                <Td >
                  <Box className=' for_bord_riad'>000022-2022</Box>
                </Td>
                <Td>
                  <Box>Jen User</Box>
                </Td>
                <Td>
                  <Box>06.06.2022</Box>
                </Td>
                <Td>
                  <Box>ETH (ERC20) - 0x123</Box>
                </Td>
                <Td>
                  <Box><span className='for_grin_color'>650.00 USD</span></Box>
                </Td>
                <Td>
                  <Box className='align_centar pendin_bg_color'>Pending</Box>
                </Td>
                <Td>
                  <Box>
                    <Box className="clctn_last_flex clctn_last_flex_ab">
                      {" "}
                      <Button>
                        {colorMode === "light" ? (
                          <Image
                            src="/img/ethersca_ic.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        ) : (
                          <Image
                            src="/img/ethersca_ic.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        )}
                      </Button>
                      <Button>
                        {colorMode === "light" ? (
                          <Image
                            src="/img/pdf_img_txt.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        ) : (
                          <Image
                            src="/img/pdf_img_txt_dark.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        )}
                      </Button>
                      <Button className='dol_sin_btn'>
                        {colorMode === "light" ? (
                          <Image
                            src="/img/dol_sin.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        ) : (
                          <Image
                            src="/img/dol_sin.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Td>
              </Tr>
              <Tr className={isActive ? 'activeBord' : ''}>
                <Td >
                  <span className={isActive ? 'for_data_che_bx_actvin' : 'For_zindex'}><Checkbox onChange={handleClick} className='def_check_box for_mrg' /></span>
                </Td>
                <Td>
                  <Box className='for_bord_riad'>000022-2022</Box>
                </Td>
                <Td>
                  <Box>Jen User</Box>
                </Td>
                <Td>
                  <Box>06.06.2022</Box>
                </Td>
                <Td>
                  <Box>ETH (ERC20) - 0x123</Box>
                </Td>
                <Td>
                  <Box><span className='for_grin_color'>650.00 USD</span></Box>
                </Td>
                <Td>
                  <Box className='align_centar Paid_bg_color'>Paid</Box>
                </Td>
                <Td>
                  <Box>
                    <Box className="clctn_last_flex clctn_last_flex_ab">
                      {" "}
                      <Button>
                        {colorMode === "light" ? (
                          <Image
                            src="/img/ethersca_ic.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        ) : (
                          <Image
                            src="/img/ethersca_ic.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        )}
                      </Button>
                      <Button>
                        {colorMode === "light" ? (
                          <Image
                            src="/img/pdf_img_txt.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        ) : (
                          <Image
                            src="/img/pdf_img_txt_dark.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        )}
                      </Button>
                      <Button className='dol_sin_btn'>
                        {colorMode === "light" ? (
                          <Image
                            src="/img/dol_sin.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        ) : (
                          <Image
                            src="/img/dol_sin.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Td>
              </Tr>
              <Tr className={isActive ? 'activeBord' : ''}>
                <Td >
                  <span className={isActive ? 'for_data_che_bx_actvin' : 'For_zindex'}><Checkbox onChange={handleClick} className='def_check_box for_mrg' /></span>
                </Td>
                <Td>
                  <Box className='for_bord_riad'>000022-2022</Box>
                </Td>
                <Td>
                  <Box>Jen User</Box>
                </Td>
                <Td>
                  <Box>06.06.2022</Box>
                </Td>
                <Td>
                  <Box>ETH (ERC20) - 0x123</Box>
                </Td>
                <Td>
                  <Box><span className='for_grin_color'>650.00 USD</span></Box>
                </Td>
                <Td>
                  <Box className='align_centar Rejected'>Rejected</Box>
                </Td>
                <Td>
                  <Box>
                    <Box className="clctn_last_flex clctn_last_flex_ab">
                      {" "}
                      <Button>
                        {colorMode === "light" ? (
                          <Image
                            src="/img/ethersca_ic.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        ) : (
                          <Image
                            src="/img/ethersca_ic.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        )}
                      </Button>
                      <Button>
                        {colorMode === "light" ? (
                          <Image
                            src="/img/pdf_img_txt.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        ) : (
                          <Image
                            src="/img/pdf_img_txt_dark.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        )}
                      </Button>
                      <Button className='dol_sin_btn'>
                        {colorMode === "light" ? (
                          <Image
                            src="/img/dol_sin.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        ) : (
                          <Image
                            src="/img/dol_sin.svg"
                            alt=""
                            className="pncl_ic"
                          />
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Td>
              </Tr> */}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
