import React from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";

export default function ThreeBoxSec() {
  return (
    <>
      <Box className="three_boxes_main">
        <Box className="trust_priceless_box">
          <Heading as="h3">
            TRUST IS
            <span>PRICELESS</span>
          </Heading>
        </Box>
        <Box className="two_img_and_txt">
          <Image src="/img/left_man_img.png" alt="" />
          <Box className="txt_cntnt txt_cntntas">
            <Text>
              The founding principles of
              <Box as="a" href="#">
                {" "}
                Dev Legion{" "}
              </Box>
              are honesty, precision, and unwavering integrity. We lead you on your journey to blockchain success, securing your wealth, enhancing your impact, and fortifying your legendary reputation.
            </Text>
            {/* <Text className="bttm_txt">
              We will guide you on your blockchain journey to{" "}
              <sapn> wealth, impact, </sapn> and a long-standing,{" "}
              <span> legendary reputation.</span>
            </Text> */}
          </Box>
          <Image src="/img/right_man_img.png" alt="" />
        </Box>
        <Box className="three_grid_boxes three_grid_boxesA2 ">
          <Box className="hedPent">
              <Heading as="h3" className="new_heding">EXPERIENCE THE LEGION ADVANTAGE:</Heading>
          </Box>
          <SimpleGrid columns={12} columnGap={12} rowGap={0}>
            <GridItem colSpan={[12, 12, 12, 4]}>
              <Box className="three_same_bx">
                <Image src="/img/trust_prsls_01.png" alt="" />
                <Text>Expand your business and discover new opportunities</Text>
              </Box>
            </GridItem>
            <GridItem colSpan={[12, 12, 12, 4]}>
              <Box className="three_same_bx">
                <Image src="/img/trust_prsls_02.png" alt="" />
                <Text>
                  Enjoy peace of mind with our round-the-clock support.
                </Text>
              </Box>
            </GridItem>
            <GridItem colSpan={[12, 12, 12, 4]}>
              <Box className="three_same_bx">
                <Image src="/img/trust_prsls_03.png" alt="" />
                <Text>
                  Secure early mover advantage and outshine the competition.
                </Text>
              </Box>
            </GridItem>
          </SimpleGrid>
        </Box>
      </Box>
    </>
  );
}
