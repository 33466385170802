import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Image,
  Heading,
  useColorMode,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Text,
  Flex,
} from '@chakra-ui/react'
import httpStatus from "http-status";
import { PDFExport } from '@progress/kendo-react-pdf';
import { BASE_URL } from '../../services/constant'
import { addDays, format } from 'date-fns';

export default function ClientReportExportModal({ clientId, startDate, endDate, download, downloaded, setDownloaded, isInvoiceExportOpen, onInvoiceExportClose }) {
  const { colorMode, toggleColorMode } = useColorMode()

  const cancelRef = React.useRef(null);
  const [data, setData] = useState({});
  const pdfExportComponent = useRef(null);

  const now = new Date();
  const today = format(now.getTime(), "MM/dd/yyyy");
  const dueDate = format(addDays(now, 7).getTime(), "MM/dd/yyyy");

  const getData = async () => {
    if (clientId == undefined || startDate == undefined || endDate == undefined) return;

    try {
      const bluClientToken = localStorage.getItem('bluClientToken');
      const start = new Date(startDate);
      const end = new Date(endDate);
      const response = await fetch(BASE_URL + "/api/report/clientportal/" + clientId + "?startTime=" + start.getTime() + "&endTime=" + end.getTime(), {
        headers: { 'Authorization': 'Bearer ' + bluClientToken }
      });
      if (response.status == httpStatus.OK) {
        const data = await response.json();
        const result = data.result;
        setData(result);
      }
    }
    catch (err) {
    }
  }

  useEffect(() => {
    if (clientId) {
      getData();
    }
  }, [isInvoiceExportOpen, clientId]);

  useEffect(() => {
    if (data && downloaded == 0) {
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
        setDownloaded(1)
      }
    }
  }, [data])

  return (
    <>
      <Box className='repor_mod_min_bx'>
        <Modal
          isOpen={isInvoiceExportOpen}
          onClose={onInvoiceExportClose}
        >
          <ModalOverlay />
          <ModalContent className='report_mod_contin'>
            {/* dev_report_img */}
            <PDFExport
              ref={pdfExportComponent}
              paperSize="auto"
              // paperSize="A4"
              margin={40}
              fileName={`Invoice-${data?.name}`}
              author="Blockleader Team"
            >
              <Image
                src='/img/dev_report_img.png'
                alt=''
                className='dev_report_img'
                height="486px"
                width="467px"
              />
              <Box className='fordbgclr'>
                {colorMode === 'light' ? (
                  <Image
                    src='/img/pop_sedow_top.png'
                    alt=''
                    className='pop_sedow_top'
                  />
                ) : (
                  <Image
                    src='/img/pop_sedow_top_dark.png'
                    alt=''
                    className='pop_sedow_top'
                  />
                )}
                {/* <Image
                  src='/img/pop_sedow_top.png'
                  alt=''
                  className='pop_sedow_top'
                /> */}
                {/* <Image
                  src='/img/pop_sedow_bot.png'
                  alt=''
                  className='pop_sedow_bot'
                /> */}
                <ModalHeader className='repor_mod_head'>
                  <Text className='client_p'>Client:<span> {data?.name ? data?.name : "-"}</span></Text>
                  <Box className='top_riport_P_img_bx'>
                    {colorMode === 'light' ? (
                      <Image
                        src='/img/logo_light.svg'
                        alt=''
                        className='sidebar_logo'
                      />
                    ) : (
                      <Image
                        src='/img/logo_dark.svg'
                        alt=''
                        className=''
                      />
                    )}
                    <Text className='top_riport_P'> Report: #0001 </Text>
                  </Box>
                </ModalHeader>
                <ModalBody>
                  <Box className='rport_data_bx'>
                    <Image src='/img/riport_pop_bxtp_img.png' alt='' className='riport_pop_bxtp_img' />
                    <Image src='/img/riport_pop_bxbtm_img.png' alt='' className='riport_pop_bxbtm_img' />
                    <Box className='rport_data_bx_contin'>
                      <Box className='rport_data_lft_contin'>
                        <Heading as="h3">Service Provider: </Heading>
                        <Box className='adrs_flex_bx'>
                          {colorMode === 'light' ? (
                            <Image src='/img/name_ic_light.svg' alt='' className='' />
                          ) : (
                            <Image src='/img/name_ic.svg' alt='' className='' />
                          )}
                          {/* <Image src='/img/name_ic.svg' alt='' className='' /> */}
                          <span>Name: </span>
                          <Text>Block Leader inc.</Text>
                          {/* <Text>{data?.name}</Text> */}
                        </Box>
                        <Box className='adrs_flex_bx'>
                          {colorMode === 'light' ? (
                            <Image src='/img/address_light.svg' alt='' className='' />
                          ) : (
                            <Image src='/img/address.svg' alt='' className='' />
                          )}
                          {/* <Image src='/img/address.svg' alt='' className='' /> */}
                          <span>Address: </span>
                          <Text>720 South Sapodilla, Suite 506, West Palm Beach, FL 33401</Text>
                          {/* <Text>{data?.address}</Text> */}
                        </Box>
                        <Box className='adrs_flex_bx'>
                          {colorMode === 'light' ? (
                            <Image src='/img/email_light.svg' alt='' className='' />
                          ) : (
                            <Image src='/img/email.svg' alt='' className='' />
                          )}
                          <span>Email: </span>
                          <Text>accounts@blockleader.io</Text>
                          {/* <Text>{data?.email}</Text> */}
                        </Box>
                        <Box className='adrs_flex_bx adrs_flex_bx_2v'>
                          {colorMode === 'light' ? (
                            <Image src='/img/wallet_light.svg' alt='' className='' />
                          ) : (
                            <Image src='/img/wallet.svg' alt='' className='' />
                          )}
                          <span>Wallet Address: </span>
                          <Text>-</Text>
                        </Box>
                      </Box>
                      <Box className='rport_data_righ_data'>
                        <Box>
                          <Text className='dat_tip_p'>Date: {today}</Text>
                          <Flex className='def_dat_flx'>
                            {colorMode === 'light' ? (
                              <Image src="/img/calendar_light.svg" alt="" />
                            ) : (
                              <Image src="/img/calendar.svg" alt="" />
                            )}
                            <span>Due Date:</span>
                            <Text> {dueDate}</Text>
                          </Flex>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className='dat_v2e_btm'>
                    <Flex className='def_dat_flx'>
                      {colorMode === 'light' ? (
                        <Image src="/img/calendar_light.svg" alt="" className='min19' />
                      ) : (
                        <Image src="/img/calendar.svg" alt="" className='min19' />
                      )}
                      <span>Time Period:  </span>
                      <Text> {startDate} - {endDate}</Text>
                    </Flex>
                  </Box>
                  {data?.projects?.map((itemProject) => {
                    return (
                      <Box className='tbl_box_min_swap'>
                        <Box className='brd_tbl asTble'>
                          <Text className='swap_had_P'>{itemProject.name}</Text>
                          <TableContainer>
                            <Table variant='striped' colorScheme='teal' >
                              <Thead className='t_had_bx'>
                                <Tr>
                                  <Th>Task ID</Th>
                                  <Th className=''>Task</Th>
                                  <Th>Role</Th>
                                  <Th>Date</Th>
                                  <Th>Hours</Th>
                                  <Th>Cost</Th>
                                </Tr>
                              </Thead>
                              <Tbody className='t_body'>
                                {itemProject?.tasks?.map((itemTask) => {
                                  let name = itemTask.name.substring(0,30);
                                  if(itemTask.name.length > 30) {
                                    name = `${name}...`;
                                  }
                                  return (
                                    <>
                                      {itemTask?.roles?.map((itemRole) => {
                                        return itemRole.users?.map((itemUser) => {
                                          return (
                                            <Tr className='hight_64'>
                                              <Td>{itemTask.id.substring(itemTask.id.length-6)}</Td>
                                              <Td>{name}</Td>
                                              <Td>{itemRole.role}</Td>
                                              <Td>{itemUser.name}</Td>
                                              <Td>{Number(itemUser.hours_worked).toFixed(2)}</Td>
                                              <Td>${Number(itemUser.total_cost).toFixed(2)}</Td>
                                            </Tr>
                                          )
                                        })
                                      })}
                                    </>
                                  )
                                })}
                              </Tbody>
                              <Tfoot className='t_had_bx t_fot_bx'>
                                <Tr>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th></Th>
                                  <Th className='txt_end'>Task Total:</Th>
                                  <Th>{Number(itemProject.hours_worked).toFixed(2)}</Th>
                                  <Th>${Number(itemProject.total_cost).toFixed(2)}</Th>
                                </Tr>
                              </Tfoot>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Box>
                    )
                  })}
                </ModalBody>
              </Box>
            </PDFExport>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
}
