import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Textarea,
  ModalFooter,
} from "@chakra-ui/react";
import httpStatus from "http-status";
import { toast } from "react-toastify";
import { BASE_URL } from "../../services/constant";

export default function InvoiceModalReject({ invoice, selectedOption, isOpen, onClose, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [canceledReason, setCanceledReason] = useState("");

  const doReject = async () => {
    try {
      setLoading(true);
      const body = {
        id: invoice?._id,
        status: selectedOption?.value,
        reason: canceledReason
      }
      const bluClientToken = localStorage.getItem('bluClientToken');
      const response = await fetch(BASE_URL + `/api/invoice-client/client/${invoice?._id}`, {
        method: "DELETE",
        headers: { 'Authorization': 'Bearer ' + bluClientToken }
      });
      const data = await response.json()
      if (response.status == httpStatus.OK) {
        invoice.reason = canceledReason;
        if (invoice) invoice.status = selectedOption?.value;
        onClose();
        setCanceledReason("");
        onSuccess();
        toast.success(`Success to cancel the invoice.\n${invoice?.invoice_ref}`);
      }
      else {
        toast.success(`Failed to cancel the invoice.\n${data?.message}`);
      }
    } catch (error) {
      console.error(error);
      toast.success(`Failed to cancel the invoice.\n${invoice?.invoice_ref}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="modal_report Metamask_pop_contin">
        <ModalBody className="modal_body Metamask_pop_body">
          <Box className='meta_mask_bo'>
            <Text className='color_red'>Reject Invoice</Text>
          </Box>
          <Text className='ple_provi_p'>Please Provide Details For Why This Invoice Is Rejected.</Text>
          <Box className='rejact_rdd_bx'>
            <Textarea
              className="rjct_two_p"
              value={canceledReason}
              onChange={(e) => { setCanceledReason(e.target.value) }} />
            {/* <Text className='merta_contin_p'>Hey I think here a some issue, I cant find task number #456748 I dont think this task may be in my invoice.</Text> */}
          </Box>
        </ModalBody>
        <ModalFooter className="foter_this_modl metamask_foot for_rejacr_invo">
          <Text>Are you sure you want to reject this invoice?</Text>
          <Box className='for_rejacr_invo_two_btn'>
            <Box className='rejac_cnc_btn_bx'>
              <Button colorScheme='blue' mr={3} disabled={loading} onClick={doReject} className="back_btn rejact_pink_btn">
                {loading ? "Loading..." : "Reject Invoice"}
              </Button>
            </Box>
            <Button className="close_btn" onClick={onClose}>Cancel</Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}