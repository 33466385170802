import React, { useEffect, useState } from 'react'
import { Box, Heading, Image, Button, useColorMode, useToast } from '@chakra-ui/react'
import axios from 'axios'
import Pagination from '../pagination';
import httpStatus from "http-status";

import { BASE_URL, CLICKUP_TICKET_STATUS } from '../../services/constant';

export default function NotificationsRightContent() {
  const { colorMode, toggleColorMode } = useColorMode();
  const toast = useToast();

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [notifications, setNotifications] = useState([]);

  const loadData = async (page) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('bluClientToken');
      const resp = await axios({
        method: 'get',
        url: `${BASE_URL}/api/notification?&page=${page}`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      if (resp.status === httpStatus.OK) {
        const json = resp.data.result;
        console.log(json.result)
        setNotifications(json.result);
        setPage(json.currentPage);
        setTotalPage(json.totalPages);
      }
    } catch (e) {
      // console.error("test", e?.message, e?.response?.status)
      if (e?.response?.status != httpStatus.UNAUTHORIZED) {
        let message = e?.response?.data?.message;
        if (!message) message = e.message
        toast({
          title: `Failed to load notification`,
          description: message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!loading) {
      loadData(page);
    }
  }, [])

  return (
    <>
      <Box className='foryscroll'>
        <Box className='notiftion_sc_main'>
          <Box className='first_hed'>
            <Heading as="h1">notifications</Heading>
          </Box>
          <Box className='forntfctnscroll'>
            <Box className='forntfctnscroll_inn'>
              {loading && (
                <Box className='notiftion_sc_main_innr_content notiftion_sc_main_innr_content_as'>
                  <Heading as="h6">Loading...</Heading>
                </Box>
              )}
              {notifications.length > 0 ? (
                notifications.map((item, index) => {
                  let beforeStatus = 'first_open_btn'
                  switch (item.status_from) {
                    case CLICKUP_TICKET_STATUS.INPROGRESS:
                      beforeStatus = 'progs_btn'
                      break
                    case CLICKUP_TICKET_STATUS.RESOLVED:
                      beforeStatus = 'resolved_btn'
                      break
                    case CLICKUP_TICKET_STATUS.CLOSED:
                      beforeStatus = 'closed_btn'
                      break
                  }

                  let afterStatus = 'first_open_btn'
                  switch (item.status_to) {
                    case CLICKUP_TICKET_STATUS.INPROGRESS:
                      afterStatus = 'progs_btn'
                      break
                    case CLICKUP_TICKET_STATUS.RESOLVED:
                      afterStatus = 'resolved_btn'
                      break
                    case CLICKUP_TICKET_STATUS.CLOSED:
                      afterStatus = 'closed_btn'
                      break
                  }

                  return (
                    <Box key={index} className='notiftion_sc_main_innr_content notiftion_sc_main_innr_content_as'>

                      <Heading as="h6">{`Ticket # ${item?.ticket_no}`}</Heading>
                      <Heading as="h6" className='margin_pernt'>Change status</Heading>

                      <Button className={`def_btn ${beforeStatus}`}>
                        {item.status_from}
                      </Button>

                      {colorMode === "light" ? (
                        <Image src="/img/arrow_ic.svg" alt="" className="arrow_ic" />
                      ) : (
                        <Image src="/img/arrow_ic_dark.svg" alt="" className="arrow_ic" />
                      )}

                      <Button className={`def_btn ${afterStatus}`}>
                        {item.status_to}
                      </Button>
                    </Box>
                  )
                })
              ) : !loading ? (
                <Box className='notiftion_sc_main_innr_content notiftion_sc_main_innr_content_as'>
                  <Heading as="h6">No data</Heading>
                </Box>
              ) : null}
            </Box>
            {/* <Box className='first_hed'>
              <Heading as="h1">notifications</Heading>
            </Box>
            <Box className='forntfctnscroll'>
              <Box className='forntfctnscroll_inn'>
                <Box className='notiftion_sc_main_innr_content notiftion_sc_main_innr_content_as'>
                  <Heading as="h6">Ticket #04564</Heading>
                  <Heading as="h6" className='margin_pernt'>Change status</Heading>
                  <Button className='def_btn first_open_btn'>Open</Button>
                  {colorMode === "light" ? (
                    <Image src="/img/arrow_ic.svg" alt="" className="arrow_ic" />
                  ) : (
                    <Image src="/img/arrow_ic_dark.svg" alt="" className="arrow_ic" />
                  )}
                  <Button className='def_btn progs_btn'>Resolved</Button>
                </Box>
                <Box className='notiftion_sc_main_innr_content notiftion_sc_main_innr_content_as'>
                  <Heading as="h6">Ticket #04564</Heading>
                  <Heading as="h6" className='margin_pernt'>Change status</Heading>
                  <Button className='def_btn first_open_btn'>Open</Button>
                  {colorMode === "light" ? (
                    <Image src="/img/arrow_ic.svg" alt="" className="arrow_ic" />
                  ) : (
                    <Image src="/img/arrow_ic_dark.svg" alt="" className="arrow_ic" />
                  )}
                  <Button className='def_btn progs_btn'>Resolved</Button>
                </Box>
                <Box className='notiftion_sc_main_innr_content notiftion_sc_main_innr_content_as'>
                  <Heading as="h6">Ticket #04564</Heading>
                  <Heading as="h6" className='margin_pernt'>Change status</Heading>
                  <Button className='def_btn first_open_btn'>Open</Button>
                  {colorMode === "light" ? (
                    <Image src="/img/arrow_ic.svg" alt="" className="arrow_ic" />
                  ) : (
                    <Image src="/img/arrow_ic_dark.svg" alt="" className="arrow_ic" />
                  )}
                  <Button className='def_btn progs_btn'>Resolved</Button>
                </Box>
              </Box>
            </Box> */}
          </Box>

          <Pagination
            page={Number(page)}
            totalPages={Number(totalPage)}
            handlePagination={(page) => loadData(page)}
          />
        </Box>
      </Box>
    </>
  )
}
// Open_btn
