import React, { useEffect, useState } from 'react'
import {
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Button,
  Image,
  useColorMode,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  GridItem,
  Select,
  Text,
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import axios from 'axios'
import httpStatus from 'http-status'
import LayoutTwo from '../components/layouts/LayoutTwo'
import Pagination from '../components/pagination'
import { BASE_URL } from '../services/constant'
import { useNavigate } from 'react-router-dom'
import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import PaginationNew from '../components/paginationNew'
import { format, startOfMonth } from 'date-fns'
import DateRangePicker from 'react-bootstrap-daterangepicker'

export default function ClientReport() {
  const { colorMode, toggleColorMode } = useColorMode()
  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), "MM/dd/yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM/dd/yyyy"));
  const [data, setData] = useState([]);
  const [user, setUser] = useState()
  const [isAdmin, setIsAdmin] = useState(false)

  const getData = async () => {
    if (startDate == undefined || endDate == undefined) return;

    try {
      const bluClientToken = localStorage.getItem('bluClientToken');
      const start = new Date(startDate);
      const end = new Date(endDate);
      const response = await fetch(BASE_URL + "/api/report/clientportal?startTime=" + start.getTime() + "&endTime=" + end.getTime(), {
        headers: { 'Authorization': 'Bearer ' + bluClientToken }
      });
      if (response.status == httpStatus.OK) {
        const data = await response.json()
        setData(data.message);
      }
    }
    catch (err) {
    }
  }

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  useEffect(() => {
    const user = localStorage.getItem('bluClientUser')
    if (user) {
      const userData = JSON.parse(user)
      setUser(userData)
      if (userData.type === 'admin') {
        setIsAdmin(true)
      } else {
        setIsAdmin(false)
      }
    }
  }, [])

  return (
    <>
      <LayoutTwo>
        <>
          <Box className='userlist_page'>
            <Box className='usr_list_cntnr'>
              <Heading as='h2' className='def_head_dbrd'>
                client report
              </Heading>
              <Box className='user_cntnt_box user_cntnt_box_new'>
                {colorMode === 'light' ? (
                  <Image
                    src='/img/user_list_top_img_v2.png'
                    alt=''
                    className='user_list_top_img'
                  />
                ) : (
                  <Image
                    src='/img/user_list_top_img_dark.png'
                    alt=''
                    className='user_list_top_img'
                  />
                )}
                {colorMode === 'light' ? (
                  <Image
                    src='/img/user_list_btm_img_v2.png'
                    alt=''
                    className='user_list_btm_img'
                  />
                ) : (
                  <Image
                    src='/img/user_list_btm_img_dark.png'
                    alt=''
                    className='user_list_btm_img'
                  />
                )}
                <Box className='user_cntr_line_bg user_cntr_line_bg_v2' />
                <Box className='header_client_rpt_list'>
                  <Heading as="h3" className='dflt_h3_ttl'>Clients</Heading>
                  <Box className='right_datepkr'>
                    <Box className='frst_right_dtepkr'>
                      {colorMode === "light" ? (
                        <Image src="/img/clock_ic.svg" alt="" className="clock_ic_as" />
                      ) : (
                        <Image src="/img/clock_ic_dark.svg" alt="" className="clock_ic_as" />
                      )}
                      <Text className="space_btwn">From</Text>
                      <Box className="date_bx">
                        <Image src="/img/datepicker_ic.svg" alt="" />
                        {/* <input type="date" /> */}
                        <DateRangePicker
                          initialSettings={{
                            singleDatePicker: true,
                            showDropdowns: false,
                            opens: 'left',
                          }}
                          onCallback={(start, end, label) => {
                            let date = format(start.toDate(), "MM/dd/yyy");
                            // localStorage.setItem("bluTimeframeStart", date);
                            setStartDate(date);
                            // localStorage.setItem("bluTimeframeType", TIMEFRAME_TYPE.CUSTOM.toString());
                          }}
                        >
                          <input type="text" className="form-control"
                            placeholder='Choose Start Period'
                            value={startDate} />
                        </DateRangePicker>
                      </Box>
                      <Text className="space_btwn space_btwn_to">To</Text>
                      <Box className="date_bx">
                        <Image src="/img/datepicker_ic.svg" alt="" />
                        {/* <input type="date" /> */}
                        <DateRangePicker
                          initialSettings={{
                            singleDatePicker: true,
                            showDropdowns: false,
                            opens: 'left',
                          }}
                          onCallback={(start, end, label) => {
                            let date = format(end.toDate(), "MM/dd/yyy");
                            // localStorage.setItem("bluTimeframeEnd", date);
                            setEndDate(date)
                            // localStorage.setItem("bluTimeframeType", TIMEFRAME_TYPE.CUSTOM.toString());
                          }}
                        >
                          <input type="text" className="form-control"
                            placeholder='Choose End Period'
                            value={endDate} />
                        </DateRangePicker>
                      </Box>
                    </Box>
                    {/* <Box className='gnrl_rport_bx'>
                        <Button className='gnrl_rpt_btn'>
                          Generate Report
                        </Button>
                      </Box> */}
                  </Box>
                </Box>



                <Box className='table_client_list mt-0'>
                  <TableContainer>
                    <Table variant="simple" className='client_report_tbl withShadow'>
                      <Thead className='tbl_clnt_head'>
                        <Tr className='pt-0'>
                          <Th className="client_tbl_frst">Client</Th>
                          <Th className="client_tbl_last_th">Hours Worked (hr)</Th>
                          <Th className="client_tbl_last_th">Total Price ($)</Th>
                          <Th className="client_tbl_last_th">Hours Billed (hr)</Th>
                          <Th className="client_tbl_last_th">Total Cost ($)</Th>
                          <Th className="client_tbl_last_th"></Th>
                        </Tr>
                      </Thead>
                      <Tbody className='table_body'>
                        {data.map((item) => {
                          return (
                            <Tr>
                              <Td>{item.name}</Td>
                              <Td>{Number(item?.hours_worked).toFixed(2)}</Td>
                              <Td>{Number(item?.total_price).toFixed(2)}</Td>
                              <Td>{Number(item?.hour_billed).toFixed(2)}</Td>
                              <Td>{Number(item?.total_cost).toFixed(2)}</Td>
                              <Td className="btn_left_prnt">
                                <Button href={`/clientreportdetail/${item._id}`} as="a" className="def_btn detail_def_nl">
                                  <span>  Details</span>
                                </Button>
                              </Td>
                            </Tr>
                          )
                        })}
                        {/* <Tr>
                            <Td className='frst_td_nl'>Swapsicle</Td>
                            <Td>104.6</Td>
                            <Td>361.00</Td>
                            <Td>0.0 h</Td>
                            <Td>Empty</Td>
                            <Td className="last_td_nl"><Button as="a" href="/clientreportdetail">Details</Button></Td>
                          </Tr>
                          <Tr>
                            <Td className='frst_td_nl'>Bigger MINDS</Td>
                            <Td>104.6</Td>
                            <Td>361.00</Td>
                            <Td>0.0 h</Td>
                            <Td>Empty</Td>
                            <Td className="last_td_nl"><Button as="a" href="/clientreportdetail">Details</Button></Td>
                          </Tr>
                          <Tr>
                            <Td className='frst_td_nl'>CAT</Td>
                            <Td>104.6</Td>
                            <Td>361.00</Td>
                            <Td>0.0 h</Td>
                            <Td>Empty</Td>
                            <Td className="last_td_nl"><Button as="a" href="/clientreportdetail">Details</Button></Td>
                          </Tr>
                          <Tr>
                            <Td className='frst_td_nl'>Pi Protocol</Td>
                            <Td>104.6</Td>
                            <Td>361.00</Td>
                            <Td>0.0 h</Td>
                            <Td>Empty</Td>
                            <Td className="last_td_nl"><Button as="a" href="/clientreportdetail">Details</Button></Td>
                          </Tr>
                          <Tr>
                            <Td className='frst_td_nl'>D0P3 Club</Td>
                            <Td>104.6</Td>
                            <Td>361.00</Td>
                            <Td>0.0 h</Td>
                            <Td>Empty</Td>
                            <Td className="last_td_nl"><Button as="a" href="/clientreportdetail">Details</Button></Td>
                          </Tr>
                          <Tr>
                            <Td className='frst_td_nl'>Vineyard</Td>
                            <Td>104.6</Td>
                            <Td>361.00</Td>
                            <Td>0.0 h</Td>
                            <Td>Empty</Td>
                            <Td className="last_td_nl"><Button as="a" href="/clientreportdetail">Details</Button></Td>
                          </Tr> */}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
                {/* <PaginationNew /> */}
              </Box>
            </Box>
          </Box>
        </>
      </LayoutTwo>
    </>
  )
}
