import axios from 'axios'
import { BASE_URL } from '../../../services/constant'
export const loginUser = (email, password) => async (dispatch) => {
  await axios
    .post(`${BASE_URL}/api/userClient/login`, { email, password })
    .then((response) => {
      console.log('login', response)
      if (response.data.accessToken) {
        localStorage.setItem('bluClientToken', response.data.accessToken)
      }
      if (response.data.result) {
        localStorage.setItem(
          'bluClientUser',
          JSON.stringify(response.data.result),
        )
      }
    })
}

export const logoutUser = () => async (dispatch) => {
  localStorage.removeItem('bluClientToken')
}
