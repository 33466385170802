import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Image,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { chainIdToHexString } from "../../utils";
import { DefaultNetwork, injectedConnector, networkInfo } from "../../utils/constants";

export default function ModalConnectWallet({ isConnectWalletOpen, onConnectWalletClose, onConnected }) {
  const cancelRef = React.useRef(null);
  const { activate, active, account, error } = useWeb3React();
  const [wrongNetwork, setWrongNetwork] = useState(false)
  const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError

  const connectWallet = async () => {
    try {
      activate(injectedConnector);
    } catch (error) {
      toast.error(error?.message ? error?.message : error)
    }
  };

  const changeNetwork = async () => {
    const wa = window
    const { ethereum } = wa
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainIdToHexString(DefaultNetwork) }],
      })
    } catch (switchError) {
      // eslint-disable-next-line no-shadow
      const error = JSON.parse(JSON.stringify(switchError))
      if (
        error.code === 4902 ||
        (error.code === -32603 && error?.data?.originalError.code === 4902)
      ) {
        try {
          const item = networkInfo[DefaultNetwork]
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: chainIdToHexString(DefaultNetwork),
                chainName: item.label,
                rpcUrls: [item.rpcUrl],
                nativeCurrency: {
                  name: item.nativeCurrency,
                  symbol: item.nativeCurrency,
                  decimals: 18,
                },
                blockExplorerUrls: [item.explorer],
              },
            ],
          })
        } catch (error) {
          toast.error(error?.message ? error?.message : error)
        }
      }
    }
  }

  useEffect(() => {
    if (active) {
      onConnectWalletClose();
      onConnected();
    }
  }, [account])

  useEffect(() => {
    setWrongNetwork(isUnsupportedChainIdError)
  }, [isUnsupportedChainIdError])

  useEffect(() => {
    if (wrongNetwork) changeNetwork()
  }, [wrongNetwork])

  return (
    <>
      <Modal isOpen={isConnectWalletOpen} onClose={onConnectWalletClose} isCentered>
        <ModalOverlay />
        <ModalContent className="modal_report Metamask_pop_contin">
          {/* <ModalHeader className="moda_hed">
            <Heading as="h2" className='invos_head'>Invoice #548</Heading>
          </ModalHeader> */}
          {/* <ModalCloseButton /> */}
          <ModalBody className="modal_body Metamask_pop_body">
            <Box className='meta_mask_bo'>
              <Image src="/img/Metamask_ic.svg" alt="" />
              <Text>Metamask Transfer</Text>
            </Box>
            <Text className='merta_contin_p'>Please connect your MetaMask wallet to pay for this invoice.</Text>
          </ModalBody>
          <ModalFooter className="foter_this_modl metamask_foot">
            <Box className='rejac_cnc_btn_bx'>
              <Button colorScheme='blue' mr={3} onClick={onConnectWalletClose} className="back_btn">
                Cancel
              </Button>
            </Box>
            <Button className="close_btn" onClick={connectWallet}>
              Connect Wallet
            </Button>
          </ModalFooter>
          {/* PaymtConformisOpen */}
        </ModalContent>
      </Modal>
    </>
  );
}