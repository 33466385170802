import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  SimpleGrid,
  GridItem,
  Heading,
  Text,
  Button,
  useToast,
  CircularProgress,
} from '@chakra-ui/react'
import { Element } from 'react-scroll'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import SliderCaptcha from '@slider-captcha/react'
import { Buffer } from 'buffer'
import { BASE_URL } from '../../services/constant'
import { loginUser } from '../../redux/Auth/action'

window.Buffer = window.Buffer || require('buffer').Buffer

export default function SectionReport() {
  const toast = useToast()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const emailRef = useRef(null)
  const passwordRef = useRef(null)
  const [contactEmail, setContactEmail] = useState('')
  const [contactName, setContactName] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState({
    name: undefined,
    email: undefined,
    message: undefined,
  })
  const [verified, setVerified] = useState(false)

  useEffect(() => {
    emailRef.current && emailRef.current.focus()
    const credential = localStorage.getItem('bluClientUser')
    if (credential) {
      setEmail(JSON.parse(credential).email)
      setPassword(JSON.parse(credential).password)
    }
  }, [])

  const validateEmail = (item) => {
    return String(item)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  }

  const verifiedCallback = (token) => {
    setVerified(true)
  }

  const sendForm = async () => {
    let newError = {
      name: undefined,
      email: undefined,
      message: undefined,
    }

    if (contactName === '') {
      newError.name = 'Name cannot be empty!'
    }

    if (contactEmail === '') {
      newError.email = 'Email cannot be empty!'
    }

    if (message === '') {
      newError.message = 'Message cannot be empty!'
    }

    if (!validateEmail(contactEmail)) {
      newError.email = 'Invalid Email!'
    }

    setError(newError)

    if (
      contactName === '' ||
      contactEmail === '' ||
      message === '' ||
      !validateEmail(contactEmail)
    ) {
      return
    }

    let msg = {
      name: contactName,
      email: contactEmail,
      message: message,
    }
    // Send data to the backend via POST
    try {
      const resp = await axios({
        method: 'POST',
        url: `${BASE_URL}/api/devlegion/contactus`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: msg,
      })
      toast({
        title: 'Thankyou for contacting us!',
        description: '',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    } catch (err) {
      toast({
        title: 'Oops, ',
        description: 'Please try again later!',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      })
      console.log(err)
    }
  }

  const handleLogin = () => {
    setIsLoading(true)
    dispatch(loginUser(email, password))
      .then(() => {
        setIsLoading(false)
        navigate('/dashboard')
      })
      .catch((e) => {
        setIsLoading(false)
        toast({
          title: 'You are not logged in.',
          description: 'Invalid username or password.',
          status: 'warning',
          duration: 9000,
          isClosable: true,
        })
      })
  }

  const setFocusPassword = (e) => {
    if (e.key === 'Enter') {
      passwordRef.current && passwordRef.current.focus()
    }
  }

  const hadleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin()
    }
  }

  return (
    <>
      <Element name='ContactUs'>
        <Box className='Report_bg_box'>
          <SimpleGrid
            columns={12}
            columnGap={0}
            rowGap={0}
            className='flex_colum align_center'
          >
            <GridItem colSpan={[12, 12, 6, 6]}>
              <Box className='reprt_gred_box'>
                <Box className='report_img_box'>
                  {/* <Heading as='h3' className='report_hdng'>
                    Ticket
                  </Heading> */}
                  <Box className='clint_head'>
                    <Heading as='h4'>
                      CLIENT <span>LOGIN</span>
                    </Heading>
                    <Text>Login for Support Tickets and Requests</Text>
                  </Box>
                  <Box className='input_box'>
                    <input
                      type='text'
                      placeholder='Email'
                      value={email}
                      useRef={emailRef}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyPress={setFocusPassword}
                    />
                  </Box>
                  <Box className='input_box'>
                    <input
                      type='password'
                      placeholder='Password'
                      value={password}
                      useRef={passwordRef}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyPress={hadleKeyPress}
                    />
                  </Box>
                  {/* <Box className='prgrf_single'>
                    <Text><span>Sign Up </span>if you now have account</Text>
                  </Box> */}
                  <Box className='btn_bg_clint_box'>
                    <Button className='btn_box_deco' onClick={handleLogin}>
                      {isLoading ? (
                        <CircularProgress isIndeterminate size='24px' />
                      ) : (
                        'Login'
                      )}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 6]}>
              <Box className='reprt_gred_box_v2'>
                <Box className='report_img_box_v2'>
                  {/* <Heading as='h3' className='contact_hdng'>
                    contact
                  </Heading> */}
                  <Box className='clint_head'>
                    <Heading as='h4'>
                      Contact <span>us</span>
                    </Heading>
                    <Text>SCHEDULE A CONSULTATION </Text>
                  </Box>
                  <Box className='input_box'>
                    <input
                      type='text'
                      placeholder='Name'
                      value={contactName}
                      onChange={(e) => {
                        setError({ ...error, name: undefined })
                        setContactName(e.target.value)
                      }}
                    />
                  </Box>
                  <Box className='input_box_error'>
                    {error.name ? <p>{error.name}</p> : <></>}
                  </Box>
                  <Box className='input_box'>
                    <input
                      type='text'
                      placeholder='Email'
                      value={contactEmail}
                      onChange={(e) => {
                        setError({ ...error, email: undefined })
                        setContactEmail(e.target.value)
                      }}
                    />
                  </Box>
                  <Box className='input_box_error'>
                    {error.email ? <p>{error.email}</p> : <></>}
                  </Box>
                  <Box className='input_box '>
                    {/* <input className='input_box_v2' type="text" placeholder='Message'/> */}

                    <textarea
                      id='w3review'
                      name='w3review'
                      className='input_box_v2'
                      placeholder='Message'
                      value={message}
                      onChange={(e) => {
                        setError({ ...error, message: undefined })
                        setMessage(e.target.value)
                      }}
                    />
                  </Box>
                  <Box className='input_box_error'>
                    {error.message ? <p>{error.message}</p> : <></>}
                  </Box>
                  {/* <Box className='input_box'>
                    <SliderCaptcha
                      create={`${BASE_URL}/api/captcha/create`}
                      verify={`${BASE_URL}/api/captcha/verify`}
                      callback={verifiedCallback}
                      variant='dark'
                    />
                  </Box> */}
                  <Box className='btn_bg_clint_box_v2'>
                    <Button
                      className='btn_box_deco_v2'
                      onClick={sendForm}
                    >
                      Send
                    </Button>
                  </Box>
                </Box>
              </Box>
            </GridItem>
          </SimpleGrid>
        </Box>
      </Element>
    </>
  )
}
