import React from "react";
import { Container, Box, Heading, Image, Text } from "@chakra-ui/react";
import Marquee from "react-easy-marquee";

export default function Footer() {
  return (
    <>
      {/* <Image src="/img/footer_top_img.png" className="ftr_tp_img"/> */}
      <Box className="footer_main">
        <Box className="footer_bg_bx">
          <Box className="auto_srolling_box">
            <Marquee
              width="100%"
              height="45px"
              reverse={true}
              duration={25000}
              className="mrq_box"
            >
              <Text>
                <span className="dvlgn_txt"> DevLegion </span> Build Something
                Exceptional <span className="dvlgn_txt"> DevLegion </span>{" "}
                Unlimited Potential{" "}
                <span className="dvlgn_txt"> DevLegion </span> Results Driven{" "}
                <span className="dvlgn_txt"> DevLegion </span> Trust is
                Priceless <span className="dvlgn_txt"> DevLegion </span>{" "}
                Blockchain is Truth{" "}
                <span className="dvlgn_txt"> DevLegion </span> Truth is
                Verifiable <span className="dvlgn_txt"> DevLegion </span>
              </Text>
            </Marquee>
          </Box>
          <Container>
            <Box className="ftr_innr_bx">
              <Image src="/img/footer_logo.svg" className="ftr_logo" />
              <Box className="devlpng_main_bx">
                <Box className="dvlpng_innr_bx">
                  <Heading as="h4">Block leader</Heading>
                  <Box className="logo_and_link_bx">
                    <Image
                      src="/img/ftr_blckldr_ic.svg"
                      alt=""
                      className="logo_ic"
                    />
                    <Box
                      as="a"
                      href="https://www.blockleader.io/"
                      target="_blank"
                    >
                      blockleader.io
                      <Image src="/img/footer_link_arrow.svg" alt="" />
                    </Box>
                  </Box>
                </Box>
                <Box className="dvlpng_innr_bx">
                  <Heading as="h4">Bitgraphix</Heading>
                  <Box className="logo_and_link_bx">
                    <Image
                      src="/img/ftr_btgrphx_ic.svg"
                      alt=""
                      className="logo_ic"
                    />
                    <Box as="a" href="https://www.bitgraphix.io"
target="_blank">
                      bitgraphix.io
                      <Image src="/img/footer_link_arrow.svg" alt="" />
                    </Box>
                  </Box>
                </Box>
                <Box className="dvlpng_innr_bx">
                  <Heading as="h4">block GURUS</Heading>
                  <Box className="logo_and_link_bx">
                    <Image
                      src="/img/ftr_blckgr_ic.svg"
                      alt=""
                      className="logo_ic"
                    />
                    <Box as="a" href="https://www.blockgurus.io"
target="_blank"
>
                      blockgurus.io
                      <Image src="/img/footer_link_arrow.svg" alt="" />
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* <Box className="ftr_link_bx">
                            <Box as="a" href="#">
                                <Image src="/img/twttr_ic.svg" />
                            </Box>
                            <Box as="a" href="#">
                                <Image src="/img/facebook_ic.svg" />
                            </Box>
                            <Box as="a" href="#">
                                <Image src="/img/github_ic.svg" />
                            </Box>
                        </Box> */}
              <Box className="ftr_design_bgbx">
                <Text>
                  DEV Legion 2022. ALl Rights Reserved (c). Powered by{" "}
                  <Box as="a" href="#">
                    BITGRAPHIX
                  </Box>
                </Text>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      
    </>
  );
}
