import React, { useEffect, useState } from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  TableContainer,
  Box,
  Button,
  Image,
  useColorMode,
  useDisclosure,
  Heading,
  useToast,
} from '@chakra-ui/react'
import axios from "axios";
import httpStatus from "http-status";
import { BASE_URL } from "../../services/constant";
import TicketDetail from "./TicketDetail";
import { CLICKUP_TICKET_STATUS } from "../../services/constant";
import { useNavigate } from "react-router-dom";
import Pagination from '../pagination';
import { logoutUser } from '../../redux/Auth/action';
import { useDispatch } from 'react-redux';

export default function TicketsTable({ isPagination, pagination, isRefresh, setIsRefresh }) {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen: isDetailOpen, onOpen: onDetailOpen, onClose: onDetailClose } = useDisclosure();
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [ticket, setTicket] = useState({})
  const [tickets, setTickets] = useState([]);

  const loadData = async (page) => {
    try {
      if (isRefresh) {
        setIsRefresh(false);
      }
      setLoading(true);
      const token = localStorage.getItem('bluClientToken');
      const resp = await axios({
        method: 'get',
        url: `${BASE_URL}/api/ticket?page=${page}&size=${pagination}`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      if (resp.status === httpStatus.OK) {
        const json = resp.data?.result;
        setTickets(json?.result);
        setPage(json.currentPage)
        setTotalPage(json.totalPages)
      }
    } catch (error) {
      console.error(error)
      if (error?.response?.status == httpStatus.UNAUTHORIZED) {
        dispatch(logoutUser()).then(() => {
          toast({
            title: 'You are not logged in.',
            description: "Please login first",
            status: 'warning',
            duration: 3000,
            isClosable: true,
          });
          navigate('/')
        })
      }
      else {
        toast({
          title: `Failed to load tickets`,
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } finally {
      setLoading(false);
    }
  }

  const getDetail = (item) => {
    setTicket(item);
    onDetailOpen();
  }

  const handleBack = () => {
    loadData(1);
    setTicket({})
    onDetailClose()
  }

  useEffect(() => {
    if (isRefresh) {
      loadData(1);
    }
  }, [isRefresh])

  useEffect(() => {
    if (!loading) {
      loadData(page);
    }
  }, [])

  return (
    <>
      <Box className='userlist_table_prnt ticketlist_table_prnt userlist_table_prnt_aj userlist_table_prnt_ab'>
        <Heading as='h5'>Ticket List</Heading>
        <TableContainer className='userlist_table_cntnr'>
          <Table variant='simple'>
            <Thead className='report_list_head_bx'>
              <Tr>
                <Th>Ticket #</Th>
                <Th>Title</Th>
                <Th className='svrt_th'>Severity</Th>
                <Th>Category</Th>
                <Th>Type of report</Th>
                <Th className='cetner_items'>Status</Th>
                <Th>Open</Th>
                {/* <Th>PDF</Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {loading && (
                <Tr className='border_befr_none'>
                  <Td className='min_height_14' colSpan={6}>
                    Loading...
                  </Td>
                </Tr>
              )}
              {tickets.length > 0 ? (
                tickets.map((item, index) => {
                  let status = 'Open_btn'
                  switch (item?.status) {
                    case CLICKUP_TICKET_STATUS.INPROGRESS:
                      status = 'Progress_btn'
                      break
                    case CLICKUP_TICKET_STATUS.RESOLVED:
                      status = 'Resolved_btn'
                      break
                    case CLICKUP_TICKET_STATUS.CLOSED:
                      status = 'Closed_btn'
                      break
                    default:
                      break
                  }
                  return (
                    <Tr key={index}>
                      <Td>
                        <Box>{item.ticket_no}</Box>
                      </Td>
                      <Td className='cusrtm_pargf'>
                        <Text>{item.name}</Text>
                      </Td>
                      <Td>
                        <Box className='pdng_0'>
                          <Box
                            className={
                              item.severity?.name === 'Critical' ? 'crtcl_svrt status' :
                                item.severity?.name === 'High' ? 'high_svrt status' :
                                  item.severity?.name === 'Medium' ? 'mdum_svrt status' : 'low_svrt status'
                            }
                          >
                            {item.severity?.name}
                          </Box>
                        </Box>
                      </Td>
                      <Td>
                        <Box>
                          {item?.category?.name}
                        </Box>
                      </Td>
                      <Td align='center'>
                        <Box className='rprt_imgs_box'>
                          {
                            item?.tags && item?.tags.length > 0 ?

                              item.tags[0] == 'issue' ?
                                <Image src='/img/exclm_t_lst_ic.svg' alt='' /> :
                                item.tags[0] == 'request' ?
                                  <Image src="/img/traced_ic_as.svg" alt="" /> : <></> : <></>
                          }
                        </Box>
                      </Td>
                      <Td>
                        <Box
                          className={`${status} cetner_items class status_btn_prnt`}
                        >
                          <Button className='def_btn'>{item?.status}</Button>
                        </Box>
                      </Td>
                      <Td>
                        <Box className='last_two_btn'>
                          <Box className=''>
                            <Button
                              className=''
                              onClick={() => getDetail(item)}
                            >
                              {colorMode === 'light' ? (
                                <Image
                                  src='/img/eye_ic_dashbrd.svg'
                                  alt=''
                                  className=''
                                />
                              ) : (
                                <Image
                                  src='/img/eye_ic_dashbrd_dark.svg'
                                  alt=''
                                  className=''
                                />
                              )}
                            </Button>
                          </Box>
                        </Box>
                      </Td>
                      {/* <Td>
                        <Box className='last_two_btn last_two_btn_last'>
                          <Box className=''>
                            <Button>
                              {colorMode === 'light' ? (
                                <Image
                                  src='/img/dwnlod_ic_dashbord.svg'
                                  alt=''
                                  className=''
                                />
                              ) : (
                                <Image
                                  src='/img/dwnlod_ic_dashbord_dark.svg'
                                  alt=''
                                  className=''
                                />
                              )}
                            </Button>
                          </Box>
                        </Box>
                      </Td> */}
                    </Tr>
                  )
                })
              ) : !loading ? (
                <Tr className='border_befr_none'>
                  <Td className='min_height_14' colSpan={6}>
                    No Data
                  </Td>
                </Tr>
              ) : null}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      {isPagination && <Pagination
        page={Number(page)}
        totalPages={Number(totalPage)}
        handlePagination={(page) => loadData(page)}
      />}

      {ticket ?
        <TicketDetail
          ticketTable={ticket}
          isDetailOpen={isDetailOpen}
          handleBack={handleBack}
        />
        : <></>}
    </>
  )
}
