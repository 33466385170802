import { Box } from "@chakra-ui/react";
import XLSX from "sheetjs-style";
import { INVOICE_CLIENT_STATUS } from "./constants";

export const exportToExcel = (fileName, data) => {
  const headerStyle = {
    font: {
      sz: 14,
      bold: true
    },
    alignment: {
      horizontal: "center"
    }
  };
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data);

  ws["A1"].s = headerStyle;
  ws["B1"].s = headerStyle;
  ws["C1"].s = headerStyle;
  if (ws["D1"]) {
    ws["D1"].s = headerStyle;
  }
  if (ws["E1"]) {
    ws["E1"].s = headerStyle;
  }

  XLSX.utils.book_append_sheet(wb, ws, "ST"); // add worksheet to workbook
  XLSX.writeFile(wb, `${fileName}.xlsx`); // write workbook
};

export const chainIdToHexString = (chainId) =>
  chainId && `0x${chainId.toString(16)}`

export const printInvoiceButton = (status) => {
  switch (status) {
    case INVOICE_CLIENT_STATUS.SUBMITTED:
      return <Box className='align_centar pendin_bg_color'>{status}</Box>;
    case INVOICE_CLIENT_STATUS.REJECTED:
      return <Box className='align_centar Paid_bg_color'>{status}</Box>;
    case INVOICE_CLIENT_STATUS.PAID:
      return <Box className='align_centar Rejected'>{status}</Box>;
  }
}