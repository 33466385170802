import React, { useEffect, useState } from 'react'
import {
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Button,
  Image,
  useColorMode,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  GridItem,
  Select,
  Text,
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import axios from 'axios'
import httpStatus from 'http-status'
import LayoutTwo from '../components/layouts/LayoutTwo'
import Pagination from '../components/pagination'
import { BASE_URL } from '../services/constant'
import { useNavigate } from 'react-router-dom'
import { FormControl, FormLabel, Input } from '@chakra-ui/react'

export default function UserList() {
  const { colorMode, toggleColorMode } = useColorMode()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenDeleteUser, onOpen: onOpenDeleteUser, onClose: onCloseDeleteUser } = useDisclosure()
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState(undefined)
  const [optionUserType, setOptionUserType] = useState([])
  const [optionClient, setOptionClient] = useState([])
  const [isAdmin, setIsAdmin] = useState(false)

  const [form, setForm] = useState({
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    type: undefined,
    client_id: undefined,
  })
  const [isButtonDisable, setIsButtonDisable] = useState(true)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const toast = useToast()

  const getDetail = async (page) => {
    try {
      const token = localStorage.getItem('bluClientToken')
      const resp = await axios({
        method: 'get',
        url: `${BASE_URL}/api/userClient?&page=${page}`,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })

      if (resp.status === httpStatus.OK) {
        const json = resp.data.result
        setItems(json.result)
        setPage(json.currentPage)
        setTotalPage(json.totalPages)
      }
    } catch (e) {
      toast({
        title: `Failed to load data`,
        description: e.response.data?.authorization[0].message ?? e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      console.error('test', e.message)
    }
  }

  const handleCreate = () => {
    setSelectedItem(undefined)
    onOpen()
  }

  const handleSelectItem = (item) => {
    console.log('test-handleSelectItem', item)
    setSelectedItem(item)
    onOpen()
  }

  const getFormDetail = async () => {
    try {
      const token = localStorage.getItem('bluClientToken')
      const resp = await axios({
        method: 'get',
        url: `${BASE_URL}/api/userClient/formData`,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })

      if (resp.status === httpStatus.OK) {
        const json = resp.data
        let options = []
        for (const option of json) {
          options.push({
            value: option.id ? option.id : option._id,
            label: option.name,
          })
        }
        setOptionClient(options)
      }
    } catch (e) {
      console.error('test', e.message, e)
      if (e.response.status == httpStatus.UNAUTHORIZED) {
        toast({
          title: 'You are not logged in.',
          description:
            e.response.data?.authorization[0].message ?? 'Please login first',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
        navigate('/')
      } else {
        toast({
          title: `Failed to load data`,
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    }
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const token = localStorage.getItem('bluClientToken')
      const resp = await axios({
        method: selectedItem === undefined ? 'post' : 'put',
        url: `${BASE_URL}/api/userClient/${selectedItem !== undefined ? selectedItem?._id : ''
          }`,
        headers: {
          Authorization: 'Bearer ' + token,
        },
        data: form,
      })

      if (resp?.status === httpStatus.OK) {
        const json = resp.data
        toast({
          title: json,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        getDetail(1)
      }
    } catch (e) {
      console.error('test', e.message)
      if (e.response.status == httpStatus.UNAUTHORIZED) {
        toast({
          title: 'You are not logged in.',
          description:
            e.response.data?.authorization[0].message ?? 'Please login first',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        })
        navigate('/')
      } else {
        console.log(e)
        let message = e.message
        if (e?.response?.data?.message) {
          message = e?.response?.data?.message
        }
        toast({
          title: 'Failed to create user',
          description: message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  const handleDeleteUser = async () => {
    try {
      setIsLoadingDelete(true)
      const token = localStorage.getItem('bluClientToken')
      const resp = await axios({
        method: 'delete',
        url: `${BASE_URL}/api/userClient/${selectedItem !== undefined ? selectedItem?._id : ''
          }`,
        headers: {
          Authorization: 'Bearer ' + token,
        },
        data: form,
      })

      if (resp?.status === httpStatus.OK) {
        const json = resp.data
        toast({
          title: json,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })

        getDetail(1)
      }
    } catch (e) {
      console.error('test', e.message)
      if (e.response.status == httpStatus.UNAUTHORIZED) {
        toast({
          title: 'You are not logged in.',
          description:
            e.response.data?.authorization[0].message ?? 'Please login first',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        })
        navigate('/')
      } else {
        console.log(e)
        let message = e.message
        if (e?.response?.data?.message) {
          message = e?.response?.data?.message
        }
        toast({
          title: 'Failed to delete user',
          description: message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoadingDelete(false)
      onCloseDeleteUser()
      onClose()
      setSelectedItem(undefined)
    }
  }

  useEffect(() => {
    let disable = false
    if (form.username === '') {
      disable = true
    }
    if (form.email === '') {
      disable = true
    }
    if (form.firstname === '') {
      disable = true
    }
    if (form.lastname === '') {
      disable = true
    }
    if (form.type === undefined) {
      disable = true
    }
    // if (form.client_id === undefined) {
    //   disable = true
    // }
    setIsButtonDisable(disable)
  }, [form])

  useEffect(() => {
    const user = localStorage.getItem('bluClientUser')
    if (user) {
      const userData = JSON.parse(user)
      if (userData.type === 'admin') {
        setIsAdmin(true)
      } else {
        setIsAdmin(false)
      }
    }
  }, [])

  useEffect(() => {
    console.log('test-item', selectedItem)
    const types = [
      { value: 'admin', label: 'admin' },
      { value: 'user', label: 'user' },
    ]
    setOptionUserType(types)
    if (selectedItem === undefined) {
      setForm({
        username: '',
        email: '',
        firstname: '',
        lastname: '',
        type: undefined,
        client_id: undefined,
      })
    } else {
      setForm({
        username: selectedItem?.username,
        email: selectedItem?.email,
        firstname: selectedItem?.firstname,
        lastname: selectedItem?.lastname,
        type: selectedItem?.type,
        client_id: selectedItem?.client_id?._id,
      })
    }
    getFormDetail()
    console.log(selectedItem)
  }, [isOpen, selectedItem])

  useEffect(() => {
    getDetail(page)
  }, [])

  return (
    <>
      <LayoutTwo>
        {isAdmin ? (
          <>
            <Box className='userlist_page'>
              <Box className='usr_list_cntnr'>
                <Heading as='h2' className='def_head_dbrd'>
                  Users
                </Heading>
                <Box className='user_cntnt_box'>
                  {colorMode === 'light' ? (
                    <Image
                      src='/img/user_list_top_img.png'
                      alt=''
                      className='user_list_top_img'
                    />
                  ) : (
                    <Image
                      src='/img/user_list_top_img_dark.png'
                      alt=''
                      className='user_list_top_img'
                    />
                  )}
                  {colorMode === 'light' ? (
                    <Image
                      src='/img/user_list_btm_img.png'
                      alt=''
                      className='user_list_btm_img'
                    />
                  ) : (
                    <Image
                      src='/img/user_list_btm_img_dark.png'
                      alt=''
                      className='user_list_btm_img'
                    />
                  )}
                  <Box className='user_cntr_line_bg' />
                  <Box className='create_user_prnt_raw'>
                    <Heading as='h5'>users</Heading>
                    <Box>
                      <Button
                        onClick={() => handleCreate()}
                        className='creat_user_btn'
                      >
                        {colorMode === 'light' ? (
                          <Image
                            src='/img/user_ic.svg'
                            alt=''
                            className='sidebar_logo'
                          />
                        ) : (
                          <Image
                            src='/img/user_ic_dark.svg'
                            alt=''
                            className='sidebar_logo'
                          />
                        )}{' '}
                        Create user
                      </Button>
                      {/* <UserModal isOpen={isOpen} onClose={onClose} getDetail={getDetail} selectedItem={selectedItem} /> */}
                    </Box>
                  </Box>
                  <Box className=''>
                    <Box className='userlist_table_prnt'>
                      <TableContainer>
                        <Table variant='simple'>
                          <Thead>
                            <Tr>
                              <Th>ID</Th>
                              <Th>Username</Th>
                              <Th>Name</Th>
                              <Th>Email</Th>
                              <Th>Type</Th>
                              <Th>Client</Th>
                              <Th>Active</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr className='border_befr_none'>
                              <Td className='min_height_14'></Td>
                            </Tr>
                            {items.length > 0
                              ? items.map((item, index) => {
                                return (
                                  <Tr key={index}>
                                    <Td>
                                      <Box>{item?._id}</Box>
                                    </Td>
                                    <Td>
                                      <Box>{item?.username}</Box>
                                    </Td>
                                    <Td>
                                      <Box>{`${item?.firstname} ${item?.lastname}`}</Box>
                                    </Td>
                                    <Td>
                                      <Box>{item?.email}</Box>
                                    </Td>
                                    <Td>
                                      <Box>{item?.type}</Box>
                                    </Td>
                                    <Td>
                                      <Box>{item?.client_id?.name}</Box>
                                    </Td>
                                    <Td>
                                      <Box>
                                        <Box className='clctn_last_flex'>
                                          {item?.disabled}{' '}
                                          <Button
                                            onClick={() =>
                                              handleSelectItem(item)
                                            }
                                          >
                                            {colorMode === 'light' ? (
                                              <Image
                                                src='/img/pncl_ic.svg'
                                                alt=''
                                                className='pncl_ic'
                                              />
                                            ) : (
                                              <Image
                                                src='/img/pncl_ic_dark.svg'
                                                alt=''
                                                className='pncl_ic'
                                              />
                                            )}
                                          </Button>
                                        </Box>
                                      </Box>
                                    </Td>
                                  </Tr>
                                )
                              })
                              : null}
                            {/* <Tr>
                          <Td>
                            <Box>5498w5aw554w</Box>
                          </Td>
                          <Td>
                            <Box>Mike_M007</Box>
                          </Td>
                          <Td>
                            <Box>awop241ujio124s</Box>
                          </Td>
                          <Td>
                            <Box>Normal</Box>
                          </Td>
                          <Td>
                            <Box>$25.00</Box>
                          </Td>
                          <Td>
                            <Box>Mike</Box>
                          </Td>
                          <Td>
                            <Box>Maykers</Box>
                          </Td>
                          <Td>
                            <Box>mike_m007@mail.com</Box>
                          </Td>
                          <Td>
                            <Box>846854</Box>
                          </Td>
                          <Td>
                            <Box>Collections</Box>
                          </Td>
                          <Td>
                            <Box>
                              <Box className="clctn_last_flex">
                                Collections{" "}
                                <Button>
                                  {colorMode === "light" ? (
                                    <Image
                                      src="/img/pncl_ic.svg"
                                      alt=""
                                      className="pncl_ic"
                                    />
                                  ) : (
                                    <Image
                                      src="/img/pncl_ic_dark.svg"
                                      alt=""
                                      className="pncl_ic"
                                    />
                                  )}
                                </Button>
                              </Box>
                            </Box>
                          </Td>
                        </Tr> */}
                          </Tbody>
                        </Table>
                      </TableContainer>
                      <Pagination
                        page={Number(page)}
                        totalPages={Number(totalPage)}
                        handlePagination={(page) => getDetail(page)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay className='mdl_overlay' />
              <ModalContent className='create_new_user_popup'>
                <ModalHeader>
                  {selectedItem === undefined ? 'Create new' : 'Update'} user
                </ModalHeader>
                <ModalCloseButton>
                  <ArrowBackIcon />
                  Back
                </ModalCloseButton>
                <ModalBody>
                  <Heading as='h3' className='mdl_body_h'>
                    User
                  </Heading>
                  <Box className='form_cntrl_box'>
                    <SimpleGrid columns={12} columnGap={5} rowGap={0}>
                      <GridItem colSpan={[12, 12, 6, 6]}>
                        <Box className='inpt_prnt_box'>
                          {/* <p>Username</p>
                        <input
                          type='text'
                          placeholder='Username'
                          value={form.username}
                          onChange={(e) => {
                            let data = { ...form }
                            data.username = e.target.value
                            setForm(data)
                          }}
                        /> */}
                          <FormControl variant='floating' id='first-name'>
                            <Input
                              type='text'
                              placeholder=' '
                              value={form.username}
                              onChange={(e) => {
                                let data = { ...form }
                                data.username = e.target.value
                                setForm(data)
                              }}
                            />
                            <FormLabel>Username</FormLabel>
                          </FormControl>
                        </Box>
                      </GridItem>
                      <GridItem colSpan={[12, 12, 6, 6]}>
                        <Box className='inpt_prnt_box'>
                          {/* <p>Email</p>
                      <input
                        type='text'
                        placeholder='Email'
                        value={form.email}
                        onChange={(e) => {
                          let data = { ...form }
                          data.email = e.target.value
                          setForm(data)
                        }}
                      /> */}
                          <FormControl variant='floating' id='first-name'>
                            <Input
                              type='text'
                              placeholder=' '
                              value={form.email}
                              onChange={(e) => {
                                let data = { ...form }
                                data.email = e.target.value
                                setForm(data)
                              }}
                            />
                            <FormLabel>Email</FormLabel>
                          </FormControl>
                        </Box>
                      </GridItem>
                      <GridItem colSpan={[12, 12, 6, 6]}>
                        <Box className='inpt_prnt_box'>
                          {/* <p>First Name</p>
                      <input
                        type='text'
                        placeholder='First Name'
                        value={form.firstname}
                        onChange={(e) => {
                          let data = { ...form }
                          data.firstname = e.target.value
                          setForm(data)
                        }}
                      /> */}
                          <FormControl variant='floating' id='first-name'>
                            <Input
                              type='text'
                              placeholder=' '
                              value={form.firstname}
                              onChange={(e) => {
                                let data = { ...form }
                                data.firstname = e.target.value
                                setForm(data)
                              }}
                            />
                            <FormLabel>First Name</FormLabel>
                          </FormControl>
                        </Box>
                      </GridItem>
                      <GridItem colSpan={[12, 12, 6, 6]}>
                        <Box className='inpt_prnt_box'>
                          {/* <p>Last Name</p>
                      <input
                        type='text'
                        placeholder='Last name'
                        value={form.lastname}
                        onChange={(e) => {
                          let data = { ...form }
                          data.lastname = e.target.value
                          setForm(data)
                        }}
                      /> */}
                          <FormControl variant='floating' id='first-name'>
                            <Input
                              type='text'
                              placeholder=' '
                              value={form.lastname}
                              onChange={(e) => {
                                let data = { ...form }
                                data.lastname = e.target.value
                                setForm(data)
                              }}
                            />
                            <FormLabel>Last Name</FormLabel>
                          </FormControl>
                        </Box>
                      </GridItem>
                      <GridItem colSpan={[12, 12, 6, 6]}>
                        <Box className='inpt_prnt_box'>
                          <Select
                            placeholder='- User Type -'
                            value={form.type}
                            onChange={(e) => {
                              let data = { ...form }
                              const option = optionUserType.find(
                                (option) => option.value === e.target.value,
                              )
                              data.type = option?.value
                              setForm(data)
                            }}
                          >
                            {optionUserType.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Select>
                        </Box>
                      </GridItem>
                      <GridItem colSpan={[12, 12, 6, 6]}>
                        <Box className='inpt_prnt_box'>
                          <Select
                            placeholder='- Client -'
                            value={form.client_id}
                            onChange={(e) => {
                              let data = { ...form }
                              const option = optionClient.find(
                                (option) => option.value === e.target.value,
                              )
                              data.client_id = option?.value
                              setForm(data)
                            }}
                          >
                            {optionClient.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Select>
                        </Box>
                      </GridItem>
                    </SimpleGrid>
                    {/* <Box className="paymnt_mthd_box">
                <Heading as="h3" className="mdl_body_h">
                  Payment method
                </Heading>
                <Box className="paymnt_table_prnt">
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th> &nbsp; &nbsp; &nbsp;Wallet name</Th>
                          <Th>Token</Th>
                          <Th>Blockchain</Th>
                          <Th>Wallet Address</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>
                            <Radio checked />
                            <Box> <input type="text" placeholder="Bitgraphix"/> </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Select placeholder="Token">
                                <option value="option1">Ethereum (ETH)</option>
                                <option value="option2">Binance (BNB)</option>
                                <option value="option1">Bitcoin (BTC)</option>
                                <option value="option2">USDC (USDC)</option>
                              </Select>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Select placeholder="Blockchain">
                                <option value="option1">Choose</option>
                                <option value="option2">ERC20</option>
                                <option value="option1">BEP20</option>
                                <option value="option2">AVAX C-Chain</option>
                              </Select>
                            </Box>
                          </Td>
                          <Td>
                            <Box>Bitgraphix 
                                <Button className="copy_btn_pernt">
                                    {colorMode === "light" ? (
                                <Image src="/img/copy_ic.svg" alt="" />
                                ) : (
                                    <Image src="/img/copy_ic_dark.svg" alt="" />
                                )}{" "}
                                </Button>
                            </Box>
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                  <Button className="add_new_pymnt_btn">
                    + Add new payment method
                  </Button>
                </Box>
              </Box> */}
                  </Box>
                </ModalBody>
                <ModalFooter className='modal_footer'>
                  {selectedItem && (
                    <Button className='cncl_btn' onClick={() => onOpenDeleteUser()}>
                      Delete
                    </Button>
                  )}
                  <Button className='cncl_btn' onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className='fill_btn'
                    disabled={isButtonDisable || isLoading}
                    onClick={() => handleSubmit()}
                  >
                    {isLoading
                      ? 'Creating'
                      : `Fill to ${selectedItem === undefined ? 'create' : 'update'
                      }`}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        ) : null}
      </LayoutTwo>

      <Modal onClose={onCloseDeleteUser} isOpen={isOpenDeleteUser} isCentered>
        <ModalContent className="RemoveTicketModal">
          <ModalBody>
            <Box className='pop_min_box'>
              {colorMode === 'light' ? (
                <Image
                  src='/img/pop_img_light.png'
                  alt=''
                  className='pop_img_light'
                />
              ) : (
                <Image
                  src='/img/pop_img_dark.png'
                  alt=''
                  className=''
                />
              )}

              <Box className='pop_dat_box'>
                <Heading as="h3" className='remov_h'>Delete confirmation</Heading>
                <Text className='remov_p'>Are you sure you want to delete this user?</Text>
                <Box className='pop_to_btn'>
                  <Button onClick={onCloseDeleteUser} className="def_cancel_ab">Cancel</Button>
                  <Button className="def_remove_ab"
                    onClick={() => handleDeleteUser()}
                    disabled={isLoadingDelete}>Remove</Button>
                </Box>
              </Box>
            </Box>

          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  )
}
