import React from "react";
import { Box, Heading, Image, Text } from "@chakra-ui/react";

export default function OurPartners() {
  return (
    <>
      <Box className="prtnr_main_bx">
        {/* <Heading as="h2">our <span>partners</span></Heading> */}
        <Heading as="h1" className="def_h1">
          our <span> Company</span>
        </Heading>
        <Box className="prtnr_innr_bx">
          <Image src="/img/prtnr_left_arrw.svg" className="lft_side_arrw" />
          <Image src="/img/prtnr_right_arrwv2.svg" className="rght_side_arrw" />
          <Heading as="h4" className="you_are_here_txt">
            YOU ARE HERE
          </Heading>
          <Box className="blckldr_img">
            <Image src="/img/blockleader_img.png" />
            <Box className="pdng_lft_bx">
              <Text>
                Block Leader is the parent company with three specialized
                divisions.
              </Text>
              <Box as="a" href="https://www.blockleader.io/" target="_blank">
                <span>blockleader.io</span>
                <Image src="/img/block_leader_arrow_ic.svg" alt="" />
              </Box>
            </Box>
          </Box>
          {/* <Box as="a" href="#" className="blckvd_img">
                    <Image src="/img/blockvoodoo_img.png" />
                </Box> */}
          <Box className="devlegion_img">
            <Box className="dvlgon_prnt_bx">
              <Box className="devlegion_html_bx">
                <Image
                  src="/img/our_cmpny_devlegion_img.png"
                  className="dvlgn_img"
                />
                <Text>
                  There are no limits with the right development team.{" "}
                </Text>
              </Box>
            </Box>
            <Image
              src="/img/devlegion_bttm_line.svg"
              className="bttm_line_img"
            />
          </Box>
          <Box className="btgrphx_prnt_bx">
            <Box className="bitgraphix_html_bx">
              <Image
                src="/img/our_cmpny_btgrphx_img.png"
                className="btgrphx_img"
              />
              <Box as="a" href="#" className="def_link">
                <span>bitgraphix.io</span>
                <Image src="/img/block_leader_arrow_ic.svg" alt="" />
              </Box>
            </Box>
          </Box>
          <Box className="blckguru_prnt_bx">
            <Box className="blckguru_html_bx">
              <Image
                src="/img/our_cmpny_blckguru_img.png"
                className="blckguru_img"
              />
              <Text>
                Blockchain Consulting,
                <br /> Legal / Regulatory compliance and Gamification Strategy{" "}
              </Text>
              <Box as="a" href="#" className="def_link">
                <span>blockgurus.io</span>
                <Image src="/img/block_leader_arrow_ic.svg" alt="" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
