import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  CircularProgress,
} from '@chakra-ui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../services/constant'

export default function AccountInfo() {
  const userInfo = JSON.parse(localStorage.getItem('bluClientUser'))
  const [client, setClient] = useState(null)
  const [isEditable, setIsEditable] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  const getClient = async (id) => {
    await axios.get(`${BASE_URL}/api/client/${id}`).then((response) => {
      if (response.data) setClient(response.data)
    })
  }

  const updatePassword = async () => {
    if (newPassword === '' || confirmPassword === '') {
      toast.error('Password cannot be empty!')
      return
    }
    if (
      newPassword !== '' &&
      confirmPassword !== '' &&
      confirmPassword !== newPassword
    ) {
      toast.error('Password is not matched!')
      return
    }
    setIsSaving(true)

    await axios
      .post(`${BASE_URL}/api/userClient/updatePassword`, {
        email: userInfo.email,
        password: currentPassword,
        newPassword: newPassword,
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success('Password successfully updated!')
          setCurrentPassword('')
          setNewPassword('')
          setConfirmPassword('')
          setIsEditable(false)
          setIsSaving(false)
        }
      })
      .catch((err) => {
        toast.error(err.response.data.result || 'Something went wrong!')
        setIsSaving(false)
      })
  }

  useEffect(() => {
    if (userInfo?.client_id) {
      getClient(userInfo?.client_id)
    }
  }, [])

  return (
    <>
      <Box className='accuont_info_box'>
        <Box className='first_hed'>
          <Heading as='h1'>Account Info</Heading>
        </Box>
        <Box className='accuont_info_inn'>
          <Box className='inpt_prnt_box'>
            <FormControl variant='floating' id='first-name'>
              <Input
                type='text'
                placeholder=' '
                value={userInfo?.username}
                disabled
              />
              <FormLabel>Username</FormLabel>
            </FormControl>
          </Box>
          <Box className='inpt_prnt_box_wrap'>
            <Box className='inpt_prnt_box'>
              <FormControl variant='floating' id='first-name'>
                <Input
                  type='text'
                  placeholder=' '
                  value={userInfo?.firstname}
                  disabled
                />
                <FormLabel>First Name</FormLabel>
              </FormControl>
            </Box>
            <Box className='inpt_prnt_box'>
              <FormControl variant='floating' id='first-name'>
                <Input
                  type='text'
                  placeholder=' '
                  value={userInfo?.lastname}
                  disabled
                />
                <FormLabel>Last Name</FormLabel>
              </FormControl>
            </Box>
          </Box>
          <Box className='inpt_prnt_box'>
            <FormControl variant='floating' id='first-name'>
              <Input
                type='email'
                placeholder=' '
                value={userInfo?.email}
                disabled
              />
              <FormLabel>Email</FormLabel>
            </FormControl>
          </Box>
          {isEditable ? (
            <>
              <Box className='inpt_prnt_box'>
                <FormControl variant='floating' id='first-name'>
                  <Input
                    type='password'
                    placeholder=' '
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                  <FormLabel>Current Password</FormLabel>
                </FormControl>
              </Box>
              <Box className='inpt_prnt_box_wrap'>
                <Box className='inpt_prnt_box'>
                  <FormControl variant='floating' id='first-name'>
                    <Input
                      type='password'
                      placeholder=' '
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <FormLabel>New Password</FormLabel>
                  </FormControl>
                </Box>
                <Box className='inpt_prnt_box'>
                  <FormControl variant='floating' id='first-name'>
                    <Input
                      type='password'
                      placeholder=' '
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <FormLabel>Confirm Password</FormLabel>
                  </FormControl>
                </Box>
              </Box>
            </>
          ) : null}
          <Box className='inpt_prnt_box'>
            <FormControl variant='floating' id='first-name'>
              <Input
                type='text'
                placeholder=' '
                value={client?.name}
                disabled
              />
              <FormLabel>Client</FormLabel>
            </FormControl>
          </Box>
          {!isEditable ? (
            <Box className='edi_btn_prnt'>
              <Button className='edt_btn' onClick={() => setIsEditable(true)}>
                Edit
              </Button>
            </Box>
          ) : (
            <Box className='edi_btn_prnt cancel_sv_btn_prnt'>
              <Button
                className='cncl_btn edt_btn'
                onClick={() => setIsEditable(false)}
              >
                Cancel
              </Button>
              <Button className='sve_btn edt_btn' onClick={updatePassword}>
                {!isSaving ? (
                  'Save'
                ) : (
                  <CircularProgress isIndeterminate size='24px' />
                )}
              </Button>
            </Box>
          )}
          {/* <Box className='btm_inputs'>
                        <Box className="inpt_prnt_box">
                            <FormControl variant="floating" id="first-name">
                                <Input type="text"
                                    placeholder=" " />
                                <FormLabel>Project</FormLabel>
                            </FormControl>
                        </Box>
                        <Box className="inpt_prnt_box">
                            <FormControl variant="floating" id="first-name">
                                <Input type="text"
                                    placeholder=" " />
                                <FormLabel>Project</FormLabel>
                            </FormControl>
                        </Box>
                        <Box className="inpt_prnt_box">
                            <FormControl variant="floating" id="first-name">
                                <Input type="text"
                                    placeholder=" " />
                                <FormLabel>Project</FormLabel>
                            </FormControl>
                        </Box>
                    </Box> */}
        </Box>
      </Box>
    </>
  )
}
