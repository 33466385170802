import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Button,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Heading,
  ModalBody,
  ModalFooter,
  Textarea,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { BASE_URL } from "../../services/constant";
import httpStatus from "http-status";
import InvoiceModalReject from "./InvoiceModalReject";
import { INVOICE_CLIENT_STATUS } from "../../utils/constants";

export default function InvoiceModalDetail({ invoice, isInvoiceDetailOpen, onInvoiceDetailClose, onClickPayNow, getInvoices }) {

  const [gettingTasks, setGettingTasks] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [invoiceData, setInvoiceData] = useState();
  const [invoiceDetail, setInvoiceDetail] = useState();
  const { isOpen: isRejectOpen, onOpen: onRejectOpen, onClose: onRejectClose, } = useDisclosure();

  const getData = async (invoiceId) => {
    try {
      const bluClientToken = localStorage.getItem('bluClientToken');
      const response = await fetch(BASE_URL + `/api/invoice-client/client/${invoiceId}`, {
        headers: { 'Authorization': 'Bearer ' + bluClientToken }
      });
      const data = await response.json()
      if (response.status == httpStatus.OK) {
        let inv = data?.message?.invoice;
        
        setIsRejected(inv.status == INVOICE_CLIENT_STATUS.REJECTED);
        setInvoiceData(data?.message?.invoice);
        setInvoiceDetail(data?.message?.data);
      }
    } catch (error) {
      console.error(error)
    }
    setGettingTasks(false);
  }

  const doAfterReject = () => {
    getInvoices();
    onInvoiceDetailClose();
  }

  useEffect(() => {
    if (invoice?._id && isInvoiceDetailOpen) {
      setInvoiceData(invoice);
      getData(invoice?._id);
    }
  }, [isInvoiceDetailOpen])

  return (
    <>
      <Box className="modal_main">
        <Modal isOpen={isInvoiceDetailOpen} onClose={onInvoiceDetailClose} isCentered={false}>
          <ModalOverlay />
          <ModalContent className="modal_report Invoice_in_ab">
            <ModalHeader className="moda_hed">
              {isRejected ? (
                <Heading as="h2" className='invos_head color_red'>Rejected Invoice #{invoice.invoice_ref}</Heading>
              ) : (
                <Heading as="h2" className='invos_head'>Invoice #{invoice.invoice_ref}</Heading>
              )}
            </ModalHeader>
            <ModalBody className="modal_body Invoice_in_ab_body">
              <Box className='input_min_bx_wi'>
                <Box className='for_set_gri'>
                  <Box className='inout_prnt_bx'>
                    <span>From</span>
                    <input type='texte' placeholder='Block Leader' disabled value={invoice.from_name} />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>From Email</span>
                    <input type='texte' placeholder='' disabled value={invoice.from_email} />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>Invoice Date</span>
                    <input type='texte' placeholder='' disabled value={invoice?.invoice_date ? format(new Date(invoice?.invoice_date), 'MM.dd.yyyy') : ''} />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>Start Period</span>
                    <input type='texte' placeholder='' disabled value={invoice?.start_period ? format(new Date(invoice?.start_period), 'MM.dd.yyyy') : ''} />
                  </Box>
                </Box>
                <Box className='for_set_gri megn_lf_20'>
                  <Box className='inout_prnt_bx'>
                    <span>To</span>
                    <input type='texte' placeholder='' disabled value={invoice.to_name} />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>To Email</span>
                    <input type='texte' placeholder='' disabled value={invoice.to_email} />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>Due Date</span>
                    <input type='texte' placeholder='' disabled value={invoice?.due_date ? format(new Date(invoice?.due_date), 'MM.dd.yyyy') : ''} />
                  </Box>
                  <Box className='inout_prnt_bx'>
                    <span>End Period</span>
                    <input type='texte' placeholder='' disabled value={invoice?.end_period ? format(new Date(invoice?.end_period), 'MM.dd.yyyy') : ''} />
                  </Box>
                </Box>
              </Box>
              {isRejected ? (
                <Box className='Reject_reason_bx'>
                  <Text>Reject reason:</Text>
                  <Textarea
                    placeholder='Here is a sample placeholder'
                    className='reject_text_era'
                    value={invoice?.status} />
                </Box>
              ) : ''}
              {invoiceDetail ? invoiceDetail?.map((item, index) => {
                return (
                  <>
                    <Text className='on_xdc_tabl' key={`text-${index}`}>{item.name}</Text>
                    <TableContainer key={`table-${index}`}>
                      <Table variant="simple" className='client_report_tbl client_report_tbl_task_only invos_tbl_ab'>
                        <Tbody className='table_body table_body_swpcl_tbl table_bodytemlate_2 w-80'>
                          <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new t_had'>
                            {/* <Td className="width_onl_10">Task ID</Td> */}
                            <Td className="width_onl_10">Date</Td>
                            <Td className="width_onl_10">Task</Td>
                            <Td className="width_onl_10">Role</Td>
                            <Td className='brd_non'>Rate</Td>
                            <Td className='brd_non'>Time</Td>
                            <Td className='brd_non'>$ Cost</Td>
                          </Tr>
                          {item?.items ? item?.items?.map((task) => {
                            return (
                              <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_nme_nmbr'>
                                {/* <Td className="width_onl_10">#{task?.task_id}</Td> */}
                                <Td className="width_onl_10">{task?.date}</Td>
                                <Td className="width_onl_10 for_max_wi"><Text>{task?.task_name}</Text></Td>
                                <Td className="width_onl_10 weight400">{task?.role}</Td>
                                <Td className='brd_non'>{task?.rate}</Td>
                                <Td className='brd_non'>{task?.hours}</Td>
                                <Td className='brd_non'>{task?.cost}</Td>
                              </Tr>
                            )
                          }) : (
                            <Tr>
                              <Td colSpan={6}>{gettingTasks ? "Loading..." : "No data found"}</Td>
                            </Tr>
                          )}
                          <Tr className='frst_tr_bg_clr pd_12 frst_tr_bg_clr_project_1accdian task_tr_new t_foot'>
                            <Td className="width_onl_10"></Td>
                            <Td className="width_onl_10"></Td>
                            <Td className="width_onl_10"></Td>
                            <Td className='for_totl_color brd_non'>Total:</Td>
                            <Td className='for_grin_color brd_non'>{item?.hours}</Td>
                            <Td className='for_grin_color brd_non'>{item?.cost}</Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </>
                )
              }) : ''}
            </ModalBody>
            <ModalFooter className="foter_this_modl invoice_foot">
              <Box className='rejac_cnc_btn_bx'>
                <Button
                  className="back_btn"
                  colorScheme='blue'
                  mr={3}
                  onClick={onInvoiceDetailClose}
                >
                  Cancel
                </Button>
                <Button
                  className="back_btn rejact_pink_btn"
                  colorScheme='blue'
                  mr={3}
                  disabled={isRejected}
                  onClick={onRejectOpen}
                >
                  Reject
                </Button>
              </Box>

              {/* <Button className="close_btn" onClick={MetamaskOpen}>MetamaskOpen</Button> */}
              <Button
                className="close_btn"
                disabled={isRejected}
                onClick={() => onClickPayNow([invoice])}
              >
                Pay Now
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>

      <InvoiceModalReject
        invoice={invoice}
        isOpen={isRejectOpen}
        onClose={onRejectClose}
        onSuccess={doAfterReject}
      />
    </>
  );
}